import { useEffect, useState } from "react";
import InfoIcon from "../../Icons/InfoIcon";
import WarningIcon from "../../Icons/WarningIcon";
import { MAIN_YELLOW } from "../../colors";

const WARN = "warn";
const INFO = "info";
const ICON_SIZE = 14;

const useGetNodeIconAndTitle = (limitScaleDownSeverity: string | undefined) => {
  const [icon, setIcon] = useState<undefined | React.ReactElement>(undefined);
  const [title, setTitle] = useState<string>("Scale down blockers");

  useEffect(() => {
    switch (limitScaleDownSeverity) {
      case WARN:
        setIcon(<WarningIcon width={ICON_SIZE} height={ICON_SIZE} fill={MAIN_YELLOW} />);
        break;
      case INFO:
        setIcon(<InfoIcon width={ICON_SIZE} height={ICON_SIZE} />);
        setTitle("Scale down constrains");
        break;
      default:
        break;
    }
  }, [limitScaleDownSeverity]);

  return { icon, title };
};

export default useGetNodeIconAndTitle;
