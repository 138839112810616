export const setBooleanUrlParam = (value: boolean | null | undefined) => (value ? true : undefined);

const DOCS_BASE = "https://docs.scaleops.com/";
export const documentationUrl = ({ path, subPath, token }: { path?: string; subPath?: string; token?: string }) => {
  const normalizedPath = path?.startsWith("/") ? path.slice(1) : path;
  const normalizedSubPath = subPath?.startsWith("#") ? subPath.slice(1) : subPath;

  return `${DOCS_BASE}${normalizedPath ?? ""}${token ? `?token=${token}` : ""}${
    normalizedSubPath ? `#${normalizedSubPath}` : ""
  }`;
};
