import { useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import Tab from "../../../components/Tab";
import { CostReportType } from "../utils";
import AggregationFilters from "./Aggregation/AggregationFilters";
import AggregationTable from "./Aggregation/AggregationTable";
import NetworkAggregationTable from "./NetworkAggregation/NetworkAggregationTable";
import NetworkWorkloadsTable from "./NetworkWorkloads/NetworkWorkloadsTable";
import { Field, TableType } from "./utils";
import WorkloadsFilters from "./Workloads/WorkloadsFilters";
import WorkloadsTable from "./Workloads/WorkloadsTable";

interface Props {
  selectedTable: TableType;
  setSelectedTable: (table: TableType) => void;
  reportType: CostReportType;
  isMultiCluster?: boolean;
}

const TablesContainer = ({ selectedTable, setSelectedTable, reportType, isMultiCluster }: Props) => {
  const [selectedWorkloadsColumns, setSelectedWorkloadsColumns] = useState<(string | undefined)[]>([
    Field.workloadName,
    Field.clusterName,
    Field.totalCost,
    Field.savingsAvailable,
    Field.spot,
    Field.onDemand,
    Field.cpu,
    Field.memory,
    // ColumnNames.replicas,
  ]);
  const [selectedAggregationColumns, setSelectedAggregationColumns] = useState<(string | undefined)[]>([
    Field.id,
    Field.clusterName,
    Field.totalCost,
    Field.savingsAvailable,
    // Field.spot,
    // Field.onDemand,
    Field.cpu,
    Field.memory,
    // ColumnNames.replicas,
  ]);

  const [selectedNetworkWorkloadsColumns, setSelectedNetworkWorkloadsColumns] = useState<(string | undefined)[]>([
    Field.workloadName,
    Field.clusterName,
    // Field.replicas,
    Field.totalCost,
    Field.crossAZCost,
    // Field.intraAZCost,
    // Field.totalDataTransfer
    // Field.intraAZDataTransfer
    // Field.crossAZDataTransfer
  ]);

  const [selectedNetworkAggregationColumns, setSelectedNetworkAggregationsColumns] = useState<(string | undefined)[]>([
    Field.id,
    Field.clusterName,
    // Field.replicas,
    Field.totalCost,
    Field.crossAZCost,
    // Field.intraAZCost,
    // Field.totalDataTransfer
    // Field.intraAZDataTransfer
    // Field.crossAZDataTransfer
  ]);

  const [, setSearchTerm] = useQueryParam("searchTerms", StringParam);

  return (
    <div className="bg-white rounded-lg rounded-tl-none relative top-[36px] p-8 flex flex-col gap-4">
      <div className="flex absolute right-0 top-[-36px] w-full">
        {Object.entries(TableType).map(([key, value]) => {
          return (
            <Tab
              key={key}
              isSelected={selectedTable === value}
              onClick={() => {
                setSearchTerm("");
                setSelectedTable(value);
              }}
              name={value}
              dataTestId={`cost-page-${key}-tab`}
            />
          );
        })}
      </div>
      {selectedTable === TableType.Workloads && reportType === CostReportType.Compute && (
        <>
          <WorkloadsFilters
            selectedColumns={selectedWorkloadsColumns}
            setSelectedColumns={setSelectedWorkloadsColumns}
            columnOptions={[
              Field.clusterName,
              Field.totalCost,
              Field.savingsAvailable,
              Field.spot,
              Field.onDemand,
              Field.cpu,
              Field.memory,
              Field.replicas,
            ]}
            isMultiCluster={isMultiCluster}
          />
          <WorkloadsTable selectedColumns={selectedWorkloadsColumns} />
        </>
      )}
      {selectedTable === TableType.Aggregation && reportType === CostReportType.Compute && (
        <>
          <AggregationFilters
            selectedColumns={selectedAggregationColumns}
            setSelectedColumns={setSelectedAggregationColumns}
            columnOptions={[
              Field.clusterName,
              Field.totalCost,
              Field.savingsAvailable,
              Field.spot,
              Field.onDemand,
              Field.cpu,
              Field.memory,
              Field.replicas,
              Field.amountOfWorkloads,
            ]}
            isMultiCluster={isMultiCluster}
          />
          <AggregationTable selectedColumns={selectedAggregationColumns} />
        </>
      )}
      {selectedTable === TableType.Workloads && reportType === CostReportType.Network && (
        <>
          <WorkloadsFilters
            selectedColumns={selectedNetworkWorkloadsColumns}
            setSelectedColumns={setSelectedNetworkWorkloadsColumns}
            columnOptions={[
              Field.clusterName,
              // Field.replicas,
              Field.totalCost,
              Field.crossAZCost,
              // Field.intraAZCost,
              Field.totalDataTransfer,
              Field.crossAZDataTransfer,
              Field.intraAZDataTransfer,
            ]}
            isMultiCluster={isMultiCluster}
          />
          <NetworkWorkloadsTable selectedColumns={selectedNetworkWorkloadsColumns} />
        </>
      )}
      {selectedTable === TableType.Aggregation && reportType === CostReportType.Network && (
        <>
          <AggregationFilters
            selectedColumns={selectedNetworkAggregationColumns}
            setSelectedColumns={setSelectedNetworkAggregationsColumns}
            columnOptions={[
              Field.clusterName,
              // Field.replicas,
              Field.totalCost,
              Field.crossAZCost,
              // Field.intraAZCost,
              Field.totalDataTransfer,
              Field.crossAZDataTransfer,
              Field.intraAZDataTransfer,
            ]}
            isMultiCluster={isMultiCluster}
          />
          <NetworkAggregationTable selectedColumns={selectedNetworkAggregationColumns} />
        </>
      )}
    </div>
  );
};

export default TablesContainer;
