import { Typography } from "@mui/material";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import { useEffect, useState } from "react";
import { ComposedChart, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { SCALEOPS_COLORS } from "../../../../colors";
import CustomLegend from "../../../../components/CustomLegend";
import ScaleOpsSpan from "../../../../components/ScaleOpsSpan";
import EfficiencyIcon from "../../../../Icons/EfficiencyIcon";
import KubernetesIcon from "../../../../Icons/KubernetesIcon";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  getTimeFormatFromEpochAndPeriodInHours,
} from "../../../../utils/formatterUtils";
import { NO_OUTLINE } from "../../../../utils/styleUtils";
import GraphLoading from "../UsageChart/GraphLoading";
import CustomScatterDot from "./CustomScatterDot";
import CustomTooltip from "./CustomTooltip";
import { EVENT_COLORS, EventName, EventPoint, EventType, ParsedData } from "./utils";

dayjs.extend(dayjsPluginUTC);

const NUMBER_OF_X_AXIS_TICKS = 6;

export const getCustomElementTooltip = (key: string) => {
  let output: React.ReactNode | undefined = undefined;

  switch (true) {
    case key === EventType.CPU_THROTTLING || key === EventType.HIGH_CPU_UTILIZATION:
      output = (
        <span className={"flex flex-wrap gap-1 items-center"}>
          <KubernetesIcon height={12} width={12} fill="#3A72E7" />
          <span>
            {" "}
            CPU usage <b>exceeded</b> the user configured <b>limit</b>.
          </span>{" "}
          You can increase the workload's limit to prevent this event.
        </span>
      );
      break;
    case key === EventType.HIGH_UTILIZATION_NODES:
      output = (
        <span className={"inline-flex gap-1 items-center"}>
          <EfficiencyIcon height={12} width={12} fill={SCALEOPS_COLORS.guideline.lessDarkPurple} /> Node CPU utilization{" "}
          <b>above 90%.</b>
        </span>
      );
      break;
    case key === EventType.FAST_REACTION:
      output = (
        <>
          <ScaleOpsSpan className="mt-[4px]" /> detected a fast increase in <b>usage</b> and adjusted the recommendation
          instantly.
        </>
      );
      break;
    case key === EventType.OOM_LIMIT:
      output = (
        <span className={"flex flex-wrap gap-1 items-center"}>
          <KubernetesIcon height={12} width={12} fill="#3A72E7" />
          <span>Workload was evicted due to Out Of Memory event.</span>
          <span>
            This was caused because the usage <b>exceeded</b> the user configured <b>limit</b>.
          </span>{" "}
          You can increase the workload's limit to prevent this event.
        </span>
      );
      break;
    case key === EventType.OOM_NODE:
      output = (
        <span className={"flex flex-wrap gap-1 items-center"}>
          <KubernetesIcon height={12} width={12} fill="#3A72E7" />
          Workload was evicted, due to node Out Of Memory event.
        </span>
      );
      break;
    case key === EventType.EVICTION:
      output = (
        <>
          <ScaleOpsSpan className="mt-[4px]" /> evicted workload due to rollout.
        </>
      );
      break;
    default:
      output = undefined;
  }
  return output;
};

const hasInfoIcon = (key: string): boolean => {
  return (
    key === EventType.CPU_THROTTLING ||
    key === EventType.HIGH_CPU_UTILIZATION ||
    key === EventType.OOM_LIMIT ||
    key === EventType.OOM_NODE ||
    key === EventType.HIGH_UTILIZATION_NODES ||
    key === EventType.FAST_REACTION ||
    key === EventType.EVICTION
  );
};

const tooltipMaxWidth = (key: string): number => {
  switch (true) {
    case key === EventType.CPU_THROTTLING || key === EventType.HIGH_CPU_UTILIZATION || key === EventType.OOM_NODE:
      return 400;
    case key === EventType.OOM_LIMIT:
      return 480;
    case key === EventType.FAST_REACTION || key === EventType.EVICTION:
      return 330;
    default:
      return 300;
  }
};

const displayNameStringTransformer = (key: string) => {
  let output;
  switch (true) {
    case Object.keys(EventName).includes(key):
      output = EventName[key as EventType];
      break;
    default:
      return key.replace(/oom/gi, "OOM").replace(/cpu/gi, "CPU");
  }
  return output;
};

interface Props {
  data: ParsedData | undefined;
  selectedViewPeriod: string;
  hourlyDateTimeFormat?: string;
  monthlyDateTimeFormat?: string;
  showMonthlyDateTimeFormatThreshold?: number;
  includedElements: EventType[];
  isLoading?: boolean;
}

const DiagnosticsChart = ({
  data,
  selectedViewPeriod,
  showMonthlyDateTimeFormatThreshold = 3,
  includedElements,
  isLoading,
}: Props) => {
  const [dataWasFound, setDataWasFound] = useState<boolean>(true);
  const [selectedChartComponents, setSelectedChartComponents] = useState<EventType[]>(includedElements);
  const [tooltipPayloadContext, setTooltipPayloadContext] = useState<EventPoint | undefined>(undefined);
  const [legendStyle, setLegendStyle] = useState<Record<string, { color: string }>>({});
  const [legendChartComponents, setLegendChartComponents] = useState<Record<string, EventType>>({});
  const [actualData, setActualData] = useState<ParsedData | { label: string }[] | undefined>(data);

  useEffect(() => {
    // Set legend style
    const legendStyleObject: Record<string, { color: string }> = includedElements.reduce((acc, curr) => {
      acc[curr] = {
        color: EVENT_COLORS[curr],
      };
      return acc;
    }, {} as Record<string, { color: string }>);
    setLegendStyle(legendStyleObject);
    // Set legend chart components
    const legendChartComponentsObject = includedElements.reduce((acc, curr) => {
      acc[curr] = curr;
      return acc;
    }, {} as Record<string, EventType>);
    setLegendChartComponents(legendChartComponentsObject);
  }, [includedElements]);

  useEffect(() => {
    if (data && data.length && dataWasFound === false) {
      setDataWasFound(true);
    }
    setActualData(!data || data.length === 0 ? [{ label: "" }] : data);
  }, [data]);

  const XAxisInterval = data ? Math.round(data.length / NUMBER_OF_X_AXIS_TICKS) ?? NUMBER_OF_X_AXIS_TICKS : 0;
  const viewPeriod = Number(selectedViewPeriod);
  const timeFormat = `${
    viewPeriod > showMonthlyDateTimeFormatThreshold ? DEFAULT_DATE_FORMAT : ""
  } ${DEFAULT_TIME_FORMAT}`;

  return (
    <div className="border border-border rounded-lg flex flex-col items-center relative pr-2  py-4">
      <Typography variant="body2" fontWeight={600} className="w-full pl-1 text-center left-[0.5px]">
        Timeline
      </Typography>
      {isLoading && <GraphLoading className="absolute w-full flex items-center justify-center top-[35px]" />}
      <div className="w-full h-[60px]">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={actualData}
            syncId="policyTuning"
            margin={{
              top: 0,
              right: 50,
              left: 0,
              bottom: 0,
            }}
          >
            <Tooltip
              wrapperStyle={NO_OUTLINE}
              content={
                <CustomTooltip
                  selectedEvents={selectedChartComponents}
                  tooltipPayloadContext={tooltipPayloadContext}
                  timeFormat={timeFormat}
                  displayNameStringTransformer={displayNameStringTransformer}
                />
              }
            />
            {selectedChartComponents.includes(EventType.AUTO) && (
              <Line
                type="basisOpen"
                dataKey="auto"
                stroke={SCALEOPS_COLORS.main.green}
                strokeWidth={6}
                dot={false}
                isAnimationActive={false}
              />
            )}
            <Line
              type="step"
              dataKey="hasEvents"
              stroke="none"
              dot={
                <CustomScatterDot
                  cx={0}
                  cy={0}
                  dotKey={EventType.AUTO_HEALING}
                  selectedEvents={selectedChartComponents}
                  setTooltipPayloadContext={setTooltipPayloadContext}
                />
              }
              activeDot={false}
              opacity={isLoading ? 0 : 1}
            />
            <YAxis domain={[1, 7]} axisLine={false} tick={false} />
            <XAxis
              strokeWidth={2}
              dataKey="timestamp"
              style={{ fontSize: "x-small" }}
              tickFormatter={(tick: string) => {
                if (!data) return "";
                if (data && data.length === 0) return tick;
                return getTimeFormatFromEpochAndPeriodInHours(Number(tick), selectedViewPeriod);
              }}
              interval={XAxisInterval}
              tickLine={!!data && data.length > 0}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-[20px]">
        <CustomLegend<EventType>
          selectedChartComponents={selectedChartComponents}
          setSelectedChartComponents={setSelectedChartComponents}
          componentStyle={legendStyle}
          ChartComponents={legendChartComponents}
          className="-mt-1"
          renderNameFunction={displayNameStringTransformer}
          customElementTooltip={getCustomElementTooltip}
          tooltipMaxWidth={tooltipMaxWidth}
          hasInfoIcon={hasInfoIcon}
        />
      </div>
    </div>
  );
};

export default DiagnosticsChart;
