import { Typography } from "@mui/material";
import {
  getPercentageOfOptimizedReplicas,
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE,
} from "./GridColumnsUtils";
import prettyBytes from "pretty-bytes";
import Tooltip from "../../Tooltip";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import clsx from "clsx";
import RightArrowIcon from "../../../Icons/RightArrowIcon";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";

export default function OriginalMemoryRequestColumn({
  params,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
}) {
  if (isUnallocatedRow(params.row)) return <div></div>;
  const originalMemoryRequest = Number(params.row.ownerMemoryRequests);

  const percentageOfOptimizedReplicas = getPercentageOfOptimizedReplicas(params);

  const memoryRequestsRecommendedDisplayValue = prettyBytes(params.row.memRecommended || 0.0, {
    bits: false,
    binary: true,
  });

  const currentDisplayValue = prettyBytes(originalMemoryRequest || 0.0, { bits: false, binary: true });

  const memoryRequestsDisplayValue = prettyBytes(params.row.memRequests || 0.0, {
    bits: false,
    binary: true,
  });

  const recommendedDisplayValue = prettyBytes(params.row.memRecommended || 0.0, {
    bits: false,
    binary: true,
  });

  return (
    <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
      <Tooltip
        title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
        maxWidth={400}
        className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
        enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
      >
        <div className="flex justify-center items-center gap-[.625rem]">
          <span
            className={clsx({
              "text-main-red":
                !(percentageOfOptimizedReplicas > 50 && params.row.auto) &&
                currentDisplayValue !== recommendedDisplayValue,
            })}
          >
            {currentDisplayValue}
          </span>
          {(memoryRequestsRecommendedDisplayValue || memoryRequestsDisplayValue) && (
            <>
              <RightArrowIcon width={10} height={10} />
              <span className="text-guideline-darkGreen">
                {percentageOfOptimizedReplicas > 50 && params.row.auto
                  ? recommendedDisplayValue
                  : memoryRequestsDisplayValue}
              </span>
            </>
          )}
        </div>
      </Tooltip>
    </Typography>
  );
}
