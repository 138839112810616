import { Helmet } from "react-helmet";

export default function DocumentHead({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <>
      <Helmet title={`${title} - ScaleOps`} />
      {children}
    </>
  );
}
