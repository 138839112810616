import { useQuery } from "@tanstack/react-query";
import { getLabels, GetLabelsResponse } from "../../../api/fetcher";

const KEY_AND_VALUE_COMBINATIONS_TO_EXCLUDE = ["app.kubernetes.io/instance=scaleops"];

const { queryKey, queryFn } = getLabels();

const useGetLabels = (isMultiCluster?: boolean) => {
  const { data, isLoading, error } = useQuery<GetLabelsResponse>({
    queryKey: [queryKey, isMultiCluster],
    queryFn: () => queryFn(isMultiCluster),
  });

  if (error) {
    console.log("Error fetching labels", error);
    return [];
  }

  if (isLoading || !data?.labels) {
    return [];
  }

  const labels = [...data.labels, ...(data?.ownerLabels ?? [])];
  let uniqueLabels = [...new Set(labels)];

  uniqueLabels = uniqueLabels.filter((label) => !KEY_AND_VALUE_COMBINATIONS_TO_EXCLUDE.includes(label));

  return uniqueLabels;
};

export default useGetLabels;
