import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import {
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE,
} from "./GridColumnsUtils";
import { Typography } from "@mui/material";
import * as formatterUtils from "../../../utils/formatterUtils";
import clsx from "clsx";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import Tooltip from "../../Tooltip";

export default function ReplicasAvailableSavingsColumn({
  params,
}: {
  params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>;
}) {
  if (isUnallocatedRow(params.row)) return <div></div>;
  if (params.row.isReadyRecommendation == false) {
    return (
      <Typography fontWeight={410} fontSize={"small"} fontFamily={"Poppins"} fontStyle={"oblique"}>
        inactive
      </Typography>
    );
  }

  const displayValue =
    params.row.replicasAvailableSavings == undefined ||
    (params.row.replicasAvailableSavings < 0 && params.row.replicasAvailableSavings > -1)
      ? formatterUtils.currencyFormatter(0)
      : formatterUtils.currencyFormatter(params.row.replicasAvailableSavings);

  return (
    <Typography
      variant="body2"
      className={clsx("fullCellTooltipContent", {
        "text-guideline-darkGreen":
          displayValue !== "$0" && params.row.replicasAvailableSavings && params.row.replicasAvailableSavings > -1,
      })}
      fontWeight={500}
    >
      <Tooltip
        title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
        maxWidth={400}
        className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
        enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
      >
        {displayValue}
      </Tooltip>
    </Typography>
  );
}
