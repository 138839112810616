import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GitForkIcon from "../Icons/GitForkIcon";
import { useMainContext } from "../MainContext";
import { HAS_MULTILEVEL_ANALYTICS } from "../pages/Analytics/AnalyticsV2/AnalyticsContainer";
import MainMenuItem, { MainMenuItemSize } from "./MainMenuItem";
import MultiMenu from "./MultiMenu";
import SavingsResultMenu from "./SavingsResultMenu";

const ARROW_ICON_SIZE = 18;

const TO_URL = {
  multicluster: "/multicluster",
  analytics: "/multiCluster/analytics",
  insight: "/multiCluster/insight",
  report: "/multiCluster/reports",
};

const URL_ARRAY_VALUES = Object.values(TO_URL);

const MultiClusterMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentCluster } = useMainContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedClusterNamesHistory, setSelectedClusterNamesHistory] = useState<string[]>([]);
  const [isInnerItemsSelected, setIsInnerItemsSelected] = useState(false);

  useEffect(() => {
    if (currentCluster && !selectedClusterNamesHistory.includes(currentCluster)) {
      setSelectedClusterNamesHistory([...selectedClusterNamesHistory, currentCluster]);
    }
    if (selectedClusterNamesHistory.length > 0 && currentCluster) {
      toast.success(`Cluster changed successfully to ${currentCluster}`);
    }
  }, [currentCluster]);

  useEffect(() => {
    const isInnerItemsSelectedValue = URL_ARRAY_VALUES.some((url) => location.pathname.startsWith(url));
    setIsInnerItemsSelected(isInnerItemsSelectedValue);
    isInnerItemsSelectedValue && setIsOpen(isInnerItemsSelectedValue);
  }, [location.pathname]);

  return (
    <div
      className={clsx("flex flex-col", {
        "gap-2": isOpen,
      })}
    >
      <Typography
        onClick={() => {
          const isUrlIncluded = URL_ARRAY_VALUES.some((url) => location.pathname.startsWith(url));
          if (isUrlIncluded || isOpen) {
            setIsOpen(!isOpen);
          } else {
            const currentCluster = sessionStorage.getItem("currentCluster");
            navigate(`${TO_URL.multicluster}${currentCluster ? `?currentClusterURLParam=${currentCluster}` : ""}`);
          }
        }}
        className={clsx("flex px-4 py-1 rounded-lg cursor-pointer items-center")}
        variant="body2"
      >
        <div
          className={clsx("grow flex justify-start items-center gap-2", {
            underline: isInnerItemsSelected,
          })}
        >
          <GitForkIcon width={17} height={17} />
          Multi cluster
        </div>
        <div>
          {isOpen ? (
            <KeyboardArrowUp
              sx={{
                width: ARROW_ICON_SIZE,
                height: ARROW_ICON_SIZE,
              }}
            />
          ) : (
            <KeyboardArrowDown
              sx={{
                width: ARROW_ICON_SIZE,
                height: ARROW_ICON_SIZE,
              }}
            />
          )}
        </div>
      </Typography>
      <div
        className="ml-[18px] border-l border-text-lightBlack pl-4"
        style={{
          maxHeight: isOpen ? "100vh" : 0,
          overflow: "hidden",
          transition: isOpen ? "max-height 0.2s ease-in" : undefined,
        }}
      >
        <MainMenuItem title="Overview" to={TO_URL.multicluster} variant="caption" />
        {!HAS_MULTILEVEL_ANALYTICS && (
          <>
            <MainMenuItem title="Troubleshoot" to={TO_URL.insight} variant="caption" />
            <SavingsResultMenu isMultiCluster={true} />
          </>
        )}
        {HAS_MULTILEVEL_ANALYTICS && (
          <MultiMenu
            baseUrl={{ name: "Analytics", url: "/multiCluster/analytics/general", variant: "caption" }}
            urls={[
              { name: "General", url: "/multiCluster/analytics/general" },
              { name: "Troubleshoot", url: "/multiCluster/analytics/insight" },
            ]}
            title="Analytics"
            MainMenuItemSize={MainMenuItemSize.Small}
          />
        )}
      </div>
    </div>
  );
};

export default MultiClusterMenu;
