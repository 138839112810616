import { Typography } from "@mui/material";
import clsx from "clsx";
import RightSizingIcon from "../../Icons/RightSizingIcon";
import UpAndDownCircleIcon from "../../Icons/UpAndDownCircleIcon";
import SavingsRow from "../SavingsRow";
import Tooltip from "../Tooltip";

const ICON_SIZE = 14;

interface Props {
  hasPolicyWithHpa: boolean;
  rightSizingSavings: React.ReactNode;
  HPASavings: React.ReactNode;
  overallSavings: React.ReactNode;
}

const HPASavingsAvailable = ({ hasPolicyWithHpa, rightSizingSavings, HPASavings, overallSavings }: Props) => {
  return (
    <Tooltip
      maxWidth={500}
      className="relative"
      title={
        <>
          {hasPolicyWithHpa ? (
            <div className="flex flex-col gap-1">
              <SavingsRow label="HPA" value={HPASavings} icon={<UpAndDownCircleIcon />} />
              <SavingsRow label="Rightsize" value={rightSizingSavings} icon={<RightSizingIcon />} />
              <hr className="w-[17.6875rem] border-text-lightBlack" />
              <SavingsRow
                label="Savings available"
                value={overallSavings}
                className="text-guideline-darkGreen"
                style={{
                  fontWeight: 700,
                }}
              />
            </div>
          ) : (
            <>
              Attach the workload to the <b>production-hpa</b> policy <br />
              to gain additional value of <b>{HPASavings?.toString()}</b>.
            </>
          )}
        </>
      }
    >
      <Typography
        variant="body2"
        className={clsx("fullCellTooltipContent relative", {
          "text-guideline-darkGreen": overallSavings !== "$0",
        })}
        fontWeight={500}
      >
        {hasPolicyWithHpa ? overallSavings : rightSizingSavings}
      </Typography>
      <UpAndDownCircleIcon className="absolute right-[-18px] top-[2px]" width={ICON_SIZE} height={ICON_SIZE} />
    </Tooltip>
  );
};

export default HPASavingsAvailable;
