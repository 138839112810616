export enum NodeStatus {
  notunderutilized = "Not under utilized",
  lessthan70underutilized = "Less than 70% under utilized",
  scaledowndisabledannotation = "Scale down disabled annotation",
  nodegroupminsizeReached = "Node group min size reached",
  mandatorynodelabelsdoesnotexist = "Mandatory node labels does not exist",
  nodependingdeletion = "Node pending deletion",
  Uninitializednode = "Uninitialized node",
  donotconsolidateannotationonnode = "Do not consolidate annotation on node",
  donotconsolidate = "Do not consolidate",
  failedtoparseemptiness = "Failed to parse emptiness",
  notemptyforenoughtime = "Not empty for enough time",
  emptinesstimestampdoesntexist = "Emptiness timestamp doesn't exist",
  nodepoolpolicywhenempty = "Node pool policy when empty",
  nodepooldoesnotexists = "Node pool does not exists",
  disruptionbudgetexceeded = "Disruption budget exceeded",
  disruptionblocked = "Disruption blocked",
  nodenotempty = "Node not empty",
  noreaseon = "No reason",
  notunneededlongenough = "Not unneeded long enough",
  notunreadylongenough = "Not unready long enough",
  minimalresourcelimitexceeded = "Minimal resource limit exceeded",
  currentlybeingdeleted = "Currently being deleted",
  notunneeded = "Not unneeded",
  notunneededotherreason = "Not unneeded other reason",
  recentlyunremovable = "Recently unremovable",
  noplacestomovepods = "No place to move pods",
  blockedbypod = "Blocked by pod",
  unexpectederror = "Unexpected error",
  notready = "Not ready",
  notunready = "Not unready",
  noreason = "No reason",
  scaledownunreadydisabled = "Scale down unready disabled",
  notautoscaled = "Not autoscaled",
  noplacetomovepods = "No place to move pods",
  controllernotfound = "Controller not found",
  minreplicasreached = "Min replicas reached",
  notreplicated = "Not replicated",
  localstoragerequested = "Local storage requested",
  notsafetoevictannotations = "Not safe to evict annotations",
  unmovablekubesystempod = "Unmovable kube system pod",
  notenoughpdb = "Not enough pdb",
}

export const NodeStatusKeys = Object.keys(NodeStatus);

export type PayloadItem = {
  value: string | number;
  name?: string;
  dataKey: string;
  stroke: string;
  payload: {
    secondaryValues?:
      | {
          [key: string]: number | undefined;
        }
      | undefined;
  };
};

export type SecondaryValueFnc = (
  label: string | undefined,
  value: string | number | undefined | null,
  secondaryValues?:
    | {
        [key: string]: number | undefined;
      }
    | undefined
) => React.ReactNode;
