import { MenuItem } from "@mui/material";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import SingleSelect from "../../../components/SingleSelect";
import NetworkIcon from "../../../Icons/NetworkIcon";

const ICON_SIZE = 14;

const AGGREGATION_VIEW_QUERY_PARAM_KEY = "aggregationView";

export enum AggregateBy {
  Namespaces = "Namespaces",
  Workloads = "Workloads",
}

export const useGetAggregationParams = () => {
  const [aggregationView, setAggregationView] = useQueryParam(
    AGGREGATION_VIEW_QUERY_PARAM_KEY,
    withDefault(StringParam, AggregateBy.Workloads)
  );
  return { aggregationView, setAggregationView };
};

const SelectAggregationView = () => {
  const { aggregationView, setAggregationView } = useGetAggregationParams();

  return (
    <SingleSelect<string>
      className="w-[160px]"
      selected={aggregationView}
      setSelected={(option) => {
        setAggregationView(option);
      }}
      renderOptionsFunc={() =>
        Object.entries(AggregateBy).map((option) => {
          return (
            <MenuItem value={option[1]} key={option[1]}>
              <div className="text-[14px] flex items-center gap-2">
                <NetworkIcon width={ICON_SIZE} height={ICON_SIZE} />
                <span>{option[1]}</span>
              </div>
            </MenuItem>
          );
        })
      }
    />
  );
};

export default SelectAggregationView;
