import React from "react";
import clsx from "clsx";
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Typography } from "@mui/material";
import { East } from "@mui/icons-material";
import { customNumberFormatter } from "../../utils/formatterUtils";
import PlainCubeIcon from "../../Icons/PlainCubeIcon";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import { SCALEOPS_COLORS } from "../../colors";

interface Props {
  title: React.ReactNode;
  original?: number;
  current?: number;
  beforeValueSuffix?: React.ReactNode;
  afterValueSuffix?: React.ReactNode;
  shouldBarChart?: boolean;
  shouldFormatNumbers?: boolean;
  valueParser?: (value: number) => number;
}

const VsBox = ({
  title,
  original = NaN,
  current = NaN,
  beforeValueSuffix,
  afterValueSuffix,
  shouldBarChart,
  shouldFormatNumbers,
  valueParser,
}: Props) => {
  let percentageValue = ((current - original) / original) * 100;
  percentageValue = Math.round(percentageValue);
  percentageValue = percentageValue * -1 ?? 0;
  percentageValue = percentageValue === Infinity || percentageValue === -Infinity ? 0 : percentageValue;

  let originalDisplayValue;
  let currentDisplayValue;

  if (shouldFormatNumbers) {
    originalDisplayValue = customNumberFormatter(original);
    currentDisplayValue = customNumberFormatter(current);
  } else if (valueParser) {
    originalDisplayValue = valueParser(original);
    currentDisplayValue = valueParser(current);
  } else {
    originalDisplayValue = original;
    currentDisplayValue = current;
  }

  return (
    <div className="w-[28%] py-1 flex flex-col gap-2 relative">
      <Typography variant="body2" fontWeight={600} className="text-center flex flex-col justify-center items-center">
        {title}{" "}
        {!isNaN(percentageValue) && (
          <Typography
            variant="body2"
            className={clsx("px-2", {
              "text-black": percentageValue <= 0,
              "text-guideline-darkGreen": percentageValue > 0,
            })}
          >
            ({percentageValue}%{percentageValue > 0 && <b> savings</b>})
          </Typography>
        )}
      </Typography>
      <div className="flex justify-center items-center">
        <div className="flex flex-col justify-center items-center opacity-100 w-full">
          <Typography variant="h3" fontSize={44} className="text-center">
            {Number.isNaN(original) ? (
              "-"
            ) : (
              <>
                {originalDisplayValue}
                {beforeValueSuffix && (
                  <Typography variant="caption" className="text-center">
                    {beforeValueSuffix}
                  </Typography>
                )}
              </>
            )}
          </Typography>
          <Typography variant="body2" className="text-center flex gap-2 justify-center items-center">
            <PlainCubeIcon />
            <p>Original</p>
          </Typography>
        </div>
        <div className="h-[50px] flex flex-col justify-center items-center">
          <East />
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <Typography variant="h3" fontSize={44} className="text-center">
            {Number.isNaN(current) ? (
              "-"
            ) : (
              <>
                {currentDisplayValue}
                {afterValueSuffix && (
                  <Typography variant="caption" className="text-center">
                    {afterValueSuffix}
                  </Typography>
                )}
              </>
            )}
          </Typography>
          <Typography variant="body2" className="text-center flex gap-2 justify-center items-center">
            <ScaleopsIcon />
            <p>Current</p>
          </Typography>
        </div>
      </div>
      {shouldBarChart && (
        <div className="flex gap-2 items-center justify-center">
          <ResponsiveContainer height={50} width="50%">
            <BarChart
              data={[
                {
                  name: "results",
                  before: original,
                  after: current,
                },
              ]}
              layout="vertical"
            >
              <XAxis type="number" hide={true} />
              <YAxis type="category" dataKey="results" hide={true} />
              <Bar dataKey="before" fill={SCALEOPS_COLORS.main.red} />
              <Bar dataKey="after" fill={SCALEOPS_COLORS.main.green} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
      <div className="bg-strongBorder h-[133px] w-[1px] absolute top-[5%] right-0" />
    </div>
  );
};

export default VsBox;
