interface Props {
  namespace?: string;
  workloadName?: string;
  type?: string;
}
const keyValRender = (key: string, value?: string) =>
  value && (
    <div>
      <b>{key}:</b> {value}
    </div>
  );

const WorkloadTooltip = ({ namespace, workloadName, type }: Props) => {
  return (
    <div className={"flex flex-col gap-0.5"}>
      {keyValRender("Namespace", namespace)}
      {keyValRender("Name", workloadName)}
      {keyValRender("Type", type)}
    </div>
  );
};

export default WorkloadTooltip;
