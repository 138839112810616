import React from "react";

interface Props {
  count?: number;
  maxToShow?: number;
}

const ChartTooltipItemsCount = ({ count, maxToShow }: Props) => {
  if (!count || !maxToShow) {
    return null;
  }
  return (
    <div className="flex justify-left mr-1 text-text-darkGray">
      Showing {count <= maxToShow ? count : maxToShow} out of {count}
    </div>
  );
};

export default ChartTooltipItemsCount;
