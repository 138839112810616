import { Typography } from "@mui/material";
import { SCALEOPS_COLORS } from "../../../colors";
import CircleCheckIcon from "../../../Icons/CircleCheckIcon";
import CloseIcon from "../../../Icons/CloseIcon";
import DataTag from "../../../utils/DataTag";
import { formatXDigits } from "../Utils";
import SelectViewPeriod from "./SelectViewPeriod";

const ICON_SIZE = 14;
interface Props {
  selectedViewPeriod: string;
  setSelectedViewPeriod: (option: string) => void;
  tags?: string[];
  status?: string;
  unschedulable?: boolean;
  blocked?: boolean;
  cost: number;
}

const renderTypes = (tags?: string[]) => {
  return (
    <Typography variant="caption" className="gap-1 block flex items-center">
      <b> Lifecycle:</b>
      {tags?.map((tag) => (
        <DataTag key={tag} title={tag} background={SCALEOPS_COLORS.main.deepBlue} color={SCALEOPS_COLORS.main.info} />
      ))}
    </Typography>
  );
};

const setYesNoIcon = (value: boolean | undefined) => {
  return value ? (
    <CircleCheckIcon className="text-main-green m-1" width={ICON_SIZE} height={ICON_SIZE} />
  ) : (
    <CloseIcon width={ICON_SIZE} height={ICON_SIZE} className="m-1" />
  );
};

const TopSection = ({
  selectedViewPeriod,
  setSelectedViewPeriod,
  tags,
  status,
  unschedulable,
  blocked,
  cost,
}: Props) => {
  const hasTypes = (tags && tags?.length > 0) || unschedulable || blocked;
  const statusValue = status === "Ready" ? " Ready" : " Not Ready";
  return (
    <div className="border border-border rounded-lg w-full p-4 flex">
      <div className="flex justify-start gap-5 grow ml-[10px]">
        <div className="gap-1 flex flex-col">
          <Typography variant="caption" className="gap-1 flex items-center">
            <b> Monthly cost:</b>
            {<p>${formatXDigits(cost)}</p>}
          </Typography>
          <Typography variant="caption" className="gap-1 flex items-center">
            <b> Status:</b>
            <span>{statusValue}</span>
          </Typography>
          {hasTypes && renderTypes(tags)}
        </div>
        <div className="gap-1 flex-1 ml-[25px]">
          <Typography variant="caption">
            <div className="flex">
              <b> Unschedulable:</b>
              {setYesNoIcon(unschedulable)}
            </div>
            <div className="flex">
              <b> Scale down blocker:</b>
              {setYesNoIcon(blocked)}
            </div>
          </Typography>
        </div>
      </div>
      <div className="h-16 w-[1px] bg-border" />
      <div className="pl-4 grow-0">
        <SelectViewPeriod selectedViewPeriod={selectedViewPeriod} setSelectedViewPeriod={setSelectedViewPeriod} />
      </div>
    </div>
  );
};
export default TopSection;
