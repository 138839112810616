import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { ArrayParam, StringParam, useQueryParam } from "use-query-params";
import { GetReportsGraph, GetReportsGraphResponse, ProvisionedWRTOriginTypes } from "../../api/fetcher";
import { SCALEOPS_COLORS } from "../../colors";
import { DateType } from "../../pages/Analytics/AnalyticsV2/utils";
import { arrToStringArr } from "../../utils/arrayUtils";
import { ResourseOverTimeTabOptions } from "../../utils/graphUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../utils/useGetTimeoutSeconds";
import CustomLegend from "../CustomLegend";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../Tab";
import { GraphLine, LineStyle, ResourceType, graphLineNames } from "./graphUtils";
import ResourceGraph from "./ResourceGraph";

const INCLUDED_GRAPH_LINES = [GraphLine.Requests, GraphLine.RequestsOrigin, GraphLine.Allocatable];

interface Props {
  setDate?: (date: DateType) => void;
  hasSavingsDiff: boolean;
  isMultiCluster?: boolean;
}

const { queryFn, queryKey } = GetReportsGraph();

const ResourcesOverTimeChartsContainer = ({ setDate, hasSavingsDiff, isMultiCluster }: Props) => {
  const [selectedTab, setSelectedTab] = useState<ResourseOverTimeTabOptions>(ResourseOverTimeTabOptions.SevenDays);
  const [window, setWindow] = useState<string | undefined>(undefined);
  const [selectedChartComponents, setSelectedChartComponents] = useState<GraphLine[]>([
    GraphLine.Requests,
    GraphLine.RequestsOrigin,
    GraphLine.Savings,
  ]);

  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);

  const [tags] = useQueryParam("tags", ArrayParam);
  const [clusters] = useQueryParam("clusters", ArrayParam);
  const [namespaces] = useQueryParam("namespaces", ArrayParam);
  const [labels] = useQueryParam("labels", ArrayParam);
  const [annotations] = useQueryParam("annotations", ArrayParam);
  const [overProvisionedWRTOrigin] = useQueryParam("overProvisionedWRTOrigin", StringParam);
  const [underProvisionedWRTOrigin] = useQueryParam("underProvisionedWRTOrigin", StringParam);

  const { data, isLoading, error, isError } = useQuery<GetReportsGraphResponse, Error>({
    queryKey: [
      queryKey,
      isMultiCluster ? "multicluster" : undefined,
      tags,
      clusters,
      namespaces,
      labels,
      annotations,
      overProvisionedWRTOrigin,
      underProvisionedWRTOrigin,
      window,
    ],
    queryFn: () =>
      queryFn({
        multiCluster: isMultiCluster,
        clusters: arrToStringArr(clusters),
        tags: arrToStringArr(tags),
        namespaces: arrToStringArr(namespaces),
        labels: arrToStringArr(labels),
        annotations: arrToStringArr(annotations),
        overProvisionedWRTOrigin: overProvisionedWRTOrigin as ProvisionedWRTOriginTypes,
        underProvisionedWRTOrigin: underProvisionedWRTOrigin as ProvisionedWRTOriginTypes,
        timeoutSeconds: timeoutSeconds,
        window: window,
      }),
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
    enabled: isQueryEnabled,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMultiCluster });

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  useEffect(() => {
    setIsQueryEnabled(true);
  }, [tags]);

  useEffect(() => {
    let days;
    switch (selectedTab) {
      case ResourseOverTimeTabOptions.SevenDays:
        days = 7;
        break;
      case ResourseOverTimeTabOptions.ThirtyDays:
        days = 30;
        break;
    }
    setWindow(`${days * 24}h`);
  }, [selectedTab]);

  const legendComponentStyle: { [key: string]: { color: string } } = {};

  Object.values(GraphLine).forEach((key) => {
    legendComponentStyle[key] = {
      color: LineStyle[key].stroke,
    };
  });

  // const daysAgo = selectedTab === ResourseOverTimeTabOptions.SevenDays ? 7 : 30;

  return (
    <div className="relative">
      <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-1px] w-full")}>
        {Object.entries(ResourseOverTimeTabOptions).map(([key, value]) => {
          return (
            <Tab
              key={key}
              isSelected={selectedTab === value}
              onClick={() => {
                setSelectedTab(value);
              }}
              name={String(value)}
              dataTestId={`node-cost-chart-${key}-tab`}
              hasDefaultGrayBorders
            />
          );
        })}
      </div>
      <div className="px-4 pb-4 bg-white w-[100%] border border-border rounded rounded-tl-none mt-[34px]">
        {/* CPU & Memory */}
        <>
          <div className="flex">
            <ResourceGraph
              selectedGraphLines={selectedChartComponents}
              resourceType={ResourceType.CPU}
              setDate={setDate}
              includedGraphLines={INCLUDED_GRAPH_LINES}
              hasSavingsDiff={hasSavingsDiff}
              data={data}
              isLoading={isLoading}
              error={error}
              isError={isError}
            />
            <ResourceGraph
              selectedGraphLines={selectedChartComponents}
              resourceType={ResourceType.Memory}
              setDate={setDate}
              includedGraphLines={INCLUDED_GRAPH_LINES}
              hasSavingsDiff={hasSavingsDiff}
              data={data}
              isLoading={isLoading}
              error={error}
              isError={isError}
            />
          </div>
          <div className="flex flex-wrap gap-4 justify-center w-full mt-[-14px]">
            <CustomLegend
              selectedChartComponents={selectedChartComponents}
              setSelectedChartComponents={setSelectedChartComponents}
              componentStyle={{
                [GraphLine.Requests]: {
                  color: LineStyle[GraphLine.Requests].stroke,
                },
                [GraphLine.RequestsOrigin]: {
                  color: LineStyle[GraphLine.RequestsOrigin].stroke,
                },
                [GraphLine.Savings]: {
                  color: SCALEOPS_COLORS.main.green,
                },
                [GraphLine.Allocatable]: {
                  color: LineStyle[GraphLine.Allocatable].stroke,
                },
              }}
              // waste capital insensitive
              isDashedFnc={(key: string) => key.includes("savings")}
              ChartComponents={{
                [GraphLine.Requests]: GraphLine.Requests,
                [GraphLine.RequestsOrigin]: GraphLine.RequestsOrigin,
                [GraphLine.Savings]: GraphLine.Savings,
                [GraphLine.Allocatable]: GraphLine.Allocatable,
              }}
              renderNameFunction={(key: string) => {
                return graphLineNames[key as keyof typeof graphLineNames];
              }}
              className="-mt-1"
            />
          </div>
        </>

        {/* Rightsize and unevictable pods 
        <>
          <div className="flex w-full gap-[20px] mt-8">
            <div className="w-[calc(50%-10px)]">
              <WorkloadComposeChart
                data={data}
                isLoading={isLoading}
                title="Rightsizing pods"
                daysAgo={daysAgo}
                elements={[
                  {
                    key: "totalPods",
                    color: SCALEOPS_COLORS.main.blue,
                    name: "Number of pods",
                  },
                  {
                    key: "rightSizedPods",
                    color: SCALEOPS_COLORS.main.green,
                    name: "Number of optimized pods",
                  },
                ]}
                yAxisTickFormatterType={YAxisTickFormatterType.Number}
              />
            </div>
            <div className="w-[calc(50%-10px)]">
              <WorkloadComposeChart
                data={data}
                isLoading={isLoading}
                title="Unevcitable pods"
                daysAgo={daysAgo}
                elements={[
                  {
                    key: "unevictablePods",
                    color: SCALEOPS_COLORS.main.blue,
                    name: "Number of unevcitable pods",
                  },
                  {
                    key: "binPackedPods",
                    color: SCALEOPS_COLORS.main.green,
                    name: "Number of optimized unevcitable pods",
                  },
                ]}
                yAxisTickFormatterType={YAxisTickFormatterType.Number}
              />
            </div>
          </div>
        </>*/}
      </div>
    </div>
  );
};

export default ResourcesOverTimeChartsContainer;
