import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { memo, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useWorkloadsContext } from "../../../WorkloadsContext";
import {
  GetPolicyTuningConfigParams,
  GetPolicyTuningConfigParamsResponse,
  getPolicyTuningHpaCurrentData,
  GetPolicyTuningHpaCurrentDataResponse,
} from "../../../api/fetcher";
import { components } from "../../../api/schema";
import CustomLegend from "../../../components/CustomLegend";
import DefaultFallback from "../../../components/DefaultFallback";
import CalculatingHPAAlertBar from "./CalculatingHPAAlertBar";
import CostBarChart from "./CostBarChart";
import PredictableBadges from "./PredictableBadges";
import ReplicasChart from "./ReplicasChart";
import SaveAndAutomateButtons from "./SaveAndAutomateButtons";
import SelectPolicy from "./SelectPolicy";
import SelectViewPeriod from "./SelectViewPeriod";
import SwitchToHPA from "./SwitchToHPA";
import { HpaChartComponent } from "./UsageChart/UsageHpaChart";
import {
  displayNameHpaLegendFormatter,
  getReplicasComponentsStyle,
  Policy,
  useViewPeriodQueryParams,
  ViewPeriodOptions,
} from "./utils";

const TopSectionDivider = () => <div className="bg-border w-[1px] h-16" />;

const getCustomElementTooltip = (key: string): React.ReactNode => {
  const output: React.ReactNode = undefined;

  switch (key) {
    case "triggerBasedReplicas:cpu":
      return (
        <Typography variant="caption">
          The <b>maximum replicas</b> calculated by the HPA, taking the upper limit from all triggers.
        </Typography>
      );
    default:
      break;
  }
  return output;
};

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  setWorkloadName: (title: string) => void;
  fetchWorkloads: () => void;
  tuningParams: GetPolicyTuningConfigParamsResponse;
  setTuningParams: React.Dispatch<React.SetStateAction<GetPolicyTuningConfigParamsResponse>>;
  selectedPolicy: Policy | undefined;
  setSelectedPolicy: React.Dispatch<React.SetStateAction<Policy | undefined>>;
  selectedChartComponents: HpaChartComponent[];
  setSelectedChartComponents: React.Dispatch<React.SetStateAction<HpaChartComponent[]>>;
  isAutomate: boolean;
  setIsAutomate: React.Dispatch<React.SetStateAction<boolean>>;
}

const PolicyTuningHpa = memo(
  ({
    selectedWorkload,
    setWorkloadName,
    fetchWorkloads,
    tuningParams,
    setTuningParams,
    selectedPolicy,
    setSelectedPolicy,
    selectedChartComponents,
    setSelectedChartComponents,
    isAutomate,
    setIsAutomate,
  }: Props) => {
    const { overriddenWorkloadsIds } = useWorkloadsContext();

    const namespace = selectedWorkload.namespace;
    const name = `${selectedWorkload.type.toLocaleLowerCase()}-${selectedWorkload.workloadName}`;
    const getPolicyTuningConfigParams = GetPolicyTuningConfigParams();
    const [hpaChartComponents, setHpaChartComponents] = useState<Record<string, HpaChartComponent>>(
      {} as Record<string, HpaChartComponent>
    );
    const [, setSelectedViewPeriod] = useViewPeriodQueryParams();
    const policyTuningHpaCurrentData = getPolicyTuningHpaCurrentData();
    const { data: currentData, isLoading: isCurrentDataLoading } = useQuery<
      GetPolicyTuningHpaCurrentDataResponse,
      Error
    >(
      [policyTuningHpaCurrentData.queryKey, name, namespace],
      () => {
        return policyTuningHpaCurrentData.queryFn({
          name,
          namespace,
        });
      },
      {
        retry: 10,
        refetchOnWindowFocus: false,
      }
    );

    const { data: dataTunningParams } = useQuery<GetPolicyTuningConfigParamsResponse, Error>({
      queryKey: [getPolicyTuningConfigParams.queryKey, selectedWorkload.id, selectedPolicy],
      queryFn: () =>
        getPolicyTuningConfigParams.queryFn({
          policyName: selectedPolicy?.name ?? "",
          namespace,
          name,
        }),
    });

    useEffect(() => {
      const isCreatedAtLessThan4Hours =
        selectedWorkload.createdAt?.length > 0 &&
        Number(selectedWorkload.createdAt) !== 0 &&
        dayjs().diff(dayjs(selectedWorkload.createdAt), "hour") < 4;

      setSelectedViewPeriod(isCreatedAtLessThan4Hours ? ViewPeriodOptions["4 hours"] : ViewPeriodOptions["7 days"]);
    }, []);

    useEffect(() => {
      if (selectedWorkload) {
        setWorkloadName(`${selectedWorkload.type}: ${selectedWorkload.namespace}/${selectedWorkload.workloadName}`);
      }
    }, [selectedWorkload]);

    useEffect(() => {
      if (dataTunningParams) {
        setTuningParams({
          cpuPolicyTuningParams: { ...dataTunningParams.cpuPolicyTuningParams },
          memoryPolicyTuningParams: { ...dataTunningParams.memoryPolicyTuningParams },
        });
      }
    }, [dataTunningParams]);

    const shouldShowCalculatingHPAMessage =
      !isCurrentDataLoading && (!currentData?.optimizationStrategy || currentData.optimizationStrategy.length === 0);

    return (
      <div className="flex flex-col gap-4 w-full">
        {shouldShowCalculatingHPAMessage && <CalculatingHPAAlertBar />}
        <SwitchToHPA selectedPolicy={selectedPolicy} setSelectedPolicy={setSelectedPolicy} />
        <div className="flex gap-6 p-4 items-center border rounded-lg border-border">
          <div className="w-fit flex gap-8">
            {selectedPolicy && (
              <ErrorBoundary
                fallback={<DefaultFallback message="Failed to load Selected Policy. Please check your setup." />}
              >
                <SelectPolicy
                  selectedPolicy={selectedPolicy}
                  namespace={selectedWorkload.namespace}
                  setSelectedPolicy={setSelectedPolicy}
                  isOverridePolicy={overriddenWorkloadsIds.includes(selectedWorkload.id)}
                  smartPolicyName={selectedWorkload.smartPolicyName}
                  smartPolicyWorkloadType={selectedWorkload.smartPolicyWorkloadType}
                />
              </ErrorBoundary>
            )}
            <ErrorBoundary
              fallback={<DefaultFallback message="Failed to load Selected View Period. Please check your setup." />}
            >
              <SelectViewPeriod />
            </ErrorBoundary>
          </div>
          <TopSectionDivider />
          <div className="grow">
            <div className="max-w-[500px]">
              <CostBarChart
                currentCost={currentData?.currentCost ?? 0}
                optimizedCost={currentData?.optimizedCost ?? 0}
              />
            </div>
          </div>
          <TopSectionDivider />
          <div className="flex flex-col w-fit justify-end gap-1.5">
            <ErrorBoundary
              fallback={<DefaultFallback message="Failed to load Cost Bar Chart. Please check your setup." />}
            >
              <div className="relative cursor-default">
                <PredictableBadges data={currentData} />
              </div>
            </ErrorBoundary>
          </div>
        </div>
        <div>
          {tuningParams && selectedPolicy && (
            <ErrorBoundary fallback={<DefaultFallback message="Failed to load Chart. Please check your setup." />}>
              <div className="border rounded-lg border-border">
                <ReplicasChart
                  selectedWorkload={selectedWorkload}
                  policyName={selectedPolicy.name}
                  selectedChartComponents={selectedChartComponents}
                  setSelectedChartComponents={setSelectedChartComponents}
                  setHpaChartComponents={setHpaChartComponents}
                />
                <CustomLegend<HpaChartComponent>
                  selectedChartComponents={selectedChartComponents}
                  setSelectedChartComponents={setSelectedChartComponents}
                  componentStyle={getReplicasComponentsStyle(hpaChartComponents)}
                  ChartComponents={hpaChartComponents}
                  className="mt-1 pb-3"
                  displayNameFormatter={displayNameHpaLegendFormatter}
                  customElementTooltip={getCustomElementTooltip}
                />
              </div>
            </ErrorBoundary>
          )}
        </div>
        <SaveAndAutomateButtons
          selectedWorkload={selectedWorkload}
          selectedPolicy={selectedPolicy}
          fetchWorkloads={fetchWorkloads}
          isAutomate={isAutomate}
          setIsAutomate={setIsAutomate}
        />
      </div>
    );
  }
);

export default PolicyTuningHpa;
