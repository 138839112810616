import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import {
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE,
} from "./GridColumnsUtils";
import { Typography } from "@mui/material";
import Tooltip from "../../Tooltip";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import HPAWorkload from "../HPAWorkload";
import RightArrowIcon from "../../../Icons/RightArrowIcon";

interface ReplicasColumnProps {
  hpaOptimizationEnabled: boolean | undefined;
}

export default function ReplicasColumn({
  params,
  hpaOptimizationEnabled,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
} & ReplicasColumnProps) {
  if (isUnallocatedRow(params.row)) return <div></div>;
  return (
    <div className="flex flex-col items-start gap-[.425rem]">
      <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
        <Tooltip
          title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
          maxWidth={400}
          className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
          enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
        >
          {params.row.runningReplicas || 0} / {params.row.replicas}
        </Tooltip>
        {!hpaOptimizationEnabled && <HPAWorkload workload={params.row} iconClassName="absolute right-[-18px]" />}
      </Typography>
      {hpaOptimizationEnabled &&
        params.row.hasHpa &&
        params.row.recommendedReplicas &&
        params.row.isReadyRecommendation == true && (
          <div className="flex items-center gap-1">
            <span className="text-guideline-darkGreen">{params.row.replicas}</span>
            <RightArrowIcon width={10} height={10} className="w-full" />
            <span className="text-guideline-darkGreen">{params.row.recommendedReplicas}</span>
            <HPAWorkload workload={params.row} iconClassName="absolute right-[-18px]" />
          </div>
        )}
    </div>
  );
}
