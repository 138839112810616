import RunningNumberBox from "../../pages/Overview/TopOverviewSection/RunningNumberBox";
import { SCALEOPS_COLORS } from "../../colors";
import PartialBorders from "../PartialBorders";

const HALF_DIV_CLASS_WRAP = `h-[134px]`;

const AverageSpan = () => <span className="text-text-disable text-[14px]">(avg.)</span>;
interface Props {
  cost?: number;
  cpuUsage?: number;
  cpuRequests?: number;
  cpuAllocated?: number;
  memoryUsage?: number;
  memoryRequests?: number;
  memoryAllocated?: number;
  isLoading?: boolean;
}
const NodesTopOverview = ({
  cost = NaN,
  cpuUsage = NaN,
  cpuRequests = NaN,
  cpuAllocated = NaN,
  memoryUsage = NaN,
  memoryRequests = NaN,
  memoryAllocated = NaN,
  isLoading,
}: Props) => {
  const cpuRequestPercentage = cpuRequests / cpuAllocated;
  const cpuUsagePercentage = cpuUsage / cpuAllocated;
  const memoryRequestPercentage = memoryRequests / memoryAllocated;
  const memoryUsagePercentage = memoryUsage / memoryAllocated;

  return (
    <div className={`w-full rounded flex h-[270px] bg-white relative`}>
      <PartialBorders right wrapperClassName="w-[70%]">
        <RunningNumberBox
          value={cost}
          title="Monthly cost"
          numberVariant="h3"
          prefix="$"
          numberClassName="text-text-lightBlack"
          isLoading={isLoading}
        />
      </PartialBorders>
      <PartialBorders right wrapperClassName="h-full relative">
        <PartialBorders bottom wrapperClassName={HALF_DIV_CLASS_WRAP}>
          <RunningNumberBox
            value={cpuRequestPercentage * 100}
            barPercentageValue={cpuRequestPercentage}
            title={
              <>
                CPU request <AverageSpan />
              </>
            }
            barPercentageColor={SCALEOPS_COLORS.guideline.darkYellow}
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            suffix="%"
            isLoading={isLoading}
          />
        </PartialBorders>
        <PartialBorders wrapperClassName={HALF_DIV_CLASS_WRAP}>
          <RunningNumberBox
            value={cpuUsagePercentage * 100}
            barPercentageValue={cpuUsagePercentage}
            title={
              <>
                CPU usage <AverageSpan />
              </>
            }
            barPercentageColor={SCALEOPS_COLORS.main.blue}
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            suffix="%"
            isLoading={isLoading}
          />
        </PartialBorders>
      </PartialBorders>
      <PartialBorders>
        <PartialBorders bottom wrapperClassName={HALF_DIV_CLASS_WRAP}>
          <RunningNumberBox
            value={memoryRequestPercentage * 100}
            barPercentageValue={memoryRequestPercentage}
            title={
              <>
                Memory request <AverageSpan />
              </>
            }
            barPercentageColor={SCALEOPS_COLORS.guideline.darkYellow}
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            suffix="%"
            isLoading={isLoading}
          />
        </PartialBorders>
        <PartialBorders wrapperClassName={HALF_DIV_CLASS_WRAP}>
          <RunningNumberBox
            value={memoryUsagePercentage * 100}
            barPercentageValue={memoryUsagePercentage}
            title={
              <>
                Memory usage <AverageSpan />
              </>
            }
            barPercentageColor={SCALEOPS_COLORS.main.blue}
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            suffix="%"
            isLoading={isLoading}
          />
        </PartialBorders>
      </PartialBorders>
    </div>
  );
};

export default NodesTopOverview;
