import clsx from "clsx";
import { useState } from "react";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../../../../components/Tab";
import { TabOptions } from "./utils";

const WorkloadNetworkChartsContainer = () => {
  const [selectedTab, setSelectedTab] = useState(TabOptions.Summary);

  return (
    <div className="relative border border-border border-lg mt-[36px]">
      <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-36px] left-[-1px]")}>
        {Object.entries(TabOptions).map(([key, value]) => {
          return (
            <Tab
              key={key}
              isSelected={selectedTab === value}
              onClick={() => {
                setSelectedTab(value);
              }}
              name={String(value)}
              dataTestId={`node-cost-chart-${key}-tab`}
              hasDefaultGrayBorders
            />
          );
        })}
      </div>
      <div className="h-[200px] p-8">Workload network chart container</div>
    </div>
  );
};

export default WorkloadNetworkChartsContainer;
