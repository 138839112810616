import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs from "dayjs";
import prettyBytes from "pretty-bytes";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CurveType } from "recharts/types/shape/Curve";
import { useMainContext } from "../../../../MainContext";
import {
  AnalyticsGraphTypes,
  GetAnalyticsGraph,
  GetAnalyticsGraphResponse,
  GetAnalyticsGraphResponseParams,
} from "../../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../../colors";
import ChartTooltipElement from "../../../../components/ChartTooltipElement";
import ChartTooltipTime from "../../../../components/ChartTooltipTime";
import Loading from "../../../../components/Loading";
import useHpaOptimizationEnabled from "../../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import {
  capitalizeFirstLetter,
  DAY_MONTH_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  spacedCamelCase,
} from "../../../../utils/formatterUtils";
import { CHART_WRAPPER_CLASS_NAME, NO_OUTLINE, TOOLTIP_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../../utils/useGetTimeoutSeconds";
import useFilterQueryParams from "../useFilterQueryParams";
import { DateType } from "../utils";
import { ANALYTICS_PAGE_HOURLY_SYNC_ID, GraphLine, LineStyle, ResourceType } from "./graphUtils";

export const savingDiffPattern = (
  <pattern id="color-stripe" width="8" height="8" patternUnits="userSpaceOnUse" patternTransform="rotate(45)">
    <rect width="2" height="8" fill={SCALEOPS_COLORS.main.green} />
  </pattern>
);
interface CustomTooltipProps {
  active?: boolean;
  payload?: { value: string | number; name?: string; dataKey: string; stroke: string }[];
  label?: string;
  selectedChartComponents: string[];
  resourceType: ResourceType;
  valueSuffix?: React.ReactNode;
  hasSavingsDiff?: boolean;
  displayNameFormatter?: (key: string) => string;
  includeHour?: boolean;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  selectedChartComponents,
  hasSavingsDiff,
  resourceType,
  displayNameFormatter,
  includeHour,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    let diffValue = 0;
    if (hasSavingsDiff) {
      const requestValue = payload.find((item) => item.name === GraphLine.Requests)?.value;
      const requestOriginValue = payload.find((item) => item.name === GraphLine.RequestsOrigin)?.value;
      diffValue = Number(requestOriginValue) - Number(requestValue);
      diffValue = Math.round(diffValue * 100) / 100;
    }

    payload = payload.filter((item, index, self) => self.findIndex((t) => t.name === item.name) === index);

    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        {label && (
          <ChartTooltipTime
            timestamp={label}
            timeFormat={includeHour ? DEFAULT_DATE_TIME_FORMAT : DEFAULT_DATE_FORMAT}
          />
        )}
        {payload.reverse().map((item, index) => {
          let displayName = capitalizeFirstLetter(spacedCamelCase(item.name ?? ""));
          displayName = displayNameFormatter ? displayNameFormatter(displayName) : displayName;

          if (selectedChartComponents.includes(item.name ?? "")) {
            let value: string | number = "";
            if (resourceType === ResourceType.Memory) {
              value = prettyBytes(Number(item.value) || 0.0, {
                bits: false,
                binary: true,
              });
            } else {
              value = item.value;
            }

            return (
              <ChartTooltipElement key={index} color={item?.stroke ?? ""} value={<>{value}</>} label={displayName} />
            );
          }
        })}
        {hasSavingsDiff && !isNaN(diffValue) && diffValue > 0 && (
          <ChartTooltipElement
            key={diffValue}
            color={SCALEOPS_COLORS.main.green}
            value={
              <>
                {resourceType === ResourceType.Memory
                  ? prettyBytes(Number(diffValue) || 0.0, {
                      bits: false,
                      binary: true,
                    })
                  : diffValue}
              </>
            }
            label="Savings"
            isDashed
          />
        )}
      </div>
    );
  }

  return null;
};

const HEIGHT = "h-[250px]";
interface Props {
  queryParams: GetAnalyticsGraphResponseParams;
  selectedGraphLines: string[];
  resourceType: ResourceType;
  setDate?: (date: DateType) => void;
  includedGraphLines?: GraphLine[];
  hasSavingsDiff?: boolean;
  displayNameFormatter?: (key: string) => string;
  isMulticluster?: boolean;
  types?: AnalyticsGraphTypes;
  LabelSpan?: React.ReactNode;
  setHasError?: (value: boolean) => void;
}

const ResourceGraph = ({
  queryParams,
  selectedGraphLines,
  resourceType,
  setDate,
  includedGraphLines = [
    GraphLine.Usage,
    GraphLine.Requests,
    GraphLine.RequestsOrigin,
    GraphLine.Recommendation,
    GraphLine.Allocatable,
  ],
  hasSavingsDiff,
  displayNameFormatter,
  isMulticluster,
  types = [
    `${resourceType}Requests`,
    `${resourceType}RequestsOrigin`,
    `${resourceType}Recommendation`,
    `${resourceType}Allocatable`,
    `${resourceType}Usage`,
    `${resourceType}RequestsOriginWithReplicas`,
    `${resourceType}RecommendationWithReplicas`,
  ],
  LabelSpan,
  setHasError,
}: Props) => {
  const { didClusterChanged } = useMainContext();
  const { queryFn, queryKey } = GetAnalyticsGraph();
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);
  const [animationActive, setAnimationActive] = useState<boolean>(false);
  const [graphData, setGraphData] = useState<{ [key: string]: string | number }[] | undefined>(undefined);

  const enableHpaOptimization = useHpaOptimizationEnabled();

  useEffect(() => {
    setAnimationActive(true);
    setTimeout(() => setAnimationActive(false), 3000);

    return () => {
      setAnimationActive(false);
    };
  }, [queryParams]);

  const filtersQueryParams = useFilterQueryParams();

  const { data, isLoading, error, isError } = useQuery<GetAnalyticsGraphResponse, Error>({
    queryKey: [
      queryKey,
      resourceType,
      queryParams,
      isMulticluster ? "multicluster" : undefined,
      filtersQueryParams,
      types,
    ],
    queryFn: () =>
      queryFn({
        from: queryParams.from,
        to: queryParams.to,
        range: queryParams.range,
        groupBy: queryParams.groupBy,
        multiCluster: isMulticluster,
        timeoutSeconds: timeoutSeconds,
        types,
        ...filtersQueryParams,
      }),
    enabled: isQueryEnabled,
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMulticluster });

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  if (didClusterChanged && error) {
    toast.error("Error fetching data for this cluster");
    setHasError && setHasError(true);
    console.log(error);
  }

  useEffect(() => {
    if (isMulticluster && !!data) {
      setIsQueryEnabled(false);
    }
  }, [data]);

  useEffect(() => {
    if (isLoading || isError) {
      setIsQueryEnabled(true);
    }
  }, [queryParams, filtersQueryParams, isLoading, isError]);
  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to && setDate) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDate({ from: from, to: to, range: "" });
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  useEffect(() => {
    if (data && data.values) {
      const newData = data?.values?.map((item) => {
        const useDataWithReplicas = enableHpaOptimization;
        const resourceTypeRequestsOriginKey = `${resourceType}RequestsOrigin`;
        const resourceTypeRequestsOriginWithReplicasKey = `${resourceType}RequestsOriginWithReplicas`;
        const resourceTypeRecommendationKey = `${resourceType}Recommendation`;
        const resourceTypeRecommendationWithReplicasKey = `${resourceType}RecommendationWithReplicas`;

        return {
          date: String(item.timestamp),
          ...item.values,
          ...(useDataWithReplicas &&
            item.values != undefined &&
            resourceTypeRequestsOriginKey in item.values && {
              [resourceTypeRequestsOriginKey]: item.values[resourceTypeRequestsOriginWithReplicasKey],
            }),
          ...(useDataWithReplicas &&
            item.values != undefined &&
            resourceTypeRecommendationKey in item.values && {
              [resourceTypeRecommendationKey]: item.values[resourceTypeRecommendationWithReplicasKey],
            }),
        };
      });

      // set the data structure to be grouped by date
      const groupedData = newData.reduce((acc, item) => {
        const date =
          queryParams.groupBy === "day"
            ? dayjs(item.date).format("DD/MMM/YYYY")
            : queryParams.groupBy === "hour"
            ? dayjs(item.date).format("YYYY-MM-DD HH:mm:ss")
            : dayjs(item.date).startOf("week").format("DD/MMM/YYYY");
        acc[date] = item;
        return acc;
      }, {} as { [key: string]: { [key: string]: string | number } });

      let groupedDataArr: {
        [key: string]: string | number;
      }[] = Object.entries(groupedData).map(([key, value]) => {
        return {
          ...value,
          date: key,
        };
      });

      // round values to 2 decimal places
      groupedDataArr = groupedDataArr.map((item) => {
        const newItem = { ...item };
        Object.keys(item).forEach((key) => {
          if (key !== "date") {
            newItem[key] = Math.round(Number(item[key]) * 100) / 100;
          }
        });
        return newItem;
      });
      setGraphData(groupedDataArr);
    }
  }, [data, queryParams]);

  if (isLoading) {
    return (
      <div className={clsx(HEIGHT, "w-[50%]")}>
        <Loading hasTitle={false} hasFullWrapper />
      </div>
    );
  }

  if (isError) {
    console.log(error);
    return null;
  }

  const firstXPointString = graphData && String(graphData[0]?.date);
  const firstXPointEpoch = dayjs(firstXPointString).unix() * 1000;
  let lastXPointString = graphData && String(graphData[graphData.length - 1]?.date);
  lastXPointString = lastXPointString && `${lastXPointString} GMT`;
  const lastXPointEpoch = dayjs(lastXPointString).unix() * 1000;

  return (
    <div className={clsx(HEIGHT, CHART_WRAPPER_CLASS_NAME, "w-[50%]")}>
      <Typography variant="body2" className="w-full text-center">
        {resourceType === ResourceType.CPU ? "CPU" : "Memory"} {LabelSpan}
      </Typography>
      <ResponsiveContainer width="100%" height="100%" className="pt-[20px]">
        <ComposedChart
          syncId={ANALYTICS_PAGE_HOURLY_SYNC_ID}
          data={graphData}
          onMouseDown={(e) => {
            e.activeLabel &&
              setDate &&
              setSelectPosition({
                ...selectPosition,
                from: dayjs(`${String(e.activeLabel)} GMT`).unix() * 1000,
                fromX: e.activeLabel,
              });
          }}
          onMouseMove={(e) => {
            selectPosition?.from &&
              setDate &&
              setSelectPosition({
                ...selectPosition,
                to: dayjs(`${String(e.activeLabel)} GMT`).unix() * 1000,
                toX: e.activeLabel,
              });
          }}
          onMouseLeave={() => {
            if (selectPosition?.from && selectPosition?.to && setDate) {
              if (selectPosition?.from < selectPosition?.to) {
                setSelectPosition({
                  ...selectPosition,
                  to: lastXPointEpoch,
                  toX: lastXPointString,
                });
              } else {
                setSelectPosition({
                  to: selectPosition.from,
                  toX: selectPosition.fromX,
                  from: firstXPointEpoch,
                  fromX: firstXPointString,
                });
              }
            }
          }}
        >
          <Tooltip
            wrapperStyle={NO_OUTLINE}
            content={
              <CustomTooltip
                selectedChartComponents={selectedGraphLines}
                resourceType={resourceType}
                hasSavingsDiff={hasSavingsDiff}
                displayNameFormatter={displayNameFormatter}
                includeHour={queryParams.groupBy === "hour"}
              />
            }
          />
          {Object.values(GraphLine).map((value) => {
            if (!selectedGraphLines.includes(value) || !includedGraphLines.includes(value)) return null;
            const dataKey = `${resourceType}${capitalizeFirstLetter(value)}`;
            return (
              <Area
                type={LineStyle[value].type as CurveType}
                dataKey={dataKey}
                name={value}
                strokeWidth={LineStyle[value].strokeWidth}
                stroke={LineStyle[value].stroke}
                fill={
                  hasSavingsDiff && value === GraphLine.RequestsOrigin ? "url(#color-stripe)" : LineStyle[value].fill
                }
                fillOpacity={LineStyle[value].fillOpacity}
                dot={false}
                isAnimationActive={animationActive}
              />
            );
          })}
          {selectedGraphLines.includes(GraphLine.RequestsOrigin) &&
            includedGraphLines.includes(GraphLine.RequestsOrigin) && (
              <Area
                type={LineStyle[GraphLine.RequestsOrigin].type as CurveType}
                dataKey={`${resourceType}${capitalizeFirstLetter(GraphLine.RequestsOrigin)}`}
                name={GraphLine.RequestsOrigin}
                strokeWidth={LineStyle[GraphLine.RequestsOrigin].strokeWidth}
                stroke={LineStyle[GraphLine.RequestsOrigin].stroke}
                fill="none"
                fillOpacity={LineStyle[GraphLine.RequestsOrigin].fillOpacity}
                dot={false}
                isAnimationActive={animationActive}
              />
            )}
          <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
          <YAxis
            style={{ fontSize: "x-small" }}
            tickFormatter={(tick: string) => {
              if (resourceType === ResourceType.Memory) {
                return prettyBytes(Number(tick) > 0 ? Number(tick) : 0.0, {
                  bits: false,
                  binary: true,
                });
              }
              return tick;
            }}
            strokeWidth={2}
          />
          <XAxis
            dataKey="date"
            style={{ fontSize: "x-small" }}
            strokeWidth={2}
            interval={queryParams.groupBy === "hour" ? 24 : undefined}
            tickFormatter={(value) => dayjs(String(value)).format(DAY_MONTH_FORMAT)}
          />
          {hasSavingsDiff && <defs>{savingDiffPattern}</defs>}
          {selectPosition?.fromX && selectPosition?.toX ? (
            <ReferenceArea
              x1={selectPosition?.fromX}
              x2={selectPosition?.toX}
              stroke={SCALEOPS_COLORS.main.blue}
              fill={SCALEOPS_COLORS.main.blue}
              fillOpacity={0.3}
              strokeOpacity={0.3}
            />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ResourceGraph;
