import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getPolicies, GetPoliciesResponse } from "../../../../api/fetcher";
import { components } from "../../../../api/schema";

const { queryFn, queryKey } = getPolicies();

interface Props {
  policyName: string | undefined;
}

const useIsPolicyHPAEnabled = ({ policyName }: Props): boolean => {
  const [policyData, setPolicyData] = useState<components["schemas"]["V1alpha1Policy"] | undefined>(undefined);

  const { data } = useQuery<GetPoliciesResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
    enabled: !!policyName,
  });

  useEffect(() => {
    if (data) {
      const policy = data.policies?.find((policy) => policy.metadata?.name === policyName);
      setPolicyData(policy);
    }
  }, [data, policyName]);

  if (!policyName || !policyData?.spec?.policyOptimize?.scaleOutPolicy?.enabled) return false;

  return true;
};

export default useIsPolicyHPAEnabled;
