import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import {
  getPercentageOfOptimizedReplicas,
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE,
} from "./GridColumnsUtils";
import * as formatterUtils from "../../../utils/formatterUtils";
import { Typography } from "@mui/material";
import Tooltip from "../../Tooltip";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import RightArrowIcon from "../../../Icons/RightArrowIcon";

const cpuFormatter = formatterUtils.CpuFormatter();

export default function OriginalCpuRequestColumn({
  params,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
}) {
  if (isUnallocatedRow(params.row)) return <div></div>;

  const percentageOfOptimizedReplicas = getPercentageOfOptimizedReplicas(params);
  let cpuRequestsDisplayValue = cpuFormatter.format(params.row.cpuRequests / 1000);
  cpuRequestsDisplayValue = Number.isNaN(cpuRequestsDisplayValue) ? "0" : cpuRequestsDisplayValue;
  let cpuRecommendedDisplayValue = cpuFormatter.format(params.row.cpuRecommended / 1000);
  cpuRecommendedDisplayValue = Number.isNaN(cpuRecommendedDisplayValue) ? "0" : cpuRecommendedDisplayValue;

  const currentDisplayValue = params.row.ownerCpuRequests ? cpuFormatter.format(params.row.ownerCpuRequests / 1000) : 0;
  const recommendedDisplayValue = cpuFormatter.format(params.row.cpuRecommended / 1000);

  return (
    <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
      <Tooltip
        title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
        maxWidth={400}
        className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
        enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
      >
        <div className="flex justify-end items-center gap-[.625rem]">
          <span>{currentDisplayValue}</span>
          {recommendedDisplayValue !== undefined && (
            <>
              <RightArrowIcon width={10} height={10} />
              <span className="text-guideline-darkGreen">
                {percentageOfOptimizedReplicas > 50 && params.row.auto
                  ? cpuRecommendedDisplayValue
                  : cpuRequestsDisplayValue}
              </span>
            </>
          )}
        </div>
      </Tooltip>
    </Typography>
  );
}
