import { Typography } from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AxisDomain } from "recharts/types/util/types";
import { ObjectParam, StringParam, useQueryParam } from "use-query-params";
import RecommendationIcon from "../../../Icons/RecommendationIcon";
import { GetNodeGroupsNodesResponse } from "../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../colors";
import useFreezeTooltip, { TooltipTrigger } from "../../../pages/Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../../pages/Analytics/AnalyticsV2/Graphs/hooks/utils";
import { EventPoint } from "../../../pages/Overview/PolicyTuning/Diagnostics/utils";
import NumberOfFilterElements from "../../../pages/Overview/PolicyTuning/NumberOfFilterElements";
import {
  podTooltipSortFnc,
  recommendedLegendSortFunction,
} from "../../../pages/Overview/PolicyTuning/WorkloadAnalytics/utils";
import { memoryDataParser } from "../../../utils/graphUtils";
import {
  NODE_OVERVIEW_DATES_URL_PARAM,
  NODE_OVERVIEW_PERIOD_URL_PARAM,
  NodeStatsResponseTypeSchema,
  useNodesViewPeriodQueryParams,
} from "../Utils";
import LineChart from "./LineChart";
import MultipleRegexpLineChart from "./MultipleRegexpLineChart";
import ResourcesOverTimeCharts from "./ResourcesOverTimeCharts";
import SelectViewPeriod from "./SelectViewPeriod";

const PERCENTAGE_Y_AXIS_DOMAIN = [0, 100];
const NUMERIC_CPU_Y_AXIS_DOMAIN: AxisDomain = [0, (dataMax: number) => (dataMax < 0 ? 0 : dataMax * 1.2)];
const NUMERIC_MEMORY_Y_AXIS_DOMAIN: AxisDomain = [0, (dataMax: number) => (dataMax < 0 ? 0 : dataMax * 1.2)];
const ROUNDED_TICK_FORMATTER = (value: number) => String(Math.round(value * 100) / 100);

const wrapperClassName = "border rounded-lg border-border";
const halfWrapperClassName = "w-[calc(50%-5px)]";

interface Props {
  selectedNode: NodeStatsResponseTypeSchema | undefined;
  nodesData: GetNodeGroupsNodesResponse | undefined;
  isLoading: boolean;
  error: Error | null;
  isFetching: boolean;
  setEmptyEventArray: React.Dispatch<React.SetStateAction<EventPoint[] | undefined>>;
}

const NodesAnalytics = ({ selectedNode, nodesData, isLoading, error, isFetching, setEmptyEventArray }: Props) => {
  const { tooltipTrigger, disabledZoom, updateActiveTooltips, setTooltipTrigger } = useFreezeTooltip({});

  const [urlDates] = useQueryParam(NODE_OVERVIEW_DATES_URL_PARAM, ObjectParam);
  const [selectedContainer] = useQueryParam(NODE_OVERVIEW_PERIOD_URL_PARAM, StringParam);
  const [selectedViewPeriod, setSelectedViewPeriod] = useNodesViewPeriodQueryParams();

  const [numberOfElements, setNumberOfElements] = useState<number>(10);
  const [from, setFrom] = useState<number>(dayjs().subtract(1, "day").unix());
  const [to, setTo] = useState<number>(dayjs().unix());

  const nodeName = selectedNode?.name;

  useEffect(() => {
    const fromValue = urlDates?.from
      ? Number(urlDates?.from)
      : dayjs.utc().subtract(Number(selectedViewPeriod), "hour").unix();
    const toValue = urlDates?.to ? Number(urlDates?.to) : dayjs.utc().unix();

    setFrom(fromValue);
    setTo(toValue);
  }, [urlDates, selectedViewPeriod]);

  useEffect(() => {
    setTooltipTrigger(TooltipTrigger.Hover);
  }, [selectedViewPeriod, selectedContainer]);

  const formatLegend = (key: string) => {
    const keyValues = key.split("/");
    const nameSpace = keyValues?.[0] ?? "";
    const podName = keyValues?.[3] ?? "";
    return `${nameSpace}/${podName}`;
  };

  const elementsFormat = (key: string, regexp: string, color: string) => ({
    key: key,
    dataKey: key,
    label: formatLegend(key),
    color: color,
    tooltipValueColor: color,
    fill: "none",
  });

  return (
    <div>
      <div className={clsx(wrapperClassName, "w-full mb-2.5 p-4 flex items-center gap-10")}>
        <div className="w-[30px]">
          <RecommendationIcon width={30} height={30} />
        </div>
        <Typography variant="body2" className="grow">
          <b>Node Troubleshoot</b>
          <p className="max-w-[100%]">
            Explore the node resource usage including: CPU, memory, node pressure events, under provisioned nodes, OOM,
            scale down blockers, pods usage and allocations.
          </p>
        </Typography>
        <div className="flex justify-center items-center gap-5">
          <SelectViewPeriod selectedViewPeriod={selectedViewPeriod} setSelectedViewPeriod={setSelectedViewPeriod} />
          <NumberOfFilterElements numberOfElements={numberOfElements} setNumberOfElements={setNumberOfElements} />
        </div>
      </div>
      <div className={wrapperClassName}>
        <ResourcesOverTimeCharts
          from={from}
          to={to}
          setEmptyEventArray={setEmptyEventArray}
          selectedViewPeriod={selectedViewPeriod}
          data={nodesData}
          isLoading={isLoading}
          error={error}
          isFetching={isFetching}
        />
      </div>

      <div className="flex flex-wrap gap-[10px] my-[10px]">
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="CPU utilization (%)"
            regexp={"utilizationCpu_"}
            tooltipValueSuffix="%"
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["utilizationCpu"],
              topk: numberOfElements,
            }}
            YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
            maxValueLimit={100}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Memory utilization (%)"
            regexp={"utilizationMemory_"}
            tooltipValueSuffix="%"
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["utilizationMemory"],
              topk: numberOfElements,
            }}
            YAxisDomain={PERCENTAGE_Y_AXIS_DOMAIN}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
          />
        </div>
      </div>

      <div className="flex flex-wrap gap-[10px] my-[10px]">
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="CPU noisy neighbors - under-provisioned workloads"
            infoTooltip={
              <>
                <b>Sum</b> of <b>usage above request</b> for nodes that have been on the same node as this workload, at
                a time when the node was <b>CPU stressed</b>.
              </>
            }
            regexp={"cpu_noisy_neighbors_"}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["underProvisionedWorkloadsCpu"],
              topk: numberOfElements,
            }}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            YAxisDomain={NUMERIC_CPU_Y_AXIS_DOMAIN}
            YAxisAllowDecimals={false}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
            updateActiveTooltips={updateActiveTooltips}
          />
        </div>
        {/* Memory noisy neighbors and Out of memory*/}
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Memory noisy neighbors - under-provisioned workloads"
            infoTooltip={
              <>
                <b>Sum</b> of <b>usage above request</b> for nodes that have been on the same node as this node, at a
                time when the node was <b>Memory stressed</b>.
              </>
            }
            regexp={"memory_noisy_neighbors_"}
            tooltipValueSuffix=""
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["underProvisionedWorkloadsMemory"],
              topk: numberOfElements,
            }}
            tickFormatter={memoryDataParser}
            YAxisDomain={NUMERIC_MEMORY_Y_AXIS_DOMAIN}
            YAxisAllowDecimals={false}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
            updateActiveTooltips={updateActiveTooltips}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Out of memory"
            regexp={"oom_"}
            tooltipValueSuffix=""
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["outOfMemory"],
            }}
            colors={[
              SCALEOPS_COLORS.events.oomKubelet,
              SCALEOPS_COLORS.events.oomLimit,
              SCALEOPS_COLORS.events.oomNode,
            ]}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            YAxisDomainWithHeadroomAndWholeNumbers
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            dotted={true}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Node pressure"
            regexp={"node_pressure_"}
            tooltipValueSuffix=""
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["nodePressure"],
            }}
            colors={[
              SCALEOPS_COLORS.events.oomKubelet,
              SCALEOPS_COLORS.events.oomLimit,
              SCALEOPS_COLORS.events.oomNode,
            ]}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            YAxisDomainWithHeadroomAndWholeNumbers
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            dotted={true}
          />
        </div>

        {/* Node pressure and Unevictable pods*/}
        <div className={halfWrapperClassName}>
          <LineChart
            title="Auto-healing due to CPU stress"
            color={SCALEOPS_COLORS.events.eviction}
            label="autoHealingCpuStress"
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["autoHealingCpuStress"],
            }}
            YAxisAllowDecimals={false}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            YAxisDomain={[0, 2]}
            dotted={true}
          />
        </div>
        <div className={halfWrapperClassName}>
          <LineChart
            title="Unevictable pods"
            color={SCALEOPS_COLORS.events.eviction}
            label="unevictablePods"
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["unevictablePods"],
            }}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            dotted={true}
          />
        </div>
        {/* Pod usage */}
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Pod CPU usage"
            regexp={""}
            whitelist={["cpu_request_recommended"]}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["podsCpuUsage"],
              topk: numberOfElements,
            }}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            getElementsFormat={elementsFormat}
            legendSortFunction={recommendedLegendSortFunction}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
            updateActiveTooltips={updateActiveTooltips}
            tooltipSortFnc={podTooltipSortFnc}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Pod memory usage"
            regexp={""}
            whitelist={["memory_request_recommended"]}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["podsMemoryUsage"],
              topk: numberOfElements,
            }}
            tickFormatter={memoryDataParser}
            YAxisDomain={NUMERIC_MEMORY_Y_AXIS_DOMAIN}
            YAxisAllowDecimals={false}
            getElementsFormat={elementsFormat}
            legendSortFunction={recommendedLegendSortFunction}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
            updateActiveTooltips={updateActiveTooltips}
            tooltipSortFnc={podTooltipSortFnc}
          />
        </div>
        {/* Pod request */}
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Pod CPU request"
            regexp={""}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["podsCpuRequest"],
              topk: numberOfElements,
            }}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            getElementsFormat={elementsFormat}
            legendSortFunction={recommendedLegendSortFunction}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
            updateActiveTooltips={updateActiveTooltips}
            tooltipSortFnc={podTooltipSortFnc}
          />
        </div>
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Pod memory request"
            regexp={""}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["podsMemoryRequest"],
              topk: numberOfElements,
            }}
            tickFormatter={memoryDataParser}
            YAxisDomain={NUMERIC_MEMORY_Y_AXIS_DOMAIN}
            YAxisAllowDecimals={false}
            getElementsFormat={elementsFormat}
            legendSortFunction={recommendedLegendSortFunction}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndClickable}
            updateActiveTooltips={updateActiveTooltips}
            tooltipSortFnc={podTooltipSortFnc}
          />
        </div>
        {/* Block reason */}
        <div className={halfWrapperClassName}>
          <MultipleRegexpLineChart
            title="Scale down block reason"
            regexp={"scale_down_reason_"}
            roundValues
            queryParams={{
              from: from,
              to: to,
              name: nodeName,
              types: ["scaleDownBlockReason"],
              topk: numberOfElements,
            }}
            tickFormatter={ROUNDED_TICK_FORMATTER}
            legendSortFunction={recommendedLegendSortFunction}
            tooltipTrigger={tooltipTrigger}
            disabledZoom={disabledZoom}
            frozenTooltipType={FrozenTooltipType.FrozenAndNotClickable}
            updateActiveTooltips={updateActiveTooltips}
            tooltipSortFnc={podTooltipSortFnc}
            maxValueLimit={2}
            YAxisDomain={[0, 2]}
          />
        </div>
      </div>
    </div>
  );
};

export default NodesAnalytics;
