import { components } from "../../api/schema";
import ExportCSV, { HAS_EXPORT_TABLE_AS_CSV } from "../exportCSV/ExportCSV";
import { OverviewTableColumns } from "./BulkPolicyEditorToolbar";

interface Props {
  selectedColumns: (string | undefined)[];
  hideExport?: boolean;
  csvData: components["schemas"]["UtilsWorkload"][];
}
const OverviewExportCSVButton = ({ selectedColumns, csvData, hideExport }: Props) => {
  if (!HAS_EXPORT_TABLE_AS_CSV || !!hideExport) return null;

  return (
    <div className="h-[34.5px] mt-[-44px] flex items-center relative z-50 w-fit">
      <ExportCSV<components["schemas"]["UtilsWorkload"]>
        filename="workloads.csv"
        columns={
          [
            "workloadName",
            "namespace",
            "auto",
            "totalCost",
            "activeSavings",
            "savingsAvailable",
            "cpuRequests",
            "ownerCpuRequests",
            "cpuRecommended",
            "cpuDiff",
            "memRequests",
            "ownerMemoryRequests",
            "memRecommended",
            "memoryDiff",
            "policyName",
            "replicas",
            "readyReplicas",
            "optimizedReplicas",
          ].filter((column) => {
            switch (true) {
              case column === "totalCost":
                return selectedColumns.includes(OverviewTableColumns.TotalCost);
              case column === "activeSavings":
                return selectedColumns.includes(OverviewTableColumns.ActiveSavings);
              case column === "savingsAvailable":
                return selectedColumns.includes(OverviewTableColumns.SavingsAvailable);
              case column === "policyName":
                return selectedColumns.includes(OverviewTableColumns.Policy);
              case column === "replicas":
                return selectedColumns.includes(OverviewTableColumns.Replicas);
              case column === "readyReplicas":
                return selectedColumns.includes(OverviewTableColumns.ReadyReplicas);
              case column === "optimizedReplicas":
                return selectedColumns.includes(OverviewTableColumns.Replicas);
              case String(column).toLocaleLowerCase().includes("cpu"):
                return (
                  selectedColumns.includes(OverviewTableColumns.CpuRequests) ||
                  selectedColumns.includes(OverviewTableColumns.OriginalCpuRequests)
                );
              case String(column).toLocaleLowerCase().includes("mem"):
                return (
                  selectedColumns.includes(OverviewTableColumns.MemoryRequests) ||
                  selectedColumns.includes(OverviewTableColumns.OriginalMemoryRequests)
                );
              default:
                return true;
            }
          }) as (keyof components["schemas"]["UtilsWorkload"])[]
        }
        columnsToRound={["totalCost", "activeSavings", "savingsAvailable"]}
        defaultValues={{ optimizedReplicas: 0 }}
        data={csvData}
        columnsToSum={["totalCost", "activeSavings", "savingsAvailable"]}
        customColumnNames={{
          workloadName: "workload name",
          id: "id",
          namespace: "namespace",
          auto: "automated",
          totalCost: "total cost",
          activeSavings: "active savings",
          savingsAvailable: "savings available",
          cpuRequests: "cpu requests",
          ownerCpuRequests: "owner cpu requests",
          cpuRecommended: "cpu recommended",
          cpuDiff: "cpu diff",
          memRequests: "memory requests",
          ownerMemoryRequests: "owner memory requests",
          memRecommended: "memory recommended",
          memoryDiff: "memory diff",
          policyName: "policy",
          replicas: "replicas",
          readyReplicas: "ready replicas",
          optimizedReplicas: "optimized replicas",
        }}
      />
    </div>
  );
};

export default OverviewExportCSVButton;
