import { MenuItem, Typography } from "@mui/material";
import SingleSelect, { SingleSelectSize } from "../../../components/SingleSelect";
import Tooltip from "../../../components/Tooltip";
import InfoIcon from "../../../Icons/InfoIcon";

const TOOLTIP_CONTENT = (
  <Typography variant="caption">
    <span>
      The <b>number of elements</b> filter is applied to the following charts:
    </span>
    <ul className="list-disc pl-5">
      <li>Node CPU utilization(%)</li>
      <li>Node memory utilization(%)</li>
      <li>CPU noisy neighbors - under-provisioned workloads</li>
      <li>Memory noisy neighbors - under-provisioned workloads</li>
      <li>Pod CPU request</li>
      <li>Pod memory request</li>
      <li>Pod CPU usage</li>
      <li>Pod memory usage</li>
    </ul>
  </Typography>
);

const OPTIONS = {
  "5 lines per graph": 5,
  "10 lines per graph": 10,
  "20 lines per graph": 20,
};

interface Props {
  numberOfElements: number;
  setNumberOfElements: React.Dispatch<React.SetStateAction<number>>;
}

const NumberOfFilterElements = ({ numberOfElements, setNumberOfElements }: Props) => {
  return (
    <div>
      <SingleSelect<number>
        label={
          <Tooltip className="flex gap-1 items-center" title={TOOLTIP_CONTENT} maxWidth={600}>
            <span>Number of element</span> <InfoIcon width={14} height={14} />
          </Tooltip>
        }
        size={SingleSelectSize.Regular}
        className="w-[180px]"
        selected={numberOfElements}
        setSelected={(option) => {
          setNumberOfElements(option);
        }}
        renderOptionsFunc={() =>
          Object.entries(OPTIONS).map((option) => {
            return (
              <MenuItem value={option[1]} key={option[1]}>
                <p className="text-[14px]">{option[0]}</p>
              </MenuItem>
            );
          })
        }
      />
    </div>
  );
};

export default NumberOfFilterElements;
