import clsx from "clsx";
import RunningNumberBox from "../../../pages/Overview/TopOverviewSection/RunningNumberBox";
import WasteVsOptimizedBarCharts from "../../../pages/Overview/TopOverviewSection/WasteVsOptimizedBarCharts";
import PartialBorders from "../../PartialBorders";
import TotalAutomatedPieChart from "./TotalAutomatedPieChart";

const BOX_CLASS_NAME = "grow";

const TOOLTIP_CTA = (
  <span>
    <b>Explore your workload & automate you pods</b> to reduce waste.
  </span>
);

const wasteTooltipContentFnc = (waste: number) => (
  <>
    <b className="text-main-red">{Math.round(waste)}%</b> of your total unevictable spend{" "}
    <b className="text-main-red">is being wasted.</b>
    <br />
    <br />
    {TOOLTIP_CTA}
  </>
);

interface Props {
  isLoading?: boolean;
  wastedSpend?: number;
  blockedNodes?: number;
  totalOptimizedPods?: number;
  totalUnOptimizedPods?: number;
}

const TopSection = ({
  isLoading,
  wastedSpend = NaN,
  blockedNodes = NaN,
  totalOptimizedPods = NaN,
  totalUnOptimizedPods = NaN,
}: Props) => (
  <div className="flex justify-center items-center w-full h-[194px]">
    <PartialBorders right wrapperClassName={BOX_CLASS_NAME}>
      <div className="flex flex-col justify-center items-center gap-3">
        <RunningNumberBox
          title="Wasted spend"
          value={Math.min(wastedSpend,78)}
          suffix="%"
          numberVariant="h2"
          numberClassName={clsx({ "text-main-red": wastedSpend > 0, "text-text-lightBlack": wastedSpend <= 0 })}
          isLoading={isLoading}
        />
        <WasteVsOptimizedBarCharts
          waste={wastedSpend}
          optimized={100 - wastedSpend}
          disableTooltip={!wastedSpend}
          isLoading={isLoading}
          tooltipContentFnc={wasteTooltipContentFnc}
        />
      </div>
    </PartialBorders>
    <PartialBorders right wrapperClassName={BOX_CLASS_NAME}>
      <div className="flex flex-col justify-center items-center gap-3">
        <RunningNumberBox
          title="Blocked nodes"
          value={blockedNodes}
          numberVariant="h2"
          numberClassName={clsx({ "text-main-red": wastedSpend > 0, "text-text-lightBlack": wastedSpend <= 0 })}
          isLoading={isLoading}
        />
      </div>
    </PartialBorders>
    <PartialBorders wrapperClassName={BOX_CLASS_NAME}>
      <TotalAutomatedPieChart
        automatedLabel="optimized"
        unAutomatedLabel="un-optimized"
        title="Optimized"
        subtitle="unevictable pods"
        totalAutomated={totalOptimizedPods}
        totalUnAutomated={totalUnOptimizedPods}
        showAutomatedAndUnAutomatedLabels
      />
    </PartialBorders>
  </div>
);

export default TopSection;
