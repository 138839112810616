/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, createContext, useState, useMemo } from "react";
import { components } from "./api/schema";

interface DashOverUnderProvisioningFilters {
  overProvisioned?: boolean;
  underProvisioned?: boolean;
  priorityClassName?: string;
  namespace?: string;
  labels?: string;
}

type PropsType = {
  id?: string;
  auto?: boolean;
  isAutomationExcluded?: boolean;
  policyName?: string;
  smartPolicyName?: string;
  automationTime?: string;
};

export type DashWorkloadWithIssues = components["schemas"]["UtilsWorkload"] & {
  issues?: components["schemas"]["DashIssues"];
} & DashOverUnderProvisioningFilters;

interface UpdateOverriddenWorkloadProps {
  ids: string[];
  props: PropsType;
}

interface UpdateOverriddenWorkloadByUpdateFunctionProps {
  ids: string[];
  updateFunction: (workload: PropsType | DashWorkloadWithIssues) => PropsType | DashWorkloadWithIssues;
}
export interface DeleteOverriddenWorkloadsProperties {
  ids: string[];
  propertyNames: string[];
}

type ContextType = {
  overriddenWorkloads: (DashWorkloadWithIssues | PropsType)[];
  overriddenWorkloadsIds: string[];
  setOverriddenWorkloads: (overriddenWorkloads: (DashWorkloadWithIssues | PropsType)[]) => void;
  selectedNamespaceIds: string[];
  setSelectedNamespaceIds: (selectedNamespaceIds: string[]) => void;
  excludeFromNamespaceAutomationIds: string[];
  setExcludeFromNamespaceAutomationIds: (excludeFromNamespaceAutomationIds: string[]) => void;
  updateOverriddenWorkloads: (props: UpdateOverriddenWorkloadProps) => void;
  updateOverriddenWorkloadsByUpdateFunction: (props: UpdateOverriddenWorkloadByUpdateFunctionProps) => void;
  deleteOverriddenWorkloadsProperties: (props: DeleteOverriddenWorkloadsProperties) => void;
  resetOverriddenWorkloads: () => void;
};

const WorkloadsContext = createContext<ContextType>({
  overriddenWorkloads: [],
  overriddenWorkloadsIds: [],
  setOverriddenWorkloads: () => {},
  selectedNamespaceIds: [],
  setSelectedNamespaceIds: () => {},
  excludeFromNamespaceAutomationIds: [],
  setExcludeFromNamespaceAutomationIds: () => {},
  updateOverriddenWorkloads: () => {},
  updateOverriddenWorkloadsByUpdateFunction: () => {},
  deleteOverriddenWorkloadsProperties: () => {},
  resetOverriddenWorkloads: () => {},
});

interface Props {
  children: React.ReactNode;
}

const WorkloadsContextProvider = ({ children }: Props) => {
  const [overriddenWorkloads, setOverriddenWorkloads] = useState<(DashWorkloadWithIssues | PropsType)[]>([]);
  const [selectedNamespaceIds, setSelectedNamespaceIds] = useState<string[]>([]);
  const [excludeFromNamespaceAutomationIds, setExcludeFromNamespaceAutomationIds] = useState<string[]>([]);

  const updateOverriddenWorkloads = ({ ids, props }: UpdateOverriddenWorkloadProps) => {
    const updatedWorkloads = overriddenWorkloads.map((workload) => {
      if (workload?.id && ids.includes(workload.id)) {
        return { ...workload, ...props };
      }
      return workload;
    });

    const newWorkloads = ids.filter((id) => !overriddenWorkloads.map((workload) => workload.id).includes(id));

    setOverriddenWorkloads([...updatedWorkloads, ...newWorkloads.map((id) => ({ ...props, id }))]);
  };

  const updateOverriddenWorkloadsByUpdateFunction = ({
    ids,
    updateFunction,
  }: UpdateOverriddenWorkloadByUpdateFunctionProps) => {
    const updatedWorkloads = overriddenWorkloads.map((workload) => {
      if (workload?.id && ids.includes(workload.id)) {
        return updateFunction(workload);
      }
      return workload;
    });

    const overriddenWorkloadsIds = overriddenWorkloads.map((workload) => workload.id);

    const newWorkloads = ids.filter((id) => !overriddenWorkloadsIds.includes(id));

    setOverriddenWorkloads([...updatedWorkloads, ...newWorkloads.map((id) => updateFunction({ id } as PropsType))]);
  };

  const deleteOverriddenWorkloadsProperties = ({ ids, propertyNames }: DeleteOverriddenWorkloadsProperties) => {
    const updatedWorkloads = overriddenWorkloads.map((workload) => {
      if (workload?.id && ids.includes(workload.id)) {
        propertyNames.forEach((propertyName: string) => {
          delete workload[propertyName as keyof PropsType];
        });
        return workload;
      }
      return workload;
    });

    setOverriddenWorkloads(updatedWorkloads);
  };

  const resetOverriddenWorkloads = () => {
    setOverriddenWorkloads([]);
  };

  const overriddenWorkloadsIds = overriddenWorkloads.map((workload) => workload.id).filter((id) => id) as string[];

  const providerValues = useMemo(
    () => ({
      overriddenWorkloads,
      overriddenWorkloadsIds,
      setOverriddenWorkloads,
      selectedNamespaceIds,
      setSelectedNamespaceIds,
      excludeFromNamespaceAutomationIds,
      setExcludeFromNamespaceAutomationIds,
      updateOverriddenWorkloads,
      updateOverriddenWorkloadsByUpdateFunction,
      deleteOverriddenWorkloadsProperties,
      resetOverriddenWorkloads,
    }),
    [
      overriddenWorkloads,
      setOverriddenWorkloads,
      overriddenWorkloadsIds,
      selectedNamespaceIds,
      setSelectedNamespaceIds,
      excludeFromNamespaceAutomationIds,
      setExcludeFromNamespaceAutomationIds,
      updateOverriddenWorkloads,
      updateOverriddenWorkloadsByUpdateFunction,
      deleteOverriddenWorkloadsProperties,
      resetOverriddenWorkloads,
    ]
  );

  return <WorkloadsContext.Provider value={providerValues}>{children}</WorkloadsContext.Provider>;
};

const useWorkloadsContext = () => useContext(WorkloadsContext);

export { WorkloadsContextProvider, useWorkloadsContext };
