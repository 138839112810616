import { ErrorBoundary } from "react-error-boundary";
import { components } from "../../../api/schema";
import DefaultFallback from "../../../components/DefaultFallback";
import AutomationSwitch from "./AutomationSwitch";
import SaveButtonContainer from "./SaveButtonContainer";
import { Policy } from "./utils";

interface SaveAndAutomateButtons {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  selectedPolicy: Policy | undefined;
  fetchWorkloads: () => void;
  isAutomate: boolean;
  setIsAutomate: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaveAndAutomateButtons = ({
  selectedWorkload,
  selectedPolicy,
  fetchWorkloads,
  isAutomate,
  setIsAutomate,
}: SaveAndAutomateButtons) => {
  return (
    <div className="flex w-full justify-end gap-4">
      <ErrorBoundary
        fallback={<DefaultFallback message="Failed to Policy Tunning Action Buttons. Please check your setup" />}
      >
        <SaveButtonContainer selectedWorkload={selectedWorkload} selectedPolicy={selectedPolicy} />
        <AutomationSwitch
          selectedWorkload={selectedWorkload}
          isAutomate={isAutomate}
          setIsAutomate={setIsAutomate}
          fetchWorkloads={fetchWorkloads}
        />
      </ErrorBoundary>
    </div>
  );
};

export default SaveAndAutomateButtons;
