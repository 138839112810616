import { Typography } from "@mui/material";
import { isUnallocatedRow } from "./GridColumnsUtils";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";

export default function ReadyReplicasColumn({
  params,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
}) {
  if (isUnallocatedRow(params.row)) return <div></div>;
  return (
    <div className="flex flex-col items-start gap-[.425rem]">
      <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
        {params.row.readyReplicas || 0} / {params.row.replicas}
      </Typography>
    </div>
  );
}
