import { CircularProgress, Typography, TypographyVariant } from "@mui/material";
import clsx from "clsx";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { SCALEOPS_COLORS } from "../../../colors";
import Tooltip from "../../../components/Tooltip";

const BAR_CHART_WIDTH = 180;
const BAR_CHART_HEIGHT = 15;
interface Props {
  value: number;
  title: React.ReactNode;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  thousandSeparator?: boolean;
  numberClassName?: string;
  headerVariant?: TypographyVariant;
  numberVariant?: TypographyVariant;
  titleFontWeight?: "regular" | "medium" | "bold";
  className?: string;
  leftChild?: React.ReactNode;
  valueDataTestId?: string;
  barPercentageValue?: number;
  barPercentageColor?: string;
  showRoundedValue?: boolean;
  disableAnimation?: boolean;
  isLoading?: boolean;
  showDashForNoData?: boolean;
}

const RunningNumberBox = ({
  value,
  title,
  prefix,
  suffix,
  numberClassName = "",
  thousandSeparator = true,
  numberVariant = "h6",
  headerVariant,
  titleFontWeight = "regular",
  className,
  leftChild,
  valueDataTestId,
  barPercentageValue,
  barPercentageColor,
  showRoundedValue = true,
  disableAnimation,
  isLoading,
  showDashForNoData = true,
}: Props) => {
  const [numberToDisplay, setNumberToDisplay] = useState(NaN);
  const barSpring = useSpring({
    from: { width: 0 },
    to: { width: BAR_CHART_WIDTH * (barPercentageValue ?? 0) },
  });

  useEffect(() => {
    setNumberToDisplay(value ?? NaN);
  }, [value]);

  const spring = useSpring({
    from: { number: 0 },
    number: numberToDisplay,
  });
  numberClassName = Number.isNaN(numberToDisplay) ? "" : numberClassName;
  return (
    <div className={clsx(className, "flex flex-col items-center justify-center")}>
      <Typography variant={headerVariant} fontWeight={titleFontWeight} className="text-center">
        {title}
      </Typography>
      <Typography variant={numberVariant} className="relative">
        {isLoading && (
          <div className="absolute w-full flex items-center justify-center pt-1">
            <div>
              <CircularProgress size="1em" />
            </div>
          </div>
        )}
        <div
          className={clsx(numberClassName, "flex", {
            "opacity-0": isLoading,
          })}
        >
          {showDashForNoData && Number.isNaN(numberToDisplay) ? (
            "-"
          ) : (
            <>
              {prefix}
              {!disableAnimation && (
                <animated.div data-testid={valueDataTestId}>
                  {spring.number
                    .to((n) => (showRoundedValue ? n.toFixed(0) : n.toFixed(2)))
                    .to((n) => {
                      if (thousandSeparator) {
                        return n.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }
                      return n;
                    })}
                </animated.div>
              )}
              {disableAnimation && (
                <span>
                  {showRoundedValue
                    ? numeral(!Number.isNaN(numberToDisplay) ? numberToDisplay : 0).format("0,0")
                    : numeral(!Number.isNaN(numberToDisplay) ? numberToDisplay : 0).format("0,0.00")}
                </span>
              )}
              {suffix}
              {leftChild}
            </>
          )}
        </div>
      </Typography>
      {barPercentageValue && numberToDisplay ? (
        <Tooltip
          title={
            <>
              {title}: {`${(barPercentageValue * 100).toFixed(2)}%`}
            </>
          }
          placement="bottom"
        >
          <div className={`flex justify-start bg-border h-[${BAR_CHART_HEIGHT}px] w-[${BAR_CHART_WIDTH}px]`}>
            <animated.div
              style={{
                backgroundColor: barPercentageColor ?? SCALEOPS_COLORS.main.green,
                ...barSpring,
              }}
            />
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default RunningNumberBox;
