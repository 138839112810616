import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { useQuery } from "@tanstack/react-query";
import { StringParam, useQueryParam } from "use-query-params";
import { Alert, AlertProps, Checkbox, ListItemText, MenuItem, Snackbar, TextField } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Info } from "@mui/icons-material";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp, GridToolbarExport } from "@mui/x-data-grid";
import { components } from "../../api/schema";
import { ScaleOps, ScaleOpsClient } from "../../api/api";
import MultiSelect from "../MultiSelect";
import MultiSelectNamespacesComponent from "../../pages/Overview/PolicyTuning/MultiSelectNamespace";
import MultiSelectLabelsComponent from "../../pages/Overview/PolicyTuning/MultiSelectLabels";
import prettyBytes from "pretty-bytes";
import Typography from "@mui/material/Typography";
import * as formatterUtils from "../../utils/formatterUtils";
import { getDataGridSx, HEADER_HEIGHT } from "../../utils/styleUtils";
import CustomFilterButton from "../CustomFilterButton";
import CustomIcon from "../../Icons/CustomIcon";
import EyeIcon from "../../Icons/EyeIcon";
import Tooltip from "../Tooltip";
import Input from "../Input";
import { useMainContext } from "../../MainContext";
import { getNamespaces, GetNamespacesResponse } from "../../api/fetcher";
import AddAggregator from "./AddAggregator";

enum ReportColumns {
  // Name = "Name",
  TotalConst = "Total Cost",
  SavingsOvertime = "Savings Overtime",
  TotalWaste = "Total Waste",
  CpuRequest = "CPU Request",
  CpuOriginalRequest = "CPU Original Request",
  CpuUtilization = "CPU Utilization",
  MemoryRequest = "Memory Request",
  MemoryOriginalRequest = "Memory Original Request",
  MemoryUtilization = "Memory Utilization",
  GPURequest = "GPU Request",
}

const CustomToolbar = () => {
  return (
    <div className="absolute z-[50] mt-[-50px] border border-strongBorder rounded px-2">
      <GridToolbarExport
        sx={{
          "& .MuiButtonBase-root": {
            backgroundColor: "#fff",
            color: "#000",
            border: "1px solid #000",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#000",
              border: "1px solid #000",
            },
          },
        }}
      />
    </div>
  );
};

const scaleopsLabelAggregator = "scaleops_label_aggregator";

const HistoryReport = () => {
  const { currentCluster } = useMainContext();
  const namespaces = getNamespaces();
  const { data } = useQuery<GetNamespacesResponse, Error>([namespaces.queryKey], () => namespaces.queryFn({}));

  const namespacesOptions = Object.values(data?.namespaces || []).map((namespace) => namespace?.metadata?.name || "");

  const [searchTerm, setSearchTerm] = useQueryParam("setSearchTerm", StringParam);
  const [costs, setCosts] = useState<GridRowsProp<components["schemas"]["ReportsAggregatorObjectCostNew"]>>([]);
  const initialColumns = [
    // ReportColumns.Name,
    ReportColumns.SavingsOvertime,
    ReportColumns.TotalConst,
    ReportColumns.TotalWaste,
    ReportColumns.CpuRequest,
    // ReportColumns.CpuOriginalRequest,
    ReportColumns.CpuUtilization,
    ReportColumns.MemoryRequest,
    // ReportColumns.MemoryOriginalRequest,
    ReportColumns.MemoryUtilization,
    // ReportColumns.GpuAllocatable,
  ];
  const availableColumns = Object.values(ReportColumns);

  const [selectedColumns, setSelectedColumns] = useState<(string | undefined)[]>(initialColumns);
  const [reportResponse, setReportResponse] = useState<components["schemas"]["ReportsCostAggregatorNewResponse"]>();

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
  });

  const defaultAggregator = ["namespace", "workload name", "workload type", "pod"];

  const initialAggregatorsString = localStorage.getItem(scaleopsLabelAggregator);
  if (initialAggregatorsString) {
    try {
      JSON.parse(initialAggregatorsString);
    } catch (e) {
      localStorage.removeItem(scaleopsLabelAggregator);
    }
  }

  const customAggregator = JSON.parse(localStorage.getItem(scaleopsLabelAggregator) || "[]") as string[];

  const [aggregators, setAggregators] = useState<string[]>([...defaultAggregator, ...customAggregator]);

  const [newLabelAggregator, setNewLabelAggregator] = useState<string | undefined>(undefined);
  const [selectedAggregators, setSelectedAggregators] = useState<string[]>(["namespace"]);

  const [selectedNamespaceFilters, setSelectedNamespaceFilters] = useState<string[]>([]);
  const [selectedLabelFilters, setSelectedLabelFilters] = useState<string[]>([]);

  const ONE_HOUR = 60 * 60 * 1000;
  const defaultFromHourssAgo = 24;
  const getTimeNow = () => {
    // return new Date(new Date().setUTCDate(new Date().getUTCDate()));
    return new Date();
  };
  const getTimeDefaultFrom = () => {
    // return new Date(new Date().setUTCDate(new Date().getUTCDate() - defaultFromDaysAgo));
    return new Date(new Date().getTime() - defaultFromHourssAgo * ONE_HOUR);
  };

  // local to make the text-field work appropriate
  const [fromTimeLocal, setFromTimeLocalLocal] = useState<Date | undefined>(getTimeDefaultFrom());
  const [toTimeLocal, setToTimeLocalLocal] = useState<Date | undefined>(getTimeNow());

  const [loadingTable, setLoadingTable] = useState<boolean>(true);

  const getFromTime = (iso = false) => {
    if (iso) {
      if (fromTimeLocal != undefined) {
        return fromTimeLocal.toISOString().slice(0, 16);
      }
      return "";
    }
    // to grafana - send utc
    return fromTimeLocal?.getTime() || "";
  };
  const getToTime = (iso = false) => {
    if (iso) {
      if (toTimeLocal != undefined) {
        return toTimeLocal.toISOString().slice(0, 16);
      }
      return "";
    }
    // to grafana - send utc
    return toTimeLocal?.getTime() || "";
  };

  const api: ScaleOpsClient = ScaleOps();

  const [snackbar, setSnackbar] = useState<Pick<AlertProps, "children" | "severity"> | null>(null);

  const handleCloseSnackbar = () => setSnackbar(null);
  const handleProcessRowUpdateError = useCallback((message: string) => {
    setSnackbar({ children: message, severity: "error" });
  }, []);

  const fetchReports = () => {
    setLoadingTable(true);
    const backendSelectedAggregators = handleValue(selectedAggregators);
    api
      .getFetcher()
      .path("/api/v1/reports/new")
      .method("post")
      .create()({
        from: getFromTime().toString(),
        to: getToTime().toString(),
        aggregators: backendSelectedAggregators,
        namespaceFilters: selectedNamespaceFilters,
        labelsFilters: selectedLabelFilters,
      })
      .then((response) => {
        if (response.ok) {
          if (response.data != undefined) {
            setReportResponse(response.data);

            if (response.data.aggregates == undefined) {
              setCosts([]);
            }
          }
        }
      })
      .catch((error) => {
        handleProcessRowUpdateError(`Failed to get reports`);
        setCosts([]);
        console.error(error);
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };

  useEffect(() => {
    let results = reportResponse?.aggregates || [];

    if (aggregators.includes("workload type")) {
      results = results.map((entity) => {
        return {
          ...entity,
          name: entity.name?.replace("/family", "/generic-type"),
        };
      });
    }

    if (searchTerm && searchTerm.length > 0) {
      results = results.filter((entity) => {
        return entity.name && entity.name.includes(searchTerm.toLowerCase());
      });
    }

    setCosts(results);
  }, [reportResponse, searchTerm]);

  useEffect(() => {
    fetchReports();
  }, [selectedAggregators, selectedNamespaceFilters, selectedLabelFilters, fromTimeLocal, toTimeLocal, currentCluster]);

  const cpuFormatter = formatterUtils.CpuReportFormatter();
  const utilizationFormatter = formatterUtils.CpuReportFormatterDigits0();
  const columns: GridColDef[] = [
    {
      field: "name",
      // hide: !selectedColumns.includes(ReportColumns.Name),
      headerName: "Name",
      flex: 1,
      type: "string",
      align: "left",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["ReportsAggregatorObjectCostNew"], string>
      ) => {
        const shouldAddAggregatorAndFilterByNamespace =
          params.row.name &&
          namespacesOptions.includes(params.row.name) &&
          !selectedAggregators.includes(params.row.name);

        const name = params.row.name?.replace(/^scaleops-rollout-/, "");

        return (
          <div
            className={clsx("w-full", {
              "cursor-pointer": shouldAddAggregatorAndFilterByNamespace,
            })}
            onClick={() => {
              if (shouldAddAggregatorAndFilterByNamespace) {
                setSelectedAggregators([...selectedAggregators, "workload name"]);
                params.row.name && setSelectedNamespaceFilters((s) => [...s, String(params.row.name)]);
              }
            }}
          >
            <Tooltip title={params.row.name}>
              <Typography variant="body2" sx={{ whiteSpace: "normal" }} className="line-clamp-3 w-full">
                {name}
              </Typography>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "totalSavings",
      hide: !selectedColumns.includes(ReportColumns.SavingsOvertime),
      headerName: "Savings Overtime",
      flex: 1,
      type: "number",
      align: "center",
      disableColumnMenu: true,
      maxWidth: 200,
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["ReportsAggregatorObjectCostNew"], string>
      ) => {
        if (reportResponse?.totalSavingsError) return null;

        const displayValue =
          params.row.totalSavings && params.row.totalSavings >= 0 ? Number(params.row.totalSavings) : 0;

        return <Typography variant="body2">{currencyFormatter.format(displayValue)}</Typography>;
      },
    },
    {
      field: "totalCost",
      hide: !selectedColumns.includes(ReportColumns.TotalConst),
      headerName: "Total Cost",
      flex: 1,
      type: "number",
      align: "center",
      disableColumnMenu: true,
      maxWidth: 200,
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["ReportsAggregatorObjectCostNew"], string>
      ) => {
        return (
          <Typography variant="body2">
            {reportResponse?.totalCostError === false
              ? params.row.totalCost == undefined || (params.row.totalCost < 0 && params.row.totalCost > -1)
                ? currencyFormatter.format(0)
                : currencyFormatter.format(params.row.totalCost)
              : ""}
          </Typography>
        );
      },
    },
    {
      field: "wasteCost",
      hide: !selectedColumns.includes(ReportColumns.TotalWaste),
      headerName: "Total Waste",
      flex: 1,
      type: "number",
      align: "center",
      disableColumnMenu: true,
      maxWidth: 180,
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["ReportsAggregatorObjectCostNew"], string>
      ) => {
        return (
          <Typography variant="body2">
            {reportResponse?.wasteCostError === false && params.row.name !== "_unallocated_"
              ? params.row.wasteCost == undefined || (params.row.wasteCost < 0 && params.row.wasteCost > -1)
                ? currencyFormatter.format(0)
                : currencyFormatter.format(params.row.wasteCost)
              : ""}
          </Typography>
        );
      },
    },
    {
      field: "cpuAllocatable",
      hide: !selectedColumns.includes(ReportColumns.CpuRequest),
      headerName: "Total CPU Request",
      renderHeader: () => {
        return (
          <Tooltip title="Average total CPU request">
            <div className="break-word whitespace-normal truncate leading-4 font-bold">
              Total CPU Request
              <Info style={{ fontSize: "12px", marginLeft: "0.25rem" }} />
            </div>
          </Tooltip>
        );
      },
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      maxWidth: 200,
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["ReportsAggregatorObjectCostNew"], string>
      ) => {
        return (
          <Typography variant="body2">
            {reportResponse?.cpuAllocatableError === false && params.row.name !== "_unallocated_"
              ? params.row.cpuAllocatable == undefined
                ? ""
                : cpuFormatter.format(params.row.cpuAllocatable || 0)
              : ""}
          </Typography>
        );
      },
    },
    {
      field: "cpuOriginalRequests",
      hide: !selectedColumns.includes(ReportColumns.CpuOriginalRequest),
      headerName: "CPU Original Request",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      maxWidth: 200,
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["ReportsAggregatorObjectCostNew"], string>
      ) => {
        return (
          <Typography>
            {reportResponse?.cpuAllocatableError === false && params.row.name !== "_unallocated_"
              ? params.row.cpuAllocatable == undefined
                ? ""
                : cpuFormatter.format(params.row.cpuAllocatable || 0)
              : ""}
          </Typography>
        );
      },
    },
    {
      field: "cpuUsage",
      hide: !selectedColumns.includes(ReportColumns.CpuUtilization),
      headerName: "CPU Utilization",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      maxWidth: 180,
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["ReportsAggregatorObjectCostNew"], string>
      ) => {
        return (
          <Typography variant="body2">
            {reportResponse?.cpuUsageError === false && params.row.name !== "_unallocated_"
              ? params.row.cpuUsage == undefined
                ? ""
                : params.row.cpuAllocatable !== 0
                ? utilizationFormatter.format(params.row.cpuUsage * 100 || 0) + "%"
                : "N/A"
              : ""}
          </Typography>
        );
      },
    },
    {
      field: "memorAllocatable",
      hide: !selectedColumns.includes(ReportColumns.MemoryRequest),
      headerName: "Total Memory Request",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      maxWidth: 200,
      renderHeader: () => {
        return (
          <Tooltip title="Average total memory request">
            <div className="break-word whitespace-normal truncate leading-4 font-bold">
              Total Memory Request
              <Info style={{ fontSize: "12px", marginLeft: "0.25rem" }} />
            </div>
          </Tooltip>
        );
      },
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["ReportsAggregatorObjectCostNew"], string>
      ) => {
        return (
          <Typography variant="body2">
            {reportResponse?.memorAllocatableError === false && params.row.name !== "_unallocated_"
              ? params.row.memorAllocatable == undefined
                ? ""
                : prettyBytes(params.row.memorAllocatable || 0.0, {
                    bits: false,
                    binary: true,
                  })
              : ""}
          </Typography>
        );
      },
    },
    {
      field: "memoryOriginalRequests",
      hide: !selectedColumns.includes(ReportColumns.MemoryOriginalRequest),
      headerName: "Memory Original Request (avg/h)",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      maxWidth: 200,
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["ReportsAggregatorObjectCostNew"], string>
      ) => {
        return (
          <Typography>
            {reportResponse?.memoryOriginalRequestsError === false && params.row.name !== "_unallocated_"
              ? params.row.memoryOriginalRequests == undefined
                ? ""
                : prettyBytes(params.row.memoryOriginalRequests || 0.0, {
                    bits: false,
                    binary: true,
                  })
              : ""}
          </Typography>
        );
      },
    },
    {
      field: "memoryUsage",
      hide: !selectedColumns.includes(ReportColumns.MemoryUtilization),
      headerName: "Memory Utilization",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      maxWidth: 200,
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["ReportsAggregatorObjectCostNew"], string>
      ) => {
        return (
          <Typography variant="body2">
            {reportResponse?.memoryUsageError === false && params.row.name !== "_unallocated_"
              ? params.row.memoryUsage == undefined
                ? ""
                : params.row.memorAllocatable !== 0
                ? utilizationFormatter.format(params.row.memoryUsage * 100 || 0.0) + "%"
                : "N/A"
              : ""}
          </Typography>
        );
      },
    },
    {
      field: "gpuRequests",
      hide: !selectedColumns.includes(ReportColumns.GPURequest),
      headerName: "GPU Requests",
      flex: 1,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      maxWidth: 200,
      renderCell: (
        params: GridRenderCellParams<string, components["schemas"]["ReportsAggregatorObjectCostNew"], string>
      ) => {
        return (
          <Typography variant="body2">
            {params.row.name !== "_unallocated_"
              ? params.row.gpuRequests == undefined
                ? ""
                : utilizationFormatter.format(params.row.gpuRequests || 0.0)
              : ""}
          </Typography>
        );
      },
    },
  ];

  function handleValue(selectedAggregators: string[]) {
    const selectedAggregatorsCopy = [];
    selectedAggregators.forEach((val) => selectedAggregatorsCopy.push(Object.assign({}, val)));

    for (let i = 0; i < selectedAggregators.length; i++) {
      selectedAggregatorsCopy[i] = handleName(selectedAggregators[i]);
    }

    return selectedAggregatorsCopy;
  }

  function handleName(name: string) {
    if (name === "workload name") {
      return "owner_name";
    }

    if (name === "workload type") {
      return "owner_type";
    }

    return name;
  }

  const handleAddAggregator = () => {
    if (newLabelAggregator && newLabelAggregator.length > 0) {
      setNewLabelAggregator("");
    }
    if (newLabelAggregator && newLabelAggregator.length > 0 && !aggregators.includes(newLabelAggregator)) {
      const Agg = [...aggregators, newLabelAggregator];
      setAggregators(Agg);
      localStorage.setItem(
        scaleopsLabelAggregator,
        JSON.stringify(Agg.filter((entity) => !defaultAggregator.includes(entity)))
      );
      setSelectedAggregators([...selectedAggregators, newLabelAggregator]);
    }
  };

  const renderAggregatorFunction = (option: string, index: number, selected: (string | undefined)[]) => (
    <MenuItem value={option} key={`${index}-multi-select-option`}>
      <Checkbox checked={selected.indexOf(option) > -1} />
      <ListItemText primary={option} />
      {customAggregator.includes(option) && (
        <DeleteOutlineIcon
          onClick={() => {
            const Agg = aggregators.filter((entity) => entity !== option);
            setAggregators(Agg);
            localStorage.setItem(
              scaleopsLabelAggregator,
              JSON.stringify(Agg.filter((entity) => !defaultAggregator.includes(entity)))
            );
          }}
        />
      )}
    </MenuItem>
  );

  return (
    <div>
      <div className="grow">
        <Typography variant="h6">Report</Typography>
        <Typography className="max-w-[620px]" sx={{ fontSize: 12 }}>
          The custom report page is designed to provide you with a comprehensive overview of your data including{" "}
          <b>cost, waste, usage and memory</b>. In addition, we provide an easy-to-use report exporting feature,
          allowing you to quickly export your data to a <b>CSV file</b>.
        </Typography>
      </div>
      <div className="flex gap-6 items-end mb-[90px] mt-[30px]">
        <Input
          placeholder="search..."
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          value={searchTerm ?? ""}
        />
        <TextField
          id="datetime-local"
          label="From"
          onChange={(e) => {
            e.target.value
              ? // new Date(new Date().setUTCDate(new Date().getUTCDate()));
                setFromTimeLocalLocal(
                  new Date(new Date(e.target.value).getTime() - new Date().getTimezoneOffset() * 60 * 1000)
                )
              : setFromTimeLocalLocal(getTimeDefaultFrom());
          }}
          type="datetime-local"
          value={getFromTime(true)}
          variant={"standard"}
          sx={{ width: 180 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="datetime-local"
          label="To"
          type="datetime-local"
          variant={"standard"}
          value={getToTime(true)}
          sx={{ width: 180 }}
          onChange={(e) => {
            e.target.value
              ? setToTimeLocalLocal(
                  new Date(new Date(e.target.value).getTime() - new Date().getTimezoneOffset() * 60 * 1000)
                )
              : setToTimeLocalLocal(getTimeNow());
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div className="flex gap-3 h-[41px]">
          <MultiSelectNamespacesComponent
            options={namespacesOptions}
            selectedNamespaceNames={selectedNamespaceFilters}
            setSelectedNamespaces={setSelectedNamespaceFilters as (props: (string | undefined)[]) => void}
            isFiltered={selectedNamespaceFilters.length > 0}
          />
          <MultiSelectLabelsComponent
            selectedLabelNames={selectedLabelFilters}
            setSelectedLabelNames={setSelectedLabelFilters as (props: (string | undefined)[]) => void}
            isFiltered={selectedLabelFilters.length > 0}
          />
          <MultiSelect
            selected={selectedAggregators}
            setSelected={setSelectedAggregators as (props: (string | undefined)[]) => void}
            options={aggregators}
            optionRenderFunction={renderAggregatorFunction}
            className="mt-[-20px] w-[126px]"
            customIcon={
              <CustomFilterButton
                label="aggregators"
                isFiltered={selectedAggregators.length > 0}
                customIcon={<CustomIcon width={14} height={14} className="mr-1" />}
              />
            }
            customInput={
              <div className="px-6 my-4">
                <AddAggregator
                  newLabelAggregator={newLabelAggregator}
                  setNewLabelAggregator={setNewLabelAggregator}
                  handleAddAggregator={handleAddAggregator}
                />
              </div>
            }
          />
          <MultiSelect
            selected={selectedColumns}
            setSelected={setSelectedColumns}
            options={availableColumns}
            // optionRenderFunction={renderColumnOptionFunction}
            className="mt-[-20px] w-[99px]"
            customIcon={
              <CustomFilterButton
                label="columns"
                isFiltered={selectedColumns.length > 0}
                customIcon={<EyeIcon width={14} height={14} className="mr-1" />}
              />
            }
          />
        </div>
      </div>
      <DataGrid
        headerHeight={HEADER_HEIGHT}
        rowHeight={90}
        sx={{
          ...getDataGridSx(),
        }}
        componentsProps={{
          toolbar: {
            printOptions: { disableToolbarButton: true },
          },
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
        loading={loadingTable}
        hideFooter={true}
        columns={columns}
        rows={costs}
        hideFooterPagination={true}
        autoHeight={true}
        initialState={{
          sorting: { sortModel: [{ sort: "desc", field: "totalCost" }] },
        }}
        key={costs.toString()}
        disableSelectionOnClick
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
};

export default HistoryReport;
