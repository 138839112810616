import { CircularProgress, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BooleanParam, useQueryParam } from "use-query-params";
import PlainCubeIcon from "../../../Icons/PlainCubeIcon";
import {
  GetBetaFeaturesQuery,
  GetBetaFeaturesResponse,
  GetNodeGroupInfo,
  GetNodeGroupInfoResponse,
  GetRebalanceStatus,
  GetRebalanceStatusResponse,
  GetUIFeaturesQuery,
  GetUIFeaturesResponse,
  SelectedReason,
} from "../../../api/fetcher";
import { TOAST_SETTINGS } from "../../../pages/Roles/mutations/utils";
import { HAS_UNHEALTHY_EVICTABLE_UPGRADE } from "../../../utils/developmentFeatureFlags";
import Button, { BUTTON_VARIANTS } from "../../Button";
import Dialog from "../../Dialog";
import Tooltip from "../../Tooltip";
import BeforeAndAfterBoxesContainer from "./BeforeAndAfterBoxesContainer";
import ButtonsContainer from "./ButtonsContainer";
import ConsolidateTable, { CONSOLIDATION_TABLE_QUERY_PREFIX } from "./ConsolidateTable";
import ForceDeleteKarpenterSwitch from "./ForceDeleteKarpenterSwitch";
import RemoveNodesWithLocalStorageSwitch from "./RemoveNodesWithLocalStorageSwitch";
import SwitchUnEvictableNodes from "./SwitchUnevictableNodes";
import useGetRebalanceQueryParams from "./useGetRebalanceQueryParams";
import { BannerData, ConsolidationState, DisqualifiedNodes, getAfterValue, NodeRow, ROW } from "./utils";
import SnapshotWrapper from "../../SnapshotWrapper";

const uiFeaturesQuery = GetUIFeaturesQuery();
const getRebalanceStatus = GetRebalanceStatus();
const nodeGroupsInfoQuery = GetNodeGroupInfo();
const betaFeaturesQuery = GetBetaFeaturesQuery();

const TOOLTIP_TEXT = (
  <>
    Consolidate nodes to <b>optimize</b> your cluster and <b>reduce cost</b>.
  </>
);

const TOOLTIP_DISABLED_TEXT = (
  <>
    <b>No Cluster Auto Scaler detected</b>, can't generate plan.
  </>
);

interface Props {
  hasNodeGroups: boolean;
  hasNodePools: boolean;
}

const ConsolidateNodesContainer = ({ hasNodeGroups, hasNodePools }: Props) => {
  const [enableNodeConsolidation] = useQueryParam("enableNodeConsolidation", BooleanParam);
  const [isDialogueOpen, setIsDialogueOpen] = useQueryParam("isConsolidationDialogueOpen", BooleanParam);

  const rebalanceQueryParams = useGetRebalanceQueryParams(CONSOLIDATION_TABLE_QUERY_PREFIX);

  const [selectedRows, setSelectedRows] = useState<ROW[]>([]);

  const [nodeData, setNodeData] = useState<{
    [k: string]: NodeRow;
  }>({});
  const [forfeitsArr, setForfeitsArr] = useState<string[]>([]);
  const [disqualifiedNodes, setDisqualifiedNodes] = useState<DisqualifiedNodes[]>([]);

  const [bannerData, setBannerData] = useState<BannerData>({
    isLoading: true,
    isInProgress: false,
  });

  const {
    data: uiFeaturesData,
    isLoading: uiFeaturesIsLoading,
    isError: uiFeaturesIsError,
  } = useQuery<GetUIFeaturesResponse, Error>({
    queryKey: [uiFeaturesQuery.queryKey],
    queryFn: uiFeaturesQuery.queryFn,
  });

  const { data: betaFeaturesData } = useQuery<GetBetaFeaturesResponse, Error>({
    queryKey: [betaFeaturesQuery.queryKey],
    queryFn: betaFeaturesQuery.queryFn,
  });

  const {
    data: rebalanceStatusData,
    isLoading: rebalanceStatusIsLoading,
    isError: rebalanceStatusIsError,
    isFetching: rebalanceStatusIsFetching,
  } = useQuery<GetRebalanceStatusResponse, Error>({
    queryKey: [getRebalanceStatus.queryKey, rebalanceQueryParams],
    queryFn: () =>
      getRebalanceStatus.queryFn({
        nodeConsolidationFilter: {
          availabilityZones: rebalanceQueryParams.selectedAvailabilityZones?.map((az) => String(az)),
          labels: rebalanceQueryParams.selectedLabels
            ?.map((label) => {
              if (!label) return null;
              let [key, value] = label.split("=");
              key = String(key.trim());
              value = String(value.trim());
              return { key, value };
            })
            .filter((entity) => entity !== null) as { key: string; value: string }[],
          names: rebalanceQueryParams.searchTerm ? [rebalanceQueryParams.searchTerm] : undefined,
          nodeGroups: rebalanceQueryParams.selectedNodeGroups?.map((ng) => String(ng)),
          nodePools: rebalanceQueryParams.selectedNodePools?.map((np) => String(np)),
          provisioners: rebalanceQueryParams.selectedProvisioners?.map((p) => String(p)),
          scaleDownReasons: rebalanceQueryParams.selectedScaleDownReasons
            ? (rebalanceQueryParams.selectedScaleDownReasons as SelectedReason[])
            : undefined,
          workloadLabels: rebalanceQueryParams.selectedWorkloadLabels?.map((l) => String(l)),
          workloadAnnotations: rebalanceQueryParams.selectedWorkloadAnnotations?.map((a) => String(a)),
          workloadsNames: rebalanceQueryParams.selectedWorkloadNames?.map((n) => String(n)),
        },
      }),
    retry: true,
    onError: () => {
      toast.error(`Error fetching Rebalance Status`, TOAST_SETTINGS);
    },
    refetchInterval: (data) =>
      data?.status?.state === ConsolidationState.Running || data?.status?.state === ConsolidationState.Canceled
        ? 3000
        : false,
  });

  const {
    data: nodeGroupsInfoData,
    isLoading: nodeGroupsInfoIsLoading,
    isError: nodeGroupsInfoIsError,
  } = useQuery<GetNodeGroupInfoResponse, Error>({
    queryKey: [nodeGroupsInfoQuery.queryKey],
    queryFn: () => nodeGroupsInfoQuery.queryFn({}),
  });

  useEffect(() => {
    setForfeitsArr(rebalanceStatusData?.status?.Forfeit ?? []);
    setDisqualifiedNodes(rebalanceStatusData?.status?.disqualifiedNodes ?? []);

    const newNodeData = Object.fromEntries(
      Object.entries(rebalanceStatusData?.status?.nodeData ?? []).map(([k, v]) => {
        let status: ConsolidationState | undefined;
        switch (true) {
          case rebalanceStatusData?.status?.failed?.includes(k):
            status = ConsolidationState.Failed;
            break;
          case rebalanceStatusData?.status?.successful?.includes(k):
            status = ConsolidationState.Completed;
            break;
          case rebalanceStatusData?.status?.inProgress?.includes(k):
            status = ConsolidationState.Running;
            break;
          case rebalanceStatusData?.status?.candidates?.includes(k) &&
            rebalanceStatusData?.status?.state === ConsolidationState.Ready:
            status = ConsolidationState.Ready;
            break;
          case rebalanceStatusData?.status?.candidates?.includes(k) &&
            rebalanceStatusData?.status?.state !== ConsolidationState.Ready:
            status = ConsolidationState.Pending;
            break;
          default:
            break;
        }

        return [k, { ...v, status }];
      })
    );

    setNodeData(newNodeData);

    const sumBefore = nodeGroupsInfoData?.nodeStats?.reduce(
      (acc, curr) => {
        return {
          cost: acc.cost + curr.cost,
          cpu: acc.cpu + curr.cpuAllocatable,
          memory: acc.memory + curr.memoryAllocatable,
        };
      },
      { cost: 0, cpu: 0, memory: 0 }
    );

    const successfulIds = rebalanceStatusData?.status?.successful ?? [];

    const evictsSums = Object.entries(rebalanceStatusData?.status?.nodeData ?? {})
      .map(([key, value]) => ({ ...value, name: key, id: key }))
      .reduce(
        (acc, curr) => {
          if (successfulIds.includes(curr.id)) {
            return acc;
          }
          return {
            cost: acc.cost + (curr?.monthlyCost ?? 0),
            cpu: acc.cpu + (curr?.cpu ?? 0),
            memory: acc.memory + (curr?.memory ?? 0),
          };
        },
        { cost: 0, cpu: 0, memory: 0 }
      );

    setBannerData({
      costBefore: sumBefore?.cost ?? 0,
      costAfter: getAfterValue({ beforeValue: sumBefore?.cost, valueToReduce: evictsSums.cost }),
      cpuBefore: sumBefore?.cpu ?? 0,
      cpuAfter: getAfterValue({ beforeValue: sumBefore?.cpu, valueToReduce: evictsSums.cpu }),
      memoryBefore: sumBefore?.memory ?? 0,
      memoryAfter: getAfterValue({ beforeValue: sumBefore?.memory, valueToReduce: evictsSums.memory }),
      isInProgress: rebalanceStatusData?.status?.state === ConsolidationState.Running,
      isLoading: uiFeaturesIsLoading || rebalanceStatusIsLoading || nodeGroupsInfoIsLoading,
    });
  }, [rebalanceStatusData, nodeGroupsInfoData, uiFeaturesIsLoading, rebalanceStatusIsLoading, nodeGroupsInfoIsLoading]);

  const numberOfCandidates = rebalanceStatusData?.status?.candidates
    ? rebalanceStatusData?.status?.candidates.length
    : 0;

  if (enableNodeConsolidation === false) {
    return null;
  }

  if (
    !uiFeaturesData?.uiFeatures?.nodeConsolidation ||
    uiFeaturesIsError ||
    rebalanceStatusIsError ||
    nodeGroupsInfoIsError
  ) {
    return null;
  }

  const disableConsolidateButton =
    !rebalanceStatusData?.status?.nodeScalers || Object.keys(rebalanceStatusData?.status?.nodeScalers).length === 0;
  return (
    <SnapshotWrapper
      inactive={betaFeaturesData?.enabled && betaFeaturesData?.betaFeatures?.nodeConsolidation}
      wrappedType={"div"}
    >
      <Dialog
        isOpen={!!isDialogueOpen}
        onClose={() => {
          setIsDialogueOpen(false);
        }}
        title="Consolidation plan"
        dialogContentStyle={{
          padding: "24px",
        }}
        fullWidth={false}
        minWidth={1200}
      >
        <div className="flex flex-col gap-2.5">
          <div className="border border-strongBorder rounded-lg px-20 py-[70px] w-full flex flex-col gap-10">
            <div className="w-full flex justify-start items-center gap-9">
              <PlainCubeIcon width={90} height={90} />
              <div>
                <Typography variant="h6" fontWeight={700} className="flex gap-2 items-center">
                  Node optimization
                </Typography>
                <Typography variant="body2" className="text-text-lightBlack">
                  You have{" "}
                  <b>
                    {numberOfCandidates} {pluralize("nodes", numberOfCandidates)}
                  </b>{" "}
                  ready for optimization.
                  <br />
                  <b>Integrate ScaleOps</b> with your cluster Autoscaler to <b>optimize nodes</b>.
                </Typography>
              </div>
            </div>
            <div className="flex justify-between w-full py-8">
              <BeforeAndAfterBoxesContainer {...bannerData} />
            </div>
            <ButtonsContainer
              state={rebalanceStatusData?.status?.state as ConsolidationState | undefined}
              allNodes={
                rebalanceStatusData?.status?.nodeData
                  ? Object.keys(rebalanceStatusData?.status?.nodeData).length
                  : undefined
              }
              successfulNodes={
                rebalanceStatusData?.status?.successful ? rebalanceStatusData?.status?.successful.length : undefined
              }
              inProgressNodes={
                rebalanceStatusData?.status?.inProgress ? rebalanceStatusData?.status?.inProgress.length : undefined
              }
              numberOfCandidates={numberOfCandidates}
              numberOfSelectedRows={selectedRows.length ?? 0}
              selectedRows={selectedRows}
            />
          </div>
          <SwitchUnEvictableNodes spec={rebalanceStatusData?.spec} />
          {HAS_UNHEALTHY_EVICTABLE_UPGRADE && hasNodeGroups && (
            <RemoveNodesWithLocalStorageSwitch spec={rebalanceStatusData?.spec} />
          )}
          {hasNodePools && <ForceDeleteKarpenterSwitch spec={rebalanceStatusData?.spec} />}
          <ConsolidateTable
            nodeData={nodeData}
            forfeitsArr={forfeitsArr}
            disqualifiedNodes={disqualifiedNodes}
            isLoading={rebalanceStatusIsFetching}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            state={rebalanceStatusData?.status?.state as ConsolidationState | undefined}
          />
        </div>
      </Dialog>
      <div className="bg-white rounded-lg pr-8 py-8 flex justify-between items-center">
        {bannerData.isLoading ? (
          <div className="w-full flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="w-[80%] flex justify-evenly items-center">
              <BeforeAndAfterBoxesContainer {...bannerData} />
            </div>
            <div className="relative">
              {bannerData?.isInProgress ? (
                <div className="absolute top-[-16px] right-[-20px] rounded-full bg-text-lightBlack scaleopsShadow text-white text-[10px] px-2 py-1">
                  In progress
                  <span className="animate-ping">...</span>
                </div>
              ) : null}
              <Tooltip
                title={disableConsolidateButton ? TOOLTIP_DISABLED_TEXT : TOOLTIP_TEXT}
                className="w-max flex justify-center"
                maxWidth={500}
              >
                <Button
                  label={
                    <div className="flex gap-3 items-center justify-center relative">
                      <span>EXPLORE CONSOLIDATION PLAN</span>
                    </div>
                  }
                  variant={BUTTON_VARIANTS.mediumDarkPurple}
                  onClick={() => {
                    setIsDialogueOpen(true);
                  }}
                  disabled={disableConsolidateButton}
                  fontSize={14}
                />
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </SnapshotWrapper>
  );
};

export default ConsolidateNodesContainer;
