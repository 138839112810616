import { CircularProgress, ListItemText, MenuItem, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { InfoIcon } from "@primer/octicons-react";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMainContext } from "../../MainContext";
import useGetUserRole from "../../MainMenu/useGetUserRole";
import {
  AlertsSettingsResponse,
  GetAlertsSettings,
  GetSlackConf,
  GetSlackConfResponse,
  UpdateSlackConfMultiInput,
} from "../../api/fetcher";
import { components } from "../../api/schema";
import CustomCheckBox from "../../components/CustomCheckBox";
import SingleSelect, { SingleSelectSize } from "../../components/SingleSelect";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../components/Tab";
import Tooltip from "../../components/Tooltip";
import { getDataGridSx, pageContentClassName } from "../../utils/styleUtils";
import useHasResourceQuota from "../Analytics/AnalyticsV2/Graphs/useHasResourceQuota";
import AlertTooltipTitle from "./AlertTooltipTitle";
import AlertsSettings from "./AlertsSettings";
import {
  adjustThreshold,
  formatAlertName,
  getIntervalOptions,
  getSampleWindowOptions,
  getSeverityOptions,
  getThresholdAbsoluteOptions,
  getThresholdPercentageOptions,
  Row,
  thresholdPrefix,
} from "./alertsUtils";
import useUpdateParam from "./useUpdateParam";
import useUpdateSlackConf from "./useUpdateSlackConf";
import useIsReadyOnlyFrontEnd from "../../utils/useIsReadyOnlyFrontEnd";
// import ClusterMultiSelect from "./ClusterMultiSelect";

const THRESHOLD_PERCENTAGE_OPTIONS = getThresholdPercentageOptions();
const THRESHOLD_ABSOLUTE_OPTIONS = getThresholdAbsoluteOptions();
const RESOURCE_QUOTA = "resource quota";

const ShouldUseAbsoluteThreshold = (id: string | undefined) => {
  return id == "OutOfMemory" || id == "PodFailedCreateEvent";
};
interface CustomHeaderProps {
  title: string;
  description: React.ReactNode;
}

const CustomHeader = ({ title, description }: CustomHeaderProps) => (
  <Tooltip title={description}>
    <Typography variant="body2" className="flex items-center gap-2" fontWeight={700}>
      {title}
      <InfoIcon size={12} />
    </Typography>
  </Tooltip>
);

const getColumns = (
  slackEnabled: boolean,
  data: AlertsSettingsResponse | undefined,
  isMultiCluster: boolean,
  isDisabled: boolean
): GridColDef[] => [
  {
    field: "enabled",
    headerName: "",
    width: 50,
    renderCell: (params: GridRenderCellParams<number, Row>) => {
      const [enabled, setEnabled] = useState<boolean>(Boolean(params.value));
      const updateParam = useUpdateParam(() => setEnabled(enabled));

      return (
        <Typography className="flex items-center justify-center gap-2 w-full" variant="body2">
          <CustomCheckBox
            value={enabled}
            onClick={() => {
              if (params.row.id) {
                updateParam({
                  data,
                  id: params.row.id,
                  value: !enabled,
                  param: "enabled",
                  isMultiCluster: isMultiCluster,
                });
              }
              setEnabled(!enabled);
            }}
            disabled={isDisabled}
          />
        </Typography>
      );
    },
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: (params: GridRenderCellParams<number, Row>) => {
      const titleData = {
        id: params.row.id,
        window: params.row.window,
        interval: params.row.interval,
        threshold: params.row.threshold,
      };
      return (
        <Tooltip title={<AlertTooltipTitle data={titleData} />} className="truncate" maxWidth={600} placement="right">
          {params.row.name?.replace("Resource Quota-", "Resource Quota: ")}
        </Tooltip>
      );
    },
  },
  {
    field: "window",
    headerName: "History Window",
    flex: 1,
    renderHeader: () => (
      <CustomHeader
        title="Window"
        description={
          <>
            The <b>window</b> is the amount of time that the alert will look back on to determine if the alert should be
            triggered.
            <br />
            <br />
            For example, if the sample window is 5 minutes, the alert will look back on the last 5 minutes of data to
            determine if the alert should be triggered.
          </>
        }
      />
    ),
    renderCell: (params: GridRenderCellParams<number, Row>) => {
      const [window, setWindow] = useState<number | undefined>(params.value);
      const updateParam = useUpdateParam(() => setWindow(window));

      const options = getSampleWindowOptions(params.row.id);

      return (
        <>
          <SingleSelect<number | undefined>
            selected={window}
            setSelected={(value) => {
              if (params.row.id && value) {
                updateParam({ data, id: params.row.id, value, param: "window", isMultiCluster: isMultiCluster });
              }
              setWindow(value);
            }}
            renderOptionsFunc={() =>
              Object.entries(options).map(([key, value]) => (
                <MenuItem value={value} key={key}>
                  <ListItemText primary={key} />
                </MenuItem>
              ))
            }
            className="w-[150px]"
            size={SingleSelectSize.Small}
            disabled={isDisabled}
          />
        </>
      );
    },
  },
  {
    field: "threshold",
    headerName: "Threshold",
    minWidth: 320,
    flex: 1,
    renderCell: (params: GridRenderCellParams<number, Row>) => {
      const adjustedThreshold = adjustThreshold(params.row.isScaleopsAlert, params.value);
      const [threshold, setThreshold] = useState<number | undefined>(adjustedThreshold);
      const updateParam = useUpdateParam(() => setThreshold(threshold));

      return (
        <div
          className={clsx("flex items-center gap-2", {
            "text-text-disable": isDisabled,
          })}
        >
          <SingleSelect<number | undefined>
            selected={threshold}
            setSelected={(value) => {
              const adjustedValue = adjustThreshold(params.row.isScaleopsAlert, value);
              if (params.row.id && adjustedValue) {
                updateParam({
                  data,
                  id: params.row.id,
                  value: adjustedValue,
                  param: "threshold",
                  isMultiCluster: isMultiCluster,
                });
              }
              setThreshold(value);
            }}
            renderOptionsFunc={() => {
              return Object.entries(
                ShouldUseAbsoluteThreshold(params.row.id) ? THRESHOLD_ABSOLUTE_OPTIONS : THRESHOLD_PERCENTAGE_OPTIONS
              ).map(([key, value]) => (
                <MenuItem value={value} key={key}>
                  <ListItemText primary={key} />
                </MenuItem>
              ));
            }}
            className="w-[75px]"
            size={SingleSelectSize.Small}
            disabled={isDisabled}
          />
          {thresholdPrefix[params.row.id as keyof typeof thresholdPrefix]}
          {thresholdPrefix[params.row.id as keyof typeof thresholdPrefix] ? "." : ""}
        </div>
      );
    },
  },
  {
    field: "slackFrequency",
    headerName: "Slack Message Frequency",
    hide: !slackEnabled,
    flex: 1,
    renderHeader: () => (
      <CustomHeader
        title="Alert Interval"
        description={
          <>
            The <b>Interval</b> is the amount of time between slack alerts on this alert.
          </>
        }
      />
    ),
    renderCell: (params: GridRenderCellParams<number, Row>) => {
      const [interval, setInterval] = useState<number | undefined>(params.value);
      const updateSlackConf = useUpdateSlackConf(() => {
        setInterval(interval);
      });
      const options = getIntervalOptions(params.row.id);
      return (
        <SingleSelect<number | undefined>
          selected={interval}
          setSelected={(value) => {
            if (params.row.id && value) {
              updateSlackConf.mutate({
                multiCluster: isMultiCluster,
                modifications: [
                  {
                    type: params.row.id,
                    key: "slackFrequency",
                    value: { raw: String(value) },
                  },
                ],
              } as UpdateSlackConfMultiInput);
            }
            setInterval(value);
          }}
          renderOptionsFunc={() =>
            Object.entries(options).map(([key, value]) => (
              <MenuItem value={value} key={key}>
                <ListItemText primary={key} />
              </MenuItem>
            ))
          }
          className="w-[150px]"
          size={SingleSelectSize.Small}
          disabled={isDisabled}
        />
      );
    },
  },
  {
    field: "severity",
    headerName: "Severity",
    flex: 1,
    renderCell: (params: GridRenderCellParams<string, Row>) => {
      const [severity, setSeverity] = useState<string | undefined>(params.value);
      const updateParam = useUpdateParam(() => {
        setSeverity(severity);
      });

      const options = getSeverityOptions();
      return (
        <SingleSelect<string | undefined>
          selected={severity}
          setSelected={(value) => {
            if (params.row.id && value) {
              updateParam({ data, id: params.row.id, value, param: "severity", isMultiCluster: isMultiCluster });
            }
            setSeverity(value);
          }}
          renderOptionsFunc={() =>
            Object.entries(options).map(([key, value]) => (
              <MenuItem value={value} key={key}>
                <ListItemText primary={key} />
              </MenuItem>
            ))
          }
          className="w-[150px]"
          size={SingleSelectSize.Small}
          disabled={isDisabled}
        />
      );
    },
  },
];

enum TabOptions {
  ClusterAlerts = "Cluster health",
  ScaleOpsAlerts = "ScaleOps health",
}

interface Props {
  isMultiCluster: boolean;
}

const alertOrder = [
  "NodeUtilization",
  "OutOfMemory",
  "UnderProvisioned",
  "OverProvisioned",
  "PodFailedCreateEvent",
  "CpuThrottling",
  "WorkloadRequestIncrease",
  "ResourceQuota",
];
const compareKnownAlerts = (
  a: components["schemas"]["AlertsEnhancedAlertSettings"],
  b: components["schemas"]["AlertsEnhancedAlertSettings"]
): number => {
  const normalizeName = (name: string) => {
    return name.startsWith("ResourceQuota-") ? name.split("-")[0] : name;
  };
  const aIndex = alertOrder.findIndex((e) => e == normalizeName(a?.name || ""));
  const bIndex = alertOrder.findIndex((e) => e == normalizeName(b?.name || ""));
  const diff = aIndex - bIndex;
  if (diff === 0) {
    return a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase() as string) || 0;
  } else {
    return diff;
  }
};

const AlertsContainer = ({ isMultiCluster }: Props) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const { userRole } = useGetUserRole();
  const hasResourceQuota = useHasResourceQuota();
  const { currentCluster, didClusterChanged } = useMainContext();
  const alertsSettings = GetAlertsSettings();
  const [, setSlackEnabled] = useState<boolean>(true);
  const [rows, setRows] = useState<Row[] | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState(TabOptions.ClusterAlerts);

  const slackConf = GetSlackConf();

  const { data, isLoading, isError, error } = useQuery<AlertsSettingsResponse>({
    queryKey: [alertsSettings.queryKey],
    queryFn: () => alertsSettings.queryFn(),
  });

  const { data: slackData } = useQuery<GetSlackConfResponse, Error>({
    queryKey: [slackConf.queryKey],
    queryFn: () => slackConf.queryFn(),
  });
  useEffect(() => {
    setSlackEnabled(!!slackData?.config?.token);
  }, [slackData]);
  if (error && didClusterChanged) {
    toast.error("Error fetching data for this cluster");
    console.log(error);
  }
  if (isError) {
    toast.error("Error fetching alerts settings");
  }

  useEffect(() => {
    setRows(undefined);
  }, [currentCluster]);

  useEffect(() => {
    setRows(
      data?.alerts
        ?.sort(compareKnownAlerts)
        .map((row: components["schemas"]["AlertsEnhancedAlertSettings"]) => {
          return {
            id: row.name,
            name: formatAlertName(row.name ?? "").replace("Cpu", "CPU"),
            interval: row.alertSettings?.parameters?.interval ?? 0,
            slackFrequency: row.slackFrequency ?? 0,
            window: row.alertSettings?.parameters?.window ?? 0,
            threshold: row.alertSettings?.parameters?.threshold ?? 0,
            enabled: !!row.alertSettings?.enabled,
            severity: row.alertSettings?.severity,
            isScaleopsAlert: row?.isScaleopsAlert,
          } as Row;
        })
        .filter((row) => {
          if (!hasResourceQuota) {
            return !row.name.toLowerCase().includes(RESOURCE_QUOTA);
          }
          return true;
        })
        .filter((row) => {
          if (selectedTab === TabOptions.ScaleOpsAlerts) {
            return !!row.isScaleopsAlert;
          } else {
            return !row.isScaleopsAlert;
          }
        })
    );
  }, [data, selectedTab]);

  return (
    <div className={clsx("relative mt-[36px] rounded-tl-none", pageContentClassName)}>
      <div className={clsx(TABS_CONTAINER_CLASS_NAME, "top-[-36px] left-[0px]")}>
        {Object.entries(TabOptions).map(([key, value]) => (
          <Tab
            key={key}
            isSelected={selectedTab === value}
            onClick={() => {
              setSelectedTab(value);
            }}
            name={value}
            dataTestId={`alerts-tab-${key.toLocaleLowerCase()}-tab`}
          />
        ))}
      </div>
      <div className={"flex justify-end"}>
        <AlertsSettings alertsSettingsData={data} isMultiCluster={isMultiCluster} />
      </div>
      <div>
        <Typography variant="h6">{selectedTab} alerts</Typography>
        <Typography className="max-w-[620px]" sx={{ fontSize: 12 }}>
          Manage your <b>{selectedTab} alerts</b> here. You can create, edit, and delete alerts. You can also enable or
          disable Slack notifications for each alert.
        </Typography>
        {userRole !== "Admin" && (
          <Typography className="max-w-[620px] italic" sx={{ fontSize: 12 }}>
            <b>Note:</b> only Admins can edit alerts.
          </Typography>
        )}
      </div>
      <div className="my-8">
        {isLoading || !rows ? (
          <div className="flex items-center justify-center min-h-56">
            <CircularProgress />
          </div>
        ) : (
          <DataGrid
            rows={rows ?? []}
            rowHeight={54}
            columns={getColumns(true, data, isMultiCluster, userRole !== "Admin" || isReadyOnlyFrontEnd)}
            pageSize={20}
            disableSelectionOnClick
            disableColumnMenu
            sx={{
              ...getDataGridSx(),
            }}
            autoHeight
          />
        )}
      </div>
    </div>
  );
};

export default AlertsContainer;
