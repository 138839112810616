import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getPolicies, GetPoliciesResponse } from "../../../../api/fetcher";

const DefaultHpaPolicy = "production-hpa";

const { queryFn, queryKey } = getPolicies();

const useGetDefaultHPAPolicy = (): string | undefined => {
  const [defaultHpaPolicy, setDefaultHpaPolicy] = useState<string | undefined>(undefined);
  const { data, isLoading, isError } = useQuery<GetPoliciesResponse, Error>({
    queryKey: [queryKey],
    queryFn: queryFn,
  });

  useEffect(() => {
    if (data) {
      const allPolicies: string[] | undefined = data?.policies
        ?.map((policy) => policy.metadata?.name)
        .filter((p) => !!p)
        .map((p) => String(p));

      switch (true) {
        case allPolicies?.includes(DefaultHpaPolicy):
          setDefaultHpaPolicy(DefaultHpaPolicy);
          break;
        case !!allPolicies?.find((policy) => policy.startsWith("hpa-")):
          setDefaultHpaPolicy(allPolicies?.find((policy) => policy.startsWith("hpa-")));
          break;
        default:
          setDefaultHpaPolicy(undefined);
          break;
      }
    }
  }, [data]);

  if (isLoading || isError) {
    return undefined;
  }

  return defaultHpaPolicy;
};

export default useGetDefaultHPAPolicy;
