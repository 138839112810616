import { useQuery } from "@tanstack/react-query";
import { getLabels, GetLabelsResponse } from "../../../api/fetcher";

const KEY_AND_VALUE_COMBINATIONS_TO_EXCLUDE = [
  "app.kubernetes.io/instance=scaleops",
  "app.kubernetes.io/managed-by=Helm",
  "app.kubernetes.io/name=scaleops",
];

const KEYS_TO_EXCLUDE = [
  "kubectl.kubernetes.io/last-applied-configuration",
  "deployment.kubernetes.io/revision",
  "kubectl.kubernetes.io/restartedAt",
  "scaleops.sh/post39",
];

const { queryKey, queryFn } = getLabels();

const useGetAnnotations = (isMultiCluster?: boolean) => {
  const { data, isLoading, error } = useQuery<GetLabelsResponse>({
    queryKey: [queryKey, isMultiCluster],
    queryFn: () => queryFn(isMultiCluster),
  });

  if (error) {
    console.log("Error fetching annotations using labels", error);
    return [];
  }

  if (isLoading || data?.annotations === undefined) {
    return [];
  }

  const ownerAnnotations = data.ownerAnnotations ?? [];
  const annotations = [...data.annotations, ...ownerAnnotations];
  let uniqueAnnotations = [...new Set(annotations)];

  uniqueAnnotations = uniqueAnnotations.filter((annotation: string) => {
    const key = annotation.split("=")[0];

    if (KEY_AND_VALUE_COMBINATIONS_TO_EXCLUDE.includes(annotation) || KEYS_TO_EXCLUDE.includes(key)) {
      return false;
    }

    return true;
  });

  return uniqueAnnotations;
};

export default useGetAnnotations;
