import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import clsx from "clsx";
import { cloneElement } from "react";
import { NavLink, useLocation } from "react-router-dom";

export const MAIN_MENU_ID_FOR_SCROLL_INITIALIZATION = "main-content";

const initiateMainBodyScrollPosition = () => {
  const mainContent = document.getElementById(MAIN_MENU_ID_FOR_SCROLL_INITIALIZATION);
  if (mainContent) {
    mainContent.style.overflow = "hidden";
    mainContent.scrollTop = 0;
    mainContent.style.overflow = "auto";
  }
};

export enum MainMenuItemSize {
  Small = "small",
  Regular = "regular",
}
interface Props {
  title: string;
  to: string;
  size?: MainMenuItemSize;
  icon?: React.ReactElement;
  isExternalLink?: boolean;
  variant?: Variant;
  isEmphasized?: boolean;
}

const MainMenuItem = ({
  title,
  to,
  size = MainMenuItemSize.Regular,
  icon,
  isExternalLink,
  variant = "body2",
  isEmphasized,
}: Props) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const id = title.replaceAll(" ", "-").toLocaleLowerCase();

  const currentCluster = sessionStorage.getItem("currentCluster") || undefined;
  to = currentCluster ? `${to}?currentClusterURLParam=${currentCluster}` : to;

  const linkContent = (
    <Typography
      variant={variant}
      className={clsx("flex justify-start items-center gap-2 rounded-lg", {
        "px-2": size === MainMenuItemSize.Small,
        "px-4 py-1": size === MainMenuItemSize.Regular,
        "text-white hover:bg-mainMenuBackgroundHover": !isActive,
        "text-black bg-mainMenuSelected": isActive,
        "border-main-yellow text-main-yellow border": isEmphasized && !isActive,
      })}
      data-testid={`main-menu-item-${title.toLocaleLowerCase().replace(" ", "-")}`}
    >
      {icon &&
        cloneElement(icon, {
          width: 17,
          height: 17,
        })}
      <p className="relative w-fit">{title}</p>
    </Typography>
  );

  if (isExternalLink)
    return (
      <a href={to} target="_blank" id={id} rel="noreferrer">
        {linkContent}
      </a>
    );

  return (
    <NavLink to={to} id={id} onClick={initiateMainBodyScrollPosition}>
      {linkContent}
    </NavLink>
  );
};

export default MainMenuItem;
