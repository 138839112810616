import { Typography } from "@mui/material";

interface Props {
  title: string;
  background: string;
  color: string;
}

const DataTag = ({ title, background, color }: Props) => {
  return (
    <span className="mr-1">
      <Typography
        variant="caption"
        className="w-fit px-2 border-border rounded-md scaleopsTextShadow"
        style={{
          background,
          color,
        }}
      >
        {title}
      </Typography>
    </span>
  );
};

export default DataTag;
