import { Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowClassNameParams } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StringParam, useQueryParam } from "use-query-params";
import {
  getDashboardByNamespace,
  GetDashboardByNamespaceResponse,
  getPolicies,
  GetPoliciesResponse,
} from "../../api/fetcher";
import { components } from "../../api/schema";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import PercentageMeter from "../../components/PercentageMeter";
import Tooltip from "../../components/Tooltip";
import useHpaOptimizationEnabled from "../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import YouHaveReadOnlyAccess from "../../components/YouHaveReadOnlyAccess";
import MagicWand from "../../Icons/MagicWand";
import RightArrowIcon from "../../Icons/RightArrowIcon";
import { useMainContext } from "../../MainContext";
import useGetUserRole from "../../MainMenu/useGetUserRole";
import {
  isBuiltInPolicy,
  policyMinChangeScaleDownCpu,
  policyMinChangeScaleUpCpu,
  policyStabilizationScaleDownWindow,
  policyStabilizationScaleUpWindow,
} from "../../utils/policyUtils";
import { getDataGridSx, HEADER_HEIGHT } from "../../utils/styleUtils";
import useIsReadyOnlyFrontEnd from "../../utils/useIsReadyOnlyFrontEnd";
import EditPolicyDrawer from "./EditPolicyDrawer";
import useDeletePolicy from "./mutations/useDeletePolicy";
import PolicyActionCell from "./PolicyActionCell";
import PolicyName from "./PolicyName";
import usePoliciesStats from "./usePoliciesStats";
import { PolicyType, RowParams } from "./utils";

const wrapperClassName = "bg-white p-12 pb-20 mb-12 min-w-[1300px]";
const SCHEDULED_POLICY = "Schedule";
const DEFAULT_POLICY_NAME = "production";
const ADMIN_ROLE = "Admin";

interface Props {
  openCreateNewPolicyDialog: () => void;
  openEditPolicyDialog: (p: components["schemas"]["V1alpha1Policy"]) => void;
  handleDuplicateClick: (p: components["schemas"]["V1alpha1Policy"]) => void;
  setSelectedCheckbox: (s: string) => void;
  setPolicies: (p: components["schemas"]["V1alpha1Policy"][]) => void;
}

const PoliciesContainer = ({
  openCreateNewPolicyDialog,
  openEditPolicyDialog,
  handleDuplicateClick,
  setSelectedCheckbox,
  setPolicies,
}: Props) => {
  const [policyToOpenOnInitialLoading, setPolicyToOpenOnInitialLoading] = useQueryParam(
    "policyToOpenOnInitialLoading",
    StringParam
  );

  const [rows, setRows] = useState<RowParams["row"][]>();
  const { didClusterChanged } = useMainContext();
  const policiesStats = usePoliciesStats();
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();

  const policiesFetcher = getPolicies();
  const dashboardByNamespace = getDashboardByNamespace();
  const deletePolicy = useDeletePolicy();

  const [isEditPolicyDrawerOpen, setIsEditPolicyDrawerOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState<components["schemas"]["V1alpha1Policy"]>();
  const { userRole } = useGetUserRole();

  const { data, isLoading, error } = useQuery<GetPoliciesResponse, Error>({
    queryKey: [policiesFetcher.queryKey],
    queryFn: policiesFetcher.queryFn,
  });

  const { data: byNamespaceData, error: byNamespaceError } = useQuery<GetDashboardByNamespaceResponse, Error>({
    queryKey: [dashboardByNamespace.queryKey],
    queryFn: () => dashboardByNamespace.queryFn({}),
    refetchInterval: 12000,
  });

  if (byNamespaceError && didClusterChanged) {
    toast.error("Error fetching data for this cluster");
    console.log(byNamespaceError);
  }

  useEffect(() => {
    if (data) {
      setPolicies(data.policies as components["schemas"]["V1alpha1Policy"][]);
    }
  }, [data]);

  const enableHpaOptimization = useHpaOptimizationEnabled();

  useEffect(() => {
    let rowsData: RowParams["row"][] | undefined = data?.policies
      ?.map((policy, index) => {
        const cpuHeadroom = policy.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.cpu?.headroomPercentage;
        const memoryHeadroom =
          policy.spec?.policyOptimize?.rightSizePolicy?.requestsConfigs?.memory?.headroomPercentage;

        return {
          id: index,
          isScheduled: policy.spec?.type === SCHEDULED_POLICY,
          name: String(policy.metadata?.name),
          cpuWindow: policy.spec?.policyOptimize?.rightSizePolicy?.windowByResource?.cpu,
          memoryWindow: policy.spec?.policyOptimize?.rightSizePolicy?.windowByResource?.memory,
          cpuHeadroom: cpuHeadroom ? `${cpuHeadroom}%` : undefined,
          memoryHeadroom: memoryHeadroom ? `${memoryHeadroom}%` : undefined,
          scaleUpCpu: `${Number((policyMinChangeScaleUpCpu(policy) * 100).toFixed(2))}%`,
          scaleDownCpu: `${Number((policyMinChangeScaleDownCpu(policy) * 100).toFixed(2))}%`,
          stabilizationWindowScaleUp: `${
            Math.round((policyStabilizationScaleUpWindow(policy) / 60 / 60) * 100) / 100
          }h`,
          stabilizationWindowScaleDown: `${
            Math.round((policyStabilizationScaleDownWindow(policy) / 60 / 60) * 100) / 100
          }h`,
          rowPolicyData: policy,
          isBuiltIn: isBuiltInPolicy(policy),
        };
      })
      ?.sort((a, b) => {
        if (a.name === PolicyType.Production && b.name !== PolicyType.Production) return -1;
        if (a.name !== PolicyType.Production && b.name === PolicyType.Production) return 1;
        if (a.name === PolicyType.HighAvailability && b.name !== PolicyType.HighAvailability) return -1;
        if (a.name !== PolicyType.HighAvailability && b.name === PolicyType.HighAvailability) return 1;
        if (a.name === PolicyType.Hibernate && b.name !== PolicyType.Hibernate) return -1;
        if (a.name !== PolicyType.Hibernate && b.name === PolicyType.Hibernate) return 1;
        return 1;
      });

    // show default policies first
    rowsData = rowsData?.sort((a, b) => {
      if (a?.isBuiltIn && !b?.isBuiltIn) return -1;
      if (!a?.isBuiltIn && b?.isBuiltIn) return 1;
      return 0;
    });

    // show production policy first
    rowsData = rowsData?.sort((a, b) => {
      if (a.name === DEFAULT_POLICY_NAME && b.name !== DEFAULT_POLICY_NAME) return -1;
      if (a.name !== DEFAULT_POLICY_NAME && b.name === DEFAULT_POLICY_NAME) return 1;
      return 0;
    });

    // hide hpa policy if FS is off
    if (!enableHpaOptimization) {
      rowsData = rowsData?.filter((row) => row.name !== PolicyType.ProductionHpa);
    }

    setRows(rowsData ?? []);
  }, [data]);

  useEffect(() => {
    if (rows && rows.length > 0 && policyToOpenOnInitialLoading) {
      const policyToOpen = rows.find((row) => row.name === policyToOpenOnInitialLoading);

      if (policyToOpen) {
        setSelectedPolicy(policyToOpen.rowPolicyData);
        setIsEditPolicyDrawerOpen(true);
      }
      setPolicyToOpenOnInitialLoading(undefined);
    }
  }, [rows, policyToOpenOnInitialLoading]);

  if (isLoading)
    return (
      <div className={wrapperClassName}>
        <Loading />
      </div>
    );

  if (error) {
    console.log("error fetching policies table:", error);
    return null;
  }

  let usePolicies: string[] | undefined = [];

  if (byNamespaceData) {
    usePolicies = byNamespaceData.workloads?.map((entity) => entity.policyName);
    usePolicies = [...new Set(usePolicies)];
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Policy name",
      flex: 2,
      renderCell: (params: RowParams) => (
        <PolicyName
          policyName={params.row.name}
          isBuiltIn={params.row.isBuiltIn}
          isScheduled={params.row.isScheduled}
        />
      ),
    },
    {
      field: "id",
      headerName: "Used by workloads",
      width: 400,
      sortable: false,
      headerAlign: isReadyOnlyFrontEnd ? "center" : undefined,
      renderCell: (params: RowParams) => {
        const policyName = params.row.name;
        const usageCount = policiesStats.usageCount?.[policyName] || 0;
        const usagePercentage = policiesStats.usagePercentage?.[policyName] || 0;
        const potentialCount = policiesStats.potentialCount?.[policyName] || 0;
        const potentialPercentage = policiesStats.potentialPercentage?.[policyName] || 0;
        const totalWorkloadCount = policiesStats.totalWorkloadsCount || 0;
        const link = `/?smartPolicy=${policyName}&notUsingSmartPolicy=1`;
        const tooltipText = (
          <div className={"flex flex-col"}>
            <div>
              <b>{policyName}</b> is used by <b>{usagePercentage}%</b> of all workloads.
            </div>
            {!!potentialCount && (
              <div className={"flex flex-col"}>
                <br />
                <b>
                  {/*<u>*/}
                  <div className={"flex gap-1"}>
                    <span className={"flex gap-0.5 text-guideline-darkPurple w-fit items-center border-b-2"}>
                      <MagicWand height={12} width={12} className="-mt-1" />
                      <span className={"w-fit"}>Auto Policy Detection</span>
                    </span>
                  </div>
                  {/*</u>*/}
                </b>
                <span className={"flex gap-1 flex-wrap "}>
                  {" "}
                  Set <b>{potentialCount}</b> more workload{potentialCount > 1 ? "s" : ""} to use <b>{policyName}</b>{" "}
                  policy
                </span>
                <br />
                <Typography
                  variant={"caption"}
                  className={"self-end"}
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  <a href={link}>
                    <span className={"flex gap-1 items-center text-guideline-darkPurple"}>
                      Explore workload{potentialCount > 1 ? "s" : ""} <RightArrowIcon width={14} height={14} />
                    </span>
                  </a>
                </Typography>
              </div>
            )}
          </div>
        );

        return (
          <Tooltip className="flex flex-col items-center justify-center w-full mt-5" title={tooltipText} maxWidth={800}>
            <div className="w-full flex justify-center">
              <PercentageMeter percentage={usagePercentage} additionalPercentage={potentialPercentage} />
            </div>
            {policiesStats.totalWorkloadsCount > 0 && (
              <Typography variant="body2" className="w-full text-black flex justify-center gap-2">
                {numeral(usageCount).format("0,0")} of {numeral(totalWorkloadCount).format("0,0")}{" "}
                {!!potentialCount && (
                  <Typography variant="caption" className={"flex items-center"}>
                    {"("}
                    <MagicWand height={12} width={12} className={"mr-1 -mt-1"} />+
                    {numeral(potentialCount).format("0,0")} suggested{")"}
                  </Typography>
                )}
              </Typography>
            )}
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      hide: isReadyOnlyFrontEnd,
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params: RowParams) => {
        const isPolicyUsed = usePolicies?.includes(params.row.name);
        return (
          <PolicyActionCell
            onEditClickNew={() => {
              setSelectedPolicy(params.row.rowPolicyData);
              setIsEditPolicyDrawerOpen(true);
            }}
            onEditClick={() => {
              openEditPolicyDialog(params.row.rowPolicyData);
            }}
            handleDelete={() => {
              deletePolicy.mutate({ policyName: params.row.name });
            }}
            handleDuplicateClick={() => {
              setSelectedCheckbox(params.row.name);
              handleDuplicateClick(params.row.rowPolicyData);
            }}
            isCustomizedPolicy={!params.row?.isBuiltIn}
            isHibernate={params.row.rowPolicyData.spec?.type === "Schedule"}
            isDeletablePolicy={!isPolicyUsed}
            actionsDisabled={userRole !== ADMIN_ROLE}
          />
        );
      },
    },
  ];
  let buttonTooltip = <YouHaveReadOnlyAccess />;
  if (!isReadyOnlyFrontEnd && userRole !== ADMIN_ROLE) {
    buttonTooltip = (
      <Typography variant="caption">
        You don't have <b>permission to create</b> new policy
      </Typography>
    );
  }
  return (
    <div className={clsx(wrapperClassName, "flex flex-col gap-8")}>
      <div className="flex justify-between h-full">
        <div>
          <Typography variant="h6">Policies management</Typography>
          <Typography className="max-w-[620px]" sx={{ fontSize: 12 }}>
            Manage your policies and <b>customize them to suit your needs</b>.
            <br />
            Define the number of days the recommendation should be based on, the request and limit headrooms and the
            continuous automation strategy and much more.
          </Typography>
        </div>
        <div className="self-end">
          <Tooltip title={buttonTooltip} disabled={!(isReadyOnlyFrontEnd || userRole !== ADMIN_ROLE)} maxWidth={500}>
            <Button
              onClick={openCreateNewPolicyDialog}
              label="Create new policy"
              fontSize={12}
              disabled={isReadyOnlyFrontEnd || userRole !== ADMIN_ROLE}
            />
          </Tooltip>
        </div>
      </div>
      <div className="w-full">
        <DataGrid
          rows={rows ?? []}
          columns={columns}
          hideFooter
          autoHeight
          disableSelectionOnClick
          disableColumnMenu
          headerHeight={HEADER_HEIGHT}
          rowHeight={110}
          sx={getDataGridSx()}
          onRowClick={(params: RowParams) => {
            if (params.row.rowPolicyData.spec?.type === "Schedule") {
              openEditPolicyDialog(params.row.rowPolicyData);
            } else {
              setSelectedPolicy(params.row.rowPolicyData);
              setIsEditPolicyDrawerOpen(true);
            }
          }}
          getRowClassName={(row: GridRowClassNameParams<RowParams["row"]> | undefined) => {
            if (row?.row?.isBuiltIn) return "cursor-pointer automatedRow";

            return "cursor-pointer";
          }}
        />
      </div>
      <EditPolicyDrawer
        rowPolicyData={selectedPolicy}
        isEditPolicyDrawerOpen={isEditPolicyDrawerOpen}
        setIsEditPolicyDrawerOpen={setIsEditPolicyDrawerOpen}
        defaultPolicy={data?.policies?.find((p) => p.metadata?.name === DEFAULT_POLICY_NAME)}
      />
    </div>
  );
};

export default PoliciesContainer;
