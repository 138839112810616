import { IconProps } from "./utils/utils";

const HealthIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M10 6.10352e-05C4.48193 6.10352e-05 0 4.48199 0 10.0001C0 15.5181 4.48193 20.0001 10 20.0001C15.5181 20.0001 20 15.5181 20 10.0001C20 4.48199 15.5181 6.10352e-05 10 6.10352e-05ZM10 19.1326C4.96386 19.1326 0.86747 15.0362 0.86747 10.0001C0.86747 4.96392 4.96386 0.867531 10 0.867531C15.0361 0.867531 19.1325 4.96392 19.1325 10.0001C19.1325 15.0362 15.0361 19.1326 10 19.1326Z" />
      <path d="M15.2048 7.83139H12.1687V4.79525C12.1687 4.55428 11.9759 4.36151 11.7349 4.36151H8.26506C8.0241 4.36151 7.83133 4.55428 7.83133 4.79525V7.83139H4.79518C4.55422 7.83139 4.36145 8.02416 4.36145 8.26513V11.735C4.36145 11.976 4.55422 12.1687 4.79518 12.1687H7.83133V15.2049C7.83133 15.4458 8.0241 15.6386 8.26506 15.6386H11.7349C11.9759 15.6386 12.1687 15.4458 12.1687 15.2049V12.1687H15.2048C15.4458 12.1687 15.6386 11.976 15.6386 11.735V8.26513C15.6145 8.02416 15.4217 7.83139 15.2048 7.83139ZM14.7952 11.3013H14.7711H11.7349C11.494 11.3013 11.3012 11.494 11.3012 11.735V14.7711H8.6988V11.735C8.6988 11.494 8.50603 11.3013 8.26506 11.3013H5.22892V8.69886H8.26506C8.50603 8.69886 8.6988 8.50609 8.6988 8.26513V5.22898H11.3253V8.26513C11.3253 8.50609 11.5181 8.69886 11.759 8.69886H14.7952V11.3013Z" />
    </svg>
  );
};

export default HealthIcon;
