import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import {
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE,
} from "./GridColumnsUtils";
import { Typography } from "@mui/material";
import Tooltip from "../../Tooltip";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import RightArrowIcon from "../../../Icons/RightArrowIcon";
import UpAndDownCircleIcon from "../../../Icons/UpAndDownCircleIcon";

interface MinReplicasDiffColumnProps {
  hpaOptimizationEnabled: boolean | undefined;
}

export default function MinReplicasDiffColumn({
  params,
  hpaOptimizationEnabled,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
} & MinReplicasDiffColumnProps) {
  if (isUnallocatedRow(params.row)) return null;
  const hpaOptimizationReady = hpaOptimizationEnabled && params.row.hasHpa && params.row.isReadyRecommendation == true;
  return (
    <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
      <Tooltip
        title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
        maxWidth={400}
        className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
        enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
        disabled={hpaOptimizationReady}
      >
        <div className="flex justify-end items-center gap-[.625rem]">
          <span>{params.row.minReplicas ?? params.row.replicas}</span>
          {hpaOptimizationReady && (
            <>
              <RightArrowIcon width={10} height={10} />
              <span className="text-guideline-darkGreen">
                {params.row.recommendedMinReplicas ?? params.row.minReplicas ?? params.row.replicas}
              </span>
              <UpAndDownCircleIcon width={14} height={14} />
            </>
          )}
        </div>
      </Tooltip>
    </Typography>
  );
}
