import { GetSimpleAuthResponse } from "../../../api/fetcher";
import { Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getDataGridSx } from "../../../utils/styleUtils";
import { useState } from "react";
import { toast } from "react-toastify";
import AsteriskIcon from "../../../Icons/AsteriskIcon";
import ShowIcon from "../../../Icons/ShowIcon";
import HideIcon from "../../../Icons/HideIcon";
import DuplicateIcon from "../../../Icons/DuplicateIcon";
import { getRoleDescription } from "./utils";

const ICON_CLASS_NAME = "hover:text-main-linkBlue";

interface Props {
  simpleAuthData: GetSimpleAuthResponse | undefined;
  isLoading: boolean;
}

const SimpleAuthUsersTable = ({ simpleAuthData, isLoading }: Props) => {
  return (
    <div className="max-w-full">
      <DataGrid
        sx={{
          ...getDataGridSx(),
        }}
        rowHeight={100}
        headerHeight={40}
        style={{ height: "100%" }}
        hideFooter={true}
        columns={[
          {
            field: "name",
            headerName: "Username",
            flex: 1,
            maxWidth: 200,
            type: "string",
            align: "left",
            sortable: false,
            cellClassName: "text-text-lightBlack",
          },
          {
            field: "password",
            headerName: "Password",
            flex: 1,
            maxWidth: 250,
            type: "string",
            align: "left",
            sortable: false,
            renderCell: (params) => {
              const [showPassword, setShowPassword] = useState(false);

              return (
                <div className="flex items-center gap-2 w-full">
                  <Typography variant="body2" className="grow">
                    {showPassword ? (
                      <span
                        onClick={(e: React.MouseEvent) => {
                          if (e.detail === 2 && params.value) {
                            navigator.clipboard.writeText(String(params.value));
                            toast.success("Password copied to clipboard");
                          }
                        }}
                      >
                        {params.value}
                      </span>
                    ) : (
                      <div className="flex items-center gap-1">
                        <AsteriskIcon width={10} height={10} />
                        <AsteriskIcon width={10} height={10} />
                        <AsteriskIcon width={10} height={10} />
                        <AsteriskIcon width={10} height={10} />
                        <AsteriskIcon width={10} height={10} />
                      </div>
                    )}
                  </Typography>
                  <button
                    className="text-primary-400"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <ShowIcon className={ICON_CLASS_NAME} /> : <HideIcon className={ICON_CLASS_NAME} />}
                  </button>
                  <button
                    className="text-primary-400"
                    onClick={() => {
                      navigator.clipboard.writeText(String(params.value));
                      toast.success("Password copied to clipboard");
                    }}
                  >
                    <DuplicateIcon className={ICON_CLASS_NAME} />
                  </button>
                </div>
              );
            },
          },
          {
            field: "role",
            headerName: "Description",
            renderCell: (params) => {
              const roleDescription = getRoleDescription(String(params.value ?? ""));
              return (
                <div className="text-text-lightBlack w-full line-clamp-3 whitespace-normal">{roleDescription}</div>
              );
            },
            flex: 3,
            type: "string",
            align: "left",
            sortable: false,
          },
        ]}
        rows={
          simpleAuthData?.users?.map((user, i) => ({
            id: user?.id ?? `${user?.username ?? ""}-${i}`,
            name: user.username,
            password: user.password,
            role: user.role,
          })) || []
        }
        hideFooterPagination={true}
        autoHeight={true}
        disableSelectionOnClick
        disableColumnMenu
        loading={isLoading}
      />
    </div>
  );
};

export default SimpleAuthUsersTable;
