export const getDisabledByKeepRequestTooltipContent = (type: "CPU" | "memory") => (
  <>
    Disable <b>"Keep original {type} request"</b> to enable this field.
  </>
);

export const HPA_SECTION_CLASS_NAME = "flex flex-col gap-2 py-10 border-b border-strongBorder";
export const HAP_INPUT_CLASS_NAME = "flex flex-wrap w-fit";

export const optimizationStrategyItems = {
  both: { displayValue: "Both", value: "allWorkloadsWithHpa" },
  predictable: { displayValue: "Predictable", value: "predictable" },
  general: { displayValue: "General", value: "maxValueMinReplicas" },
  none: { displayValue: "None", value: "none" },
};
