import { GetWorkloadNetworkResponse } from "../../../../api/fetcher";
import PartialBorders from "../../../../components/PartialBorders";
import Tooltip from "../../../../components/Tooltip";
import InfoIcon from "../../../../Icons/InfoIcon";
import RunningNumberBox from "../../TopOverviewSection/RunningNumberBox";

interface MetricTitleProps {
  title: string;
}

const MetricTitle = ({ title }: MetricTitleProps) => (
  <Tooltip
    title={
      <>
        The workloads's <b>{title.toLowerCase()}</b>
      </>
    }
    className="w-max flex items-center gap-1"
    maxWidth={450}
  >
    <span>{title}</span>
    <InfoIcon width={14} height={14} />
  </Tooltip>
);

interface TopMetricsProps {
  data: GetWorkloadNetworkResponse;
  isLoading?: boolean;
}

const WRAPPER_CLASS_NAME = "w-full h-full";

const TopMetrics = ({ data, isLoading }: TopMetricsProps) => {
  return (
    <div className="border rounded-lg border-border w-full p-4 flex items-center relative h-[150px]">
      <div className={WRAPPER_CLASS_NAME}>
        <PartialBorders right>
          <RunningNumberBox
            title={<MetricTitle title="Total network cost" />}
            value={data.totalCost?.total || 0}
            prefix="$"
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            isLoading={isLoading}
          />
        </PartialBorders>
      </div>
      <div className={WRAPPER_CLASS_NAME}>
        <PartialBorders>
          <RunningNumberBox
            title={<MetricTitle title="Cross-AZ cost" />}
            value={data.crossAZCost?.total || 0}
            prefix="$"
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            isLoading={isLoading}
          />
        </PartialBorders>
      </div>
      {/* <div className={WRAPPER_CLASS_NAME}>
        <PartialBorders>
          <RunningNumberBox
            title={<MetricTitle title="Intra-AZ cost" />}
            value={data.intraAZCost?.total || 0}
            prefix="$"
            numberVariant="h4"
            numberClassName="text-text-lightBlack"
            isLoading={isLoading}
          />
        </PartialBorders>
      </div> */}
    </div>
  );
};

export default TopMetrics;
