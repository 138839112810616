import * as policyUtils from "../../../utils/policyUtils";
import { components } from "../../../api/schema";
import FormSingleSelect from "../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../utils";

export enum LimitStrategyItems {
  KeepLimit = "Keep original limit",
  SetLimit = "Set limit",
  NoLimit = "No limit",
  EqualsToRequest = "Same as request",
}

const getOptions = (): [string, string][] => Object.entries(LimitStrategyItems).map((entity) => [entity[1], entity[1]]);

interface Props {
  isCustomizedPolicy?: boolean;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
}

const LimitsStrategy = ({ isCustomizedPolicy, defaultPolicy }: Props) => {
  const policyStrategyOptions = getOptions();

  const defaultLimitStrategyCpu = isCustomizedPolicy ? policyUtils.policyCpuLimitStrategy(defaultPolicy) : undefined;

  const defaultLimitStrategyMemory = isCustomizedPolicy
    ? policyUtils.policyMemoryLimitStrategy(defaultPolicy)
    : undefined;

  return (
    <div className="flex flex-wrap gap-4">
      <FormTitleAndDescription title="Limit strategy" description="Define the strategy for defining limits." />
      <div className="flex gap-4 w-fit relative">
        <FormSingleSelect
          label="CPU"
          name="limitStrategyCpu"
          options={policyStrategyOptions}
          defaultValue={defaultLimitStrategyCpu}
          areOptionsDisabled={!isCustomizedPolicy}
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        />
        <FormSingleSelect
          label="Memory"
          name="limitStrategyMemory"
          defaultValue={defaultLimitStrategyMemory}
          options={policyStrategyOptions}
          areOptionsDisabled={!isCustomizedPolicy}
          disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        />
      </div>
    </div>
  );
};
export default LimitsStrategy;
