import { ListItemText, MenuItem } from "@mui/material";
import SingleSelect from "../../../components/SingleSelect";

export enum ViewPeriodOptions {
  "30 minutes" = "0.5",
  "1 hour" = "1",
  "4 hours" = "4",
  "12 hours" = "12",
  "1 day" = "24",
  "2 days" = "48",
  "4 days" = "96",
  "7 days" = "168",
  "2 weeks" = "336",
}

interface Props {
  selectedViewPeriod: string;
  setSelectedViewPeriod: (option: string) => void;
}

const SelectViewPeriod = ({ selectedViewPeriod, setSelectedViewPeriod }: Props) => {
  //const [urlDates, setUrlDates] = useQueryParam(NODE_OVERVIEW_DATES_URL_PARAM, ObjectParam);

  return (
    <SingleSelect<string>
      label="View period"
      className="w-[150px]"
      selected={selectedViewPeriod || ViewPeriodOptions["1 day"]}
      setSelected={(option) => {
        setSelectedViewPeriod(option);
        // setUrlDates(undefined);
      }}
      renderOptionsFunc={() =>
        Object.entries(ViewPeriodOptions).map((option) => {
          return (
            <MenuItem value={option[1]} key={option[1]}>
              <ListItemText primary={option[0]} />
            </MenuItem>
          );
        })
      }
    />
  );
};

export default SelectViewPeriod;
