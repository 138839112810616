import { Typography } from "@mui/material";
import React from "react";
import { SCALEOPS_COLORS } from "../../colors";
import ScaleopsIcon from "../../Icons/ScaleopsIcon";
import WorkloadTooltip from "../../utils/workloadTooltip";
import Tooltip from "../Tooltip";

interface Props {
  entityName: string | number;
  workloadName?: string;
  namespace?: string;
  entityType?: string;
  disabled?: boolean;
  message?: string;
}

const ExploreEntityTooltip: React.FC<Props> = ({
  entityName,
  workloadName,
  namespace,
  entityType,
  disabled,
  message = "Explore workload overview",
}) => {
  return (
    <Tooltip
      title={
        <div className={"flex flex-col gap-0.5"}>
          <WorkloadTooltip
            namespace={namespace}
            workloadName={workloadName ?? (entityName as string)}
            type={entityType}
          />
          {
            <div className="flex gap-1 mt-2" style={{ color: SCALEOPS_COLORS.primary.purpleBlue }}>
              <ScaleopsIcon width={10} height={10} className="mt-0.5" /> <small>{message}</small>
            </div>
          }
        </div>
      }
      maxWidth={500}
      disabled={disabled}
      className="w-full"
    >
      <Typography
        variant="body2"
        fontWeight={500}
        data-testid="workload-name"
        sx={{ whiteSpace: "normal" }}
        className="line-clamp-3 break-words"
      >
        {entityName}
      </Typography>
    </Tooltip>
  );
};

export default ExploreEntityTooltip;
