export enum AvailableSavingsFilter {
  Positive = "Positive",
}

export enum FilterByUrlParam {
  ENABLE_OPTIMIZATION_GAPS_FILTER = "ENABLE_OPTIMIZATION_GAPS_FILTER",
  ENABLE_SPOT_FRIENDLY_FILTER = "ENABLE_SPOT_FRIENDLY_FILTER",
  ENABLE_HPA_RECOMMENDATION = "ENABLE_HPA_RECOMMENDATION",
  ENABLE_HPA_CUSTOMER_VIEW = "ENABLE_HPA_CUSTOMER_VIEW",
}

export const enableFilterByUrlParam = (filter: FilterByUrlParam) => {
  if (sessionStorage.getItem("snapshot-server")) {
    return (
      filter !== FilterByUrlParam.ENABLE_HPA_RECOMMENDATION && filter !== FilterByUrlParam.ENABLE_HPA_CUSTOMER_VIEW
    );
  }
  // get from page url address the optimization gaps filter
  const urlParams = new URLSearchParams(window.location.search);
  const res = urlParams.get(filter.toString());
  return res == "true";
};
