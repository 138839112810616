import ClearIcon from "@mui/icons-material/Clear";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  GetBetaFeaturesQuery,
  GetBetaFeaturesResponse,
  GetIgnoredNamespaces,
  GetIgnoredNamespacesResponse,
  getNamespaces,
  GetNamespacesResponse,
} from "../../api/fetcher";
import { HAS_HPA_ENABLED } from "../../utils/developmentFeatureFlags";
import Button from "../Button";
import CustomSelectedFilterChip from "../CustomSelectedFilterChip";
import FormSwitch from "../formComponents/FormSwitch";
import FormTitleAndDescription from "../formComponents/FormTitleAndDescription";
import MultiSelect from "../MultiSelect";
import useIgnoreNamespaces from "./useIgnoreNamespaces";
import useSaveBetaFeatures from "./useSaveBetaFeatures";

interface Props {
  disabled?: boolean;
}

const { queryKey: betaFeaturesQueryKey, queryFn: betaFeaturesQueryFn } = GetBetaFeaturesQuery();
const { queryKey: ignoreNamespacesQueryKey, queryFn: ignoreNamespacesQueryFn } = GetIgnoredNamespaces();

const GeneralSettingsTab = ({ disabled }: Props) => {
  const betaFeatures = useSaveBetaFeatures();
  const ignoreNamespaces = useIgnoreNamespaces();
  const namespaces = getNamespaces();
  const [selectedNamespaceFilters, setSelectedNamespaceFilters] = useState<string[]>([]);

  const { data: nsData } = useQuery<GetNamespacesResponse, Error>([namespaces.queryKey], () => namespaces.queryFn({}));

  const { data: betaFeaturesData, isLoading: betaFeaturesIsLoading } = useQuery<GetBetaFeaturesResponse, Error>({
    queryKey: [betaFeaturesQueryKey, "for-general-settings"],
    queryFn: betaFeaturesQueryFn,
  });

  const { data: ignoreNamespacesData, isLoading: ignoreNamespacesIsLoading } = useQuery<
    GetIgnoredNamespacesResponse,
    Error
  >({
    queryKey: [ignoreNamespacesQueryKey, "for-ignored-ns"],
    queryFn: ignoreNamespacesQueryFn,
  });

  const namespacesOptions = [
    ...new Set(
      Object.values(nsData?.namespaces || [])
        .map((namespace) => namespace?.metadata?.name || "")
        .concat(selectedNamespaceFilters)
        .sort((a, b) => a.localeCompare(b))
    ),
  ];

  if (betaFeaturesIsLoading || ignoreNamespacesIsLoading) {
    return (
      <div className="bg-white flex items-center justify-center w-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        betaFeaturesEnabled: !!betaFeaturesData?.enabled,
        hpaOptimization: !!betaFeaturesData?.betaFeatures?.hpaOptimization,
        nodeCapacityConfiguration: !!betaFeaturesData?.betaFeatures?.nodeCapacityConfiguration,
        nodeConsolidation: !!betaFeaturesData?.betaFeatures?.nodeConsolidation,
        namespaces: selectedNamespaceFilters,
      }}
      onSubmit={(values) => {
        const isBetaFeaturesChanged =
          betaFeaturesData?.betaFeatures &&
          (values.nodeCapacityConfiguration !== betaFeaturesData.betaFeatures.nodeCapacityConfiguration ||
            values.nodeConsolidation !== betaFeaturesData.betaFeatures.nodeConsolidation);
        if (isBetaFeaturesChanged) {
          betaFeatures.mutate({
            enabled: !!values.hpaOptimization || !!values.nodeCapacityConfiguration || !!values.nodeConsolidation,
            betaFeatures: {
              hpaOptimization: !!values.hpaOptimization,
              nodeCapacityConfiguration: !!values.nodeCapacityConfiguration,
              nodeConsolidation: !!values.nodeConsolidation,
            },
          });
        }

        const isNsListChanged =
          selectedNamespaceFilters.length !== ignoreNamespacesData?.namespaces?.length ||
          selectedNamespaceFilters.some((value, index) => value !== ignoreNamespacesData?.namespaces?.[index]);
        if (isNsListChanged) {
          ignoreNamespaces.mutate({
            namespaces: selectedNamespaceFilters,
          });
        }
      }}
    >
      {(formik) => {
        const { values } = formik;
        const isDisabled = disabled || !values.betaFeaturesEnabled;

        useEffect(() => {
          setSelectedNamespaceFilters(ignoreNamespacesData?.namespaces || []);
        }, []);
        return (
          <Form className="flex flex-col gap-10 pt-4">
            <div className="pb-10 border-b w-[70vw]">
              <div className="flex items-center py-4">
                <FormTitleAndDescription
                  title="Beta Features"
                  description="Explore ScaleOps new functionality by enabling one or more of the following features."
                />
              </div>
              {/* <div className="flex items-center">
                <FormSwitch
                  name="betaFeaturesEnabled"
                  disabled={disabled}
                  secondaryOnChange={(value: boolean) => {
                    values.hpaOptimization = value;
                    values.nodeCapacityConfiguration = value;
                    values.nodeConsolidation = value;
                  }}
                />
                <Typography variant="body2">Enable Beta Features</Typography>
              </div> */}
              {HAS_HPA_ENABLED && (
                <div className="flex items-center">
                  <FormSwitch name="hpaOptimization" disabled={isDisabled} className="ml-[-9px]" />
                  <Typography variant="body2">HPA optimization</Typography>
                </div>
              )}
              <div className="flex items-center">
                <FormSwitch name="nodeCapacityConfiguration" disabled={isDisabled} className="ml-[-9px]" />
                <Typography variant="body2">Cluster headroom</Typography>
              </div>
              <div className="flex items-center">
                <FormSwitch name="nodeConsolidation" disabled={isDisabled} className="ml-[-9px]" />
                <Typography variant="body2">Node consolidation</Typography>
              </div>
            </div>
            <div className="flex flex-col gap-3 py-4">
              <FormTitleAndDescription
                title="Ignored Namespaces"
                description="Select namespaces to exclude from the platform. These namespaces will be ignored for all operations and will not be visible to users."
              />
              <div className="flex py-3 h-[50px]">
                <MultiSelect
                  isSearchable
                  hasVirtualizedList
                  label={<span className="text-[14px]">Namespaces</span>}
                  wrapperClassName="w-[220px]"
                  className="w-[220px]"
                  selected={selectedNamespaceFilters}
                  setSelected={(options) => {
                    setSelectedNamespaceFilters(options as string[]);
                  }}
                  options={namespacesOptions ?? []}
                  fontSize="12px"
                  renderValue={(selected) => `Selected (${selected.length}) `}
                  disableTooltip
                  disabled={disabled}
                />
              </div>
              <Box
                component="main"
                my={3}
                sx={{
                  width: "70vw",
                  maxHeight: "140px",
                  borderBottom: "1px solid #e0e0e0",
                  overflow: "auto",
                  padding: "8px 0",
                }}
              >
                {selectedNamespaceFilters?.map((ns) => (
                  <div className="inline-grid">
                    <CustomSelectedFilterChip
                      className="max-w-[200px] truncate bg-text-lightBlack hover:bg-strongerBorder text-[#fff]"
                      label={<>{ns ?? ""}</>}
                      onClick={() => {
                        if (disabled) return;
                        setSelectedNamespaceFilters((s) => s?.filter((l) => l !== ns));
                      }}
                      tooltipContent={<>namespaces "{ns ?? ""}".</>}
                      key={`ns (${ns})`}
                      hasTooltip
                      clearIcon={<ClearIcon sx={{ fontSize: 15, padding: "0px", color: "whitesmoke" }} />}
                    />
                  </div>
                ))}
              </Box>
            </div>
            <div className="flex py-1 w-full justify-end">
              <Button type="submit" label="Save" disabled={disabled} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default GeneralSettingsTab;
