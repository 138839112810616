import { Dayjs } from "dayjs";
import { DEFAULT_DATE_TIME_FORMAT } from "../utils/formatterUtils";
import ChartTooltipTime from "./ChartTooltipTime";
import React from "react";

interface Props {
  timestamp: string | number | Dayjs;
  timeFormat?: string;
  valueTitle?: string;
}

const ChartTooltipTitle = ({ timestamp, timeFormat = DEFAULT_DATE_TIME_FORMAT, valueTitle }: Props) => {
  return (
    <span className="flex justify-between">
      {timestamp && <ChartTooltipTime timestamp={timestamp} timeFormat={timeFormat} />}
      {valueTitle && (
        <span className="text-[10px]" style={{ fontWeight: 600 }}>
          {valueTitle}
        </span>
      )}
    </span>
  );
};

export default ChartTooltipTitle;
