import { DataGrid, GridColDef, GridRenderCellParams, GridSortDirection } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { GetCostReportWorkloads, GetCostReportWorkloadsResponse } from "../../../../api/fetcher";
import { components } from "../../../../api/schema";
import useHpaOptimizationEnabled from "../../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import { ASC, DESC } from "../../../../components/WorkloadStatusByNamespace/utils";
import WorkloadNameRow from "../../../../components/WorkloadStatusByNamespace/WorkloadNameRow";
import { getDataGridSx, HEADER_HEIGHT, ROW_HEIGHT } from "../../../../utils/styleUtils";
import useStateWithLocalStorage from "../../../../utils/useStateWithLocalStorage";
import { PolicyTuningTabs } from "../../../Overview/PolicyTuning/utils";
import { Field, getSharedColumns, getSortedColumn } from "../utils";
import ExportWorkloadsCSV from "./ExportWorkloadsCSV";
import useWorkloadsFilters from "./hooks/useWorkloadsFilters";
import { WORKLOAD_ROW } from "./utils";

const COST_PAGE_SIZE_LOCAL_STORAGE_KEY = "costPageSize";

const { queryKey, queryFn } = GetCostReportWorkloads();

const INITIAL_SORT_MODEL = [
  {
    field: String(Field.totalCost),
    sort: DESC as GridSortDirection,
  },
];

const getColumns = (selectedColumns: (string | undefined)[]): GridColDef[] => [
  {
    field: Field.workloadName,
    headerName: "Workload",
    hide: !selectedColumns.includes(Field.workloadName),
    flex: 3,
    minWidth: 150,
    type: "string",
    align: "left",
    disableColumnMenu: true,
    sortable: true,
    renderCell: (
      params: GridRenderCellParams<
        components["schemas"]["UtilsWorkloadCostReport"],
        components["schemas"]["UtilsWorkloadCostReport"]
      >
    ) => (
      <WorkloadNameRow
        name={params.row.workloadName}
        namespace={params.row.namespace}
        type={params.row.workloadType}
        cluster={params.row.clusterName}
        targetTab={PolicyTuningTabs.Resources}
      />
    ),
  },
  ...getSharedColumns(selectedColumns),
];

interface Props {
  selectedColumns: (string | undefined)[];
}

const WorkloadsTable = ({ selectedColumns }: Props) => {
  const filters = useWorkloadsFilters();

  const [rows, setRows] = useState<WORKLOAD_ROW[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowCount, setRowCount] = useState<number>(0);
  const [sortField, setSortField] = useQueryParam("sortField", withDefault(StringParam, String(Field.totalCost)));
  const [sortDirection, setSortDirection] = useQueryParam("sortDirection", withDefault(StringParam, DESC));
  const [isInitialSortModel, setIsInitialSortModel] = useState<boolean>(true);
  const [pageSize, setPageSize] = useStateWithLocalStorage<number>({
    localStorageKey: COST_PAGE_SIZE_LOCAL_STORAGE_KEY,
    defaultValue: 25,
    valueFormatter: (value) => parseInt(value),
  });
  const enableHpaOptimization = useHpaOptimizationEnabled();

  const [searchTerm] = useQueryParam("searchTerms", StringParam);

  const { data, isLoading, error, isError } = useQuery<GetCostReportWorkloadsResponse, Error>({
    queryKey: [queryKey, filters],
    queryFn: () =>
      queryFn({
        ...filters,
        multiCluster: true,
      }),
  });

  useEffect(() => {
    let sortedData = data
      ? data?.aggregatedWorkloads?.map((wl) => {
          return {
            ...wl,
            displayWorkloadName: `${wl.namespace}/${wl.workloadName}`,
            savingsAvailable: enableHpaOptimization ? wl.savingsAvailableWithReplicas : wl.savingsAvailable,
          };
        })
      : [];

    // filter by searchTerm
    if (searchTerm) {
      sortedData = sortedData?.filter((wl) => {
        return wl.displayWorkloadName.includes(searchTerm);
      });
    }

    // sort by displayWorkloadName if sortField is workloadName
    if (sortField && sortDirection) {
      if (sortField === Field.workloadName) {
        sortedData = sortedData.sort((a, b) => {
          if (sortDirection === ASC) {
            return a.displayWorkloadName.localeCompare(b.displayWorkloadName);
          } else {
            return b.displayWorkloadName.localeCompare(a.displayWorkloadName);
          }
        });
      }
    }

    sortedData = getSortedColumn({ sortField, sortDirection, sortedData });

    sortedData = sortedData?.slice(page * pageSize, (page + 1) * pageSize) ?? [];

    setRows(sortedData);
    setRowCount(sortedData.length);
  }, [data, page, pageSize, sortField, sortDirection, searchTerm]);

  if (isError) {
    console.log("Error fetching data: ", error);
  }

  return (
    <div className="flex flex-col gap-3 relative">
      <DataGrid
        pagination={true}
        headerHeight={HEADER_HEIGHT}
        autoHeight
        rowHeight={ROW_HEIGHT}
        sx={{ ...getDataGridSx(false, false) }}
        columns={getColumns(selectedColumns)}
        rows={rows}
        initialState={{
          pagination: {
            pageSize: pageSize,
          },
        }}
        pageSize={pageSize}
        onPageChange={(newPage) => {
          setPage(newPage);
        }}
        onPageSizeChange={(newPageSize) => {
          setPageSize(newPageSize);
        }}
        loading={isLoading}
        experimentalFeatures={{ newEditingApi: true }}
        paginationMode="server"
        sortingMode="server"
        onSortModelChange={(x) => {
          if (isInitialSortModel) setIsInitialSortModel(false);
          setSortField(x[0]?.field);
          setSortDirection(x[0]?.sort);
        }}
        sortModel={
          isInitialSortModel && !sortField && !sortDirection
            ? INITIAL_SORT_MODEL
            : [
                {
                  field: String(sortField),
                  sort: String(sortDirection) as GridSortDirection,
                },
              ]
        }
        rowCount={rowCount}
        disableSelectionOnClick
      />
      <ExportWorkloadsCSV />
    </div>
  );
};

export default WorkloadsTable;
