import { useState, useCallback } from "react";
import * as componentUtils from "../../components/componentUtils/overview";
import { AlertProps } from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import SlackSetup from "../../components/SlackSetup";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { Alert, Snackbar } from "@mui/material";
import { GetConfig } from "../../utils/ConfigHelper";
import { documentationUrl } from "../../utils/urlUtils";

const Alerts = () => {
  const config = GetConfig();
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, "children" | "severity"> | null>(null);
  const handleCloseSnackbar = () => setSnackbar(null);
  const savedPopupSuccess = useCallback(() => {
    setSnackbar({ children: "Saved", severity: "success" });
  }, []);
  const savedPopupFailed = useCallback((message: string) => {
    setSnackbar({ children: message, severity: "error" });
  }, []);

  const handleSave = (success: boolean, message = "") => {
    if (success) savedPopupSuccess();
    if (!success) savedPopupFailed(message);

    setTimeout(() => {
      handleCloseSnackbar();
    }, 15000);
  };

  return componentUtils.windowBlock(
    "",
    <div>
      <Paper
        variant="outlined"
        sx={{ width: "100%", typography: "body1", padding: "24px 20px 24px", marginTop: "10px" }}
      >
        <div className="mb-2 mt-4">
          <Typography variant="h5">Cluster Optimization Notifications</Typography>
          <Typography variant="caption">
            Once the setup below is complete, notifications will be sent in regards with this cluster. For more
            information click{" "}
            <a
              href={documentationUrl({
                path: "integrations/slack-integration",
                token: config.token,
              })}
              target="_blank"
              className="text-main-blue"
            >
              here
            </a>
            .
          </Typography>
        </div>
        <SlackSetup handleSave={handleSave} />
      </Paper>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
};

export default Alerts;
