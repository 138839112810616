import { ReactElement } from "react";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

declare type PolicyKind = "delete" | "new" | "update";
// export declare type GoalMetricFunctionality = GoalMetricFunctionalityMinimize | GoalMetricFunctionalityMaximize;
// export enum GoalMetricFunctionality {
//   Minimize = "Minimize",
//   Maximize = "Maximize",
// }
export type Directionality = ">=" | "<=" | ">" | "<";
export interface Metric {
  name: string;
  limit: number;
  units: string;
  directionality: Directionality;
}
export interface SLA extends Metric {
  vlad?: string;
}
export interface Restrictions {
  minPods?: number;
  maxPods?: number;
  metric?: { name: string; limit: number; units: string; dir: Directionality };
}
export interface Policy {
  id: string;
  name: string;
  schedule: number;
  sla: Metric[];
  kind?: PolicyKind;
}
export const Markdown = styled(Typography)(() => ({
  backgroundColor: "#f6f8fa",
  boxSizing: "border-box",
  borderRadius: "6px",
  color: "black",
  padding: "7px",
  width: "fit-content",
  fontSize: "12px",
}));

export const RenderMetrics = (metrics: Metric[] | undefined) => {
  if (metrics == undefined) {
    return <></>;
  }
  return metrics.map((m) => {
    return RenderMetric(m);
  });
};
export const RenderMetric = (metric: Metric | undefined): ReactElement => {
  if (metric != undefined) {
    return (
      <Markdown>
        <code>
          {metric.name} {metric.directionality} {metric.limit}
          {metric.units}
        </code>
      </Markdown>
    );
  }
  return <></>;
};

// type PoliciesTableProps = {
//   policies: Policy[];
//   updatePolicy: (policy: Partial<Policy>) => Promise<Partial<Policy>>;
// };
// export default function PoliciesTable(props: PoliciesTableProps) {
//   const { policies, updatePolicy }: PoliciesTableProps = props;
//   const [policyForEdit, setPolicyForEdit] = useState<Partial<Policy> | undefined>(undefined);
//
//   const addPolicy = (policy: Partial<Policy> | undefined): void => {
//     if (policy != undefined) {
//       setPolicyForEdit(policy);
//     } else {
//       setPolicyForEdit({});
//     }
//   };
//   const openDetailsPopup = (id: GridRowId) => {
//     console.log("openDetailsPopup", "id", id);
//     setPolicyForEdit(
//       policies.find((p) => {
//         return p.id == id;
//       })
//     );
//   };
//   // const openDetailsPopupCallback = useCallback(
//   //   (id: GridRowId) => () => {
//   //     openDetailsPopup(id);
//   //   },
//   //   []
//   // );
//
//   const rowClick: GridEventListener<"rowClick"> = (params: GridRowParams<Workload>, event) => {
//     event.defaultMuiPrevented = true;
//     event.preventDefault();
//     openDetailsPopup(params.id);
//   };
//
//   const handleDeleteClick = (id: GridRowId) => () => {
//     const policy = policies.find((policy) => policy.id == id);
//     if (policy) {
//       policy.kind = "delete";
//       updatePolicy(policy)
//         .then((p) => {
//           console.log(p);
//         })
//         .catch((reason) => {
//           console.error(reason);
//         });
//     }
//   };
//
//   const columns: GridColumns = [
//     {
//       field: "name",
//       headerName: "Policy Name",
//       flex: 1,
//       type: "string",
//       align: "left",
//       disableColumnMenu: true,
//       sortable: false,
//     },
//     {
//       field: "experimentDuration",
//       headerName: "Tuning Duration",
//       flex: 1,
//       type: "number",
//       align: "center",
//       disableColumnMenu: true,
//       sortable: false,
//       valueFormatter: ({ value }: GridValueFormatterParams<number>) => {
//         if (value == null) {
//           return "";
//         }
//         return moment.duration(value, "minutes").humanize();
//       },
//     },
//     {
//       field: "restrictions",
//       headerName: "Restrictions",
//       flex: 1,
//       type: "string",
//       align: "center",
//       disableColumnMenu: true,
//       sortable: false,
//       valueGetter: (params: GridValueGetterParams<{ minPods?: number; maxPods?: number }, Policy>) => {
//         return `Max Pods: ${params.value?.maxPods || "infinite"}, Min Pods: ${params.value?.minPods || 0}`;
//       },
//     },
//     {
//       field: "goalMetric",
//       headerName: "Goal",
//       flex: 1,
//       type: "string",
//       align: "center",
//       disableColumnMenu: true,
//       sortable: false,
//       renderCell: (params: GridRenderCellParams<SLA, Policy>) => {
//         return RenderGoalMetric(params.value);
//       },
//     },
//     {
//       field: "actions",
//       type: "actions",
//       getActions: ({ id }: GridRowParams<Workload>): ReactElement<GridActionsCellItemProps>[] => {
//         return [<GridActionsCellItem icon={<DeleteIcon />} onClick={handleDeleteClick(id)} label="Delete" />];
//       },
//     },
//   ];
//
//   const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     const duration: HTMLInputElement | null = event.currentTarget.elements.namedItem(
//       "duration"
//     ) as HTMLInputElement | null;
//     const minPodsInput: HTMLInputElement | null = event.currentTarget.elements.namedItem(
//       "minPods"
//     ) as HTMLInputElement | null;
//     const maxPodsInput: HTMLInputElement | null = event.currentTarget.elements.namedItem(
//       "maxPods"
//     ) as HTMLInputElement | null;
//     const metricNameInput: HTMLInputElement | null = event.currentTarget.elements.namedItem(
//       "goal-metric-name"
//     ) as HTMLInputElement | null;
//     const directionalityInput: HTMLInputElement | null = event.currentTarget.elements.namedItem(
//       "goal-metric-function-name"
//     ) as HTMLInputElement | null;
//     const nameInput: HTMLInputElement | null = event.currentTarget.elements.namedItem(
//       "name"
//     ) as HTMLInputElement | null;
//     const kindInput: HTMLInputElement | null = event.currentTarget.elements.namedItem(
//       "kind"
//     ) as HTMLInputElement | null;
//     const idInput: HTMLInputElement | null = event.currentTarget.elements.namedItem("id") as HTMLInputElement | null;
//     const kind: PolicyKind = kindInput == null ? "new" : (kindInput.value as PolicyKind);
//     const id = idInput == null ? uuidv4() : idInput.value;
//     const experimentDuration = duration?.value != undefined ? +duration?.value : undefined;
//     const restrictions = {
//       minPods: minPodsInput?.value != undefined ? +minPodsInput?.value : undefined,
//       maxPods: maxPodsInput?.value != undefined ? +maxPodsInput?.value : undefined,
//     };
//     const metricName = metricNameInput?.value || "";
//     const directionality = directionalityInput?.value || "<=";
//     const goalMetric: SLA = {
//       name: metricName,
//       units: "",
//       limit: 100,
//       directionality: directionality as Directionality,
//
//       //
//       // metricName: metricName || "",
//       // functionName: (directionality || GoalMetricFunctionality.Maximize) as GoalMetricFunctionality,
//     };
//     const name = nameInput?.value;
//     const updatedPolicy: Partial<Policy> = {
//       id,
//       name,
//       experimentDuration,
//       restrictions,
//       sla: goalMetric,
//       kind,
//     };
//
//     updatePolicy(updatedPolicy)
//       .then((workload) => {
//         console.log(workload);
//         setPolicyForEdit(undefined);
//       })
//       .catch((reason) => console.error(reason));
//   };
//
//   return (
//     <Box height={"210px"}>
//       <DataGrid
//         sx={{
//           cursor: "pointer",
//         }}
//         columns={columns}
//         rows={policies}
//         components={{
//           Footer: PolicyFooterToolbar,
//         }}
//         componentsProps={{
//           footer: { updatePolicy: updatePolicy, addPolicy: addPolicy, openUpdate: setPolicyForEdit },
//         }}
//         onRowClick={rowClick}
//         checkboxSelection
//         autoHeight
//       />
//       <Dialog
//         open={policyForEdit != undefined}
//         onClose={() => {
//           setPolicyForEdit(undefined);
//         }}
//       >
//         <Box component="form" onSubmit={handleSave}>
//           <DialogTitle style={{ backgroundColor: theme.palette.primary.main, color: "white", height: "55px" }}>
//             HPA Policy
//           </DialogTitle>
//           <DialogContent>
//             <PolicyDetails editable policy={policyForEdit}></PolicyDetails>
//           </DialogContent>
//           <DialogActions>
//             <Button
//               onClick={() => {
//                 setPolicyForEdit(undefined);
//               }}
//             >
//               Cancel
//             </Button>
//             <Button type="submit">Save</Button>
//           </DialogActions>
//         </Box>
//       </Dialog>
//     </Box>
//   );
// }
