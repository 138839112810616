import { useQuery } from "@tanstack/react-query";
import { getNamespaces, GetNamespacesResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";

const { queryKey, queryFn } = getNamespaces();

const useGetNamespaces = (isMultiCluster?: boolean) => {
  const { data, isLoading, error } = useQuery<GetNamespacesResponse>({
    queryKey: [queryKey, isMultiCluster],
    queryFn: () => queryFn({ multiCluster: isMultiCluster }),
  });

  if (error) {
    console.log("Error fetching labels", error);
    return [];
  }

  if (isLoading || !data?.namespaces) {
    return [];
  }

  const namespacesNames = data.namespaces
    .filter((ns) => !!ns?.metadata?.name)
    .map((ns: components["schemas"]["V1Namespace"]) => ns.metadata?.name ?? "");
  return [...new Set(namespacesNames)]; // for uniqueness
};

export default useGetNamespaces;
