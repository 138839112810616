import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import CheckIcon from "../../Icons/CheckIcon";
import CheckedIcon from "../../Icons/CheckedIcon";
import CloseIcon from "../../Icons/CloseIcon";
import InfoIcon from "../../Icons/InfoIcon";
import { components } from "../../api/schema";
import { getDataGridSx } from "../../utils/styleUtils";
import CustomColumnsFilterButton from "../CustomColumnsFilterButton";
import MultiSelect from "../MultiSelect";
import Tooltip from "../Tooltip";
import ExportCSV, { HAS_EXPORT_TABLE_AS_CSV } from "../exportCSV/ExportCSV";
import CustomHeader from "./CustomHeader";
import SearchNodeFilter from "./SearchNodeFilter";
import UsageAndRequestChart, { Elements } from "./UsageAndRequestChart";
import { DIFF_DEFAULT_PROPS, formatXDigits, SimpleEllipsisWithTooltip } from "./Utils";

const secondsToHours = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const days = Math.floor(hours / 24);
  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  }
  if (minutes > 0) {
    return `${minutes}m`;
  }
  return `${seconds}s`;
};

type CSVExportType = {
  name: string;
  cost: number;
  instanceTypesDisplayName: string;
  numNodes: { min: number; current: number; max: number };
  cpuDiff: number;
  memoryDiff: number;
  cpuRequests: number;
  cpuAllocatable: number;
  cpuUsage: number;
  memoryRequests: number;
  memoryAllocatable: number;
  memoryUsage: number;
  id: string;
};

enum NodePoolColumns {
  MonthlyCost = "Monthly Cost",
  InstanceTypes = "Instance Types",
  Consolidate = "Consolidate",
  Budgets = "Budgets",
  Expiration = "Expiration",
  Emptiness = "Emptiness",
  CpuRequestVsAllocatableDiff = "CPU (Request vs Allocatable)",
  CpuUsageVsAllocatableDiff = "CPU (Usage vs Allocatable)",
  MemoryRequestVsAllocatableDiff = "Memory (Request vs Allocatable)",
  MemoryUsageVsAllocatableDiff = "Memory (Usage vs Allocatable)",
  Policy = "Policy",
}

const initialColumns = [
  NodePoolColumns.MonthlyCost,
  NodePoolColumns.InstanceTypes,
  NodePoolColumns.CpuRequestVsAllocatableDiff,
  NodePoolColumns.CpuUsageVsAllocatableDiff,
  NodePoolColumns.MemoryRequestVsAllocatableDiff,
  NodePoolColumns.MemoryUsageVsAllocatableDiff,
  NodePoolColumns.Policy,
];

const availableColumns = [
  NodePoolColumns.MonthlyCost,
  NodePoolColumns.InstanceTypes,
  NodePoolColumns.CpuRequestVsAllocatableDiff,
  NodePoolColumns.CpuUsageVsAllocatableDiff,
  NodePoolColumns.MemoryRequestVsAllocatableDiff,
  NodePoolColumns.MemoryUsageVsAllocatableDiff,
  NodePoolColumns.Expiration,
  NodePoolColumns.Emptiness,
  NodePoolColumns.Budgets,
  NodePoolColumns.Policy,
];

export type NodePoolRowEntry = {
  name: string;
  instanceTypes: string[];
  instanceTypesDisplayName: string;
  cpuDiff: number;
  memoryDiff: number;
  numNodes: { min: number; current: number; max: number };
  cpuRequests: number;
  cpuAllocatable: number;
  cpuUsage: number;
  memoryRequests: number;
  memoryAllocatable: number;
  memoryUsage: number;
  cost: number;
  consolidate?: boolean;
  expiresAfterInSeconds?: number | null;
  emptinessInSeconds?: number | null;
  blockedConsolidationReasons?: string[] | null;
  budgets?: components["schemas"]["UtilsBudget"][];
  policy?: string;
};

type NodePoolResponseTypeSchema = components["schemas"]["UtilsNodePoolInfo"];

const renderBlockedConsolidationReasons = (blockedConsolidationReasons: string[] | null) => {
  return (
    <div className="w-full text-wrap">
      {blockedConsolidationReasons?.map((reason: string) => {
        return <li>{reason}</li>;
      })}
    </div>
  );
};

const renderNameCell = (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
  return <SimpleEllipsisWithTooltip text={params.row.name} />;
};

const renderCostCell = (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
  return <p>${formatXDigits(params.row.cost)}</p>;
};

const renderInstanceTypesCell = (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
  return <SimpleEllipsisWithTooltip text={params.row.instanceTypes?.join(", ")} />;
};

const renderSingleBudget = (budget: components["schemas"]["UtilsBudget"]) => {
  const schedule = budget?.schedule || "always";
  const duration = budget?.duration ? String(budget?.duration) : "always";
  const showDuration = schedule !== "always" && duration !== "always";
  return (
    <div className={"flex w-full"}>
      <div className="flex justify-center items-center w-[15%]">{budget?.IsActive ? <CheckIcon /> : <CloseIcon />}</div>
      <div className="grow flex flex-col justify-evenly">
        <div>
          <b>Schedule:</b> {schedule}
        </div>
        {showDuration && (
          <div>
            <b>Duration:</b> {duration}
          </div>
        )}
        <div>
          <b>Nodes:</b> {budget?.nodes}
        </div>
      </div>
    </div>
  );
};

const getColumns = (selectedColumns: (string | undefined)[]) => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: 300,
      type: "string",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: renderNameCell,
    },
    {
      field: "cost",
      hide: !selectedColumns.includes(NodePoolColumns.MonthlyCost),
      headerName: "Monthly Cost",
      description: "Monthly Cost",
      flex: 2,
      minWidth: 150,
      type: "number",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: renderCostCell,
    },
    {
      field: "instanceTypesDisplayName",
      hide: !selectedColumns.includes(NodePoolColumns.InstanceTypes),
      headerName: "Instance Types",
      description: "Instance Types",
      flex: 2,
      minWidth: 150,
      type: "string",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: renderInstanceTypesCell,
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "cpuRequestVsAllocatableDiff",
        headerName: "cpuRequestVsAllocatableDiff",
        hide: !selectedColumns.includes(NodePoolColumns.CpuRequestVsAllocatableDiff),
        renderHeader: () => <CustomHeader title="CPU Request" tooltipContent="Request vs Allocatable" />,
        renderCell: (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
          if (params.row.cpuAllocatable === 0) {
            return null;
          }
          return (
            <UsageAndRequestChart
              usage={Math.round((params.row.cpuUsage / params.row.cpuAllocatable) * 100)}
              request={Math.round((params.row.cpuRequests / params.row.cpuAllocatable) * 100)}
              tooltipData={{
                usage: params.row.cpuUsage,
                request: params.row.cpuRequests,
                allocatable: params.row.cpuAllocatable,
              }}
              elementToDisplay={[Elements.Request]}
              showMetricsTitles={false}
              showAllocatableBellow
            />
          );
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "cpuUsageVsAllocatableDiff",
        headerName: "cpuUsageVsAllocatableDiff",
        hide: !selectedColumns.includes(NodePoolColumns.CpuUsageVsAllocatableDiff),
        renderHeader: () => <CustomHeader title="CPU Usage" tooltipContent="Usage vs Allocatable" />,
        renderCell: (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
          if (params.row.cpuAllocatable === 0) {
            return null;
          }
          return (
            <UsageAndRequestChart
              usage={Math.round((params.row.cpuUsage / params.row.cpuAllocatable) * 100)}
              request={Math.round((params.row.cpuRequests / params.row.cpuAllocatable) * 100)}
              tooltipData={{
                usage: params.row.cpuUsage,
                request: params.row.cpuRequests,
                allocatable: params.row.cpuAllocatable,
              }}
              elementToDisplay={[Elements.Usage]}
              showMetricsTitles={false}
              showAllocatableBellow
            />
          );
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "memoryRequestVsAllocatableDiff",
        headerName: "memoryRequestVsAllocatableDiff",
        hide: !selectedColumns.includes(NodePoolColumns.MemoryRequestVsAllocatableDiff),
        renderHeader: () => <CustomHeader title="Memory Request" tooltipContent="Request vs Allocatable" />,
        renderCell: (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
          if (params.row.memoryAllocatable === 0) {
            return null;
          }
          return (
            <UsageAndRequestChart
              usage={Math.round((params.row.memoryUsage / params.row.memoryAllocatable) * 100)}
              request={Math.round((params.row.memoryRequests / params.row.memoryAllocatable) * 100)}
              tooltipData={{
                usage: params.row.memoryUsage,
                request: params.row.memoryRequests,
                allocatable: params.row.memoryAllocatable,
              }}
              elementToDisplay={[Elements.Request]}
              showMetricsTitles={false}
              showAllocatableBellow
            />
          );
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "memoryUsageVsAllocatableDiff",
        headerName: "memoryUsageVsAllocatableDiff",
        hide: !selectedColumns.includes(NodePoolColumns.MemoryUsageVsAllocatableDiff),
        ...DIFF_DEFAULT_PROPS,
        ...{
          renderHeader: () => <CustomHeader title="Memory Usage" tooltipContent="Usage vs Allocatable" />,
          renderCell: (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
            if (params.row.memoryAllocatable === 0) {
              return null;
            }
            return (
              <UsageAndRequestChart
                usage={Math.round((params.row.memoryUsage / params.row.memoryAllocatable) * 100)}
                request={Math.round((params.row.memoryRequests / params.row.memoryAllocatable) * 100)}
                tooltipData={{
                  usage: params.row.memoryUsage,
                  request: params.row.memoryRequests,
                  allocatable: params.row.memoryAllocatable,
                }}
                elementToDisplay={[Elements.Usage]}
                showMetricsTitles={false}
              />
            );
          },
        },
      },
    },
    {
      field: "consolidate",
      hide: !selectedColumns.includes(NodePoolColumns.Consolidate),
      headerName: "Consolidate",
      description: "Consolidate",
      flex: 1,
      minWidth: 150,
      type: "boolean",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
        return params.row.consolidate ? (
          <CheckedIcon className="text-main-green" />
        ) : (
          <Tooltip
            maxWidth={420}
            disabled={(params.row?.blockedConsolidationReasons || []).length == 0}
            title={renderBlockedConsolidationReasons(params.row?.blockedConsolidationReasons || [])}
          >
            No
          </Tooltip>
        );
      },
    },
    {
      field: "expirationInSeconds",
      hide: !selectedColumns.includes(NodePoolColumns.Expiration),
      headerName: "Expiration",
      description: "Expiration",
      flex: 1,
      minWidth: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
        return params.row.expiresAfterInSeconds ? secondsToHours(params.row.expiresAfterInSeconds) : "N/A";
      },
    },
    {
      field: "emptinessInSeconds",
      hide: !selectedColumns.includes(NodePoolColumns.Emptiness),
      headerName: "Emptiness",
      description: "Emptiness",
      flex: 1,
      minWidth: 150,
      type: "number",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
        return params.row.emptinessInSeconds ? secondsToHours(params.row.emptinessInSeconds) : "N/A";
      },
    },
    {
      field: "policy",
      hide: !selectedColumns.includes(NodePoolColumns.Policy),
      headerName: "Disruption Policy",
      description: "Disruption Policy",
      flex: 1,
      minWidth: 150,
      type: "string",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
        const policyDisplayText = params.row.policy === "WhenEmpty" ? "WhenEmpty" : "WhenUnderutilized";
        return <i>{policyDisplayText}</i>;
      },
    },
    {
      field: "budgets",
      hide: !selectedColumns.includes(NodePoolColumns.Budgets),
      headerName: "Budgets",
      description: "Budgets",
      flex: 1,
      minWidth: 150,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, NodePoolRowEntry, string>) => {
        const activeBudgets = params.row.budgets?.reduce(
          (a: number, b: components["schemas"]["UtilsBudget"]) => (b?.IsActive ? a + 1 : a),
          0
        );
        const tooltipTitle = (
          <div className="w-[250px]">
            <hr />
            {params.row.budgets?.map((budget) => (
              <div>
                <div>{renderSingleBudget(budget)}</div>
                <hr />
              </div>
            ))}
          </div>
        );
        return (
          <Tooltip title={tooltipTitle} maxWidth={420}>
            <div className="flex flex-row-reverse gap-2">
              <div className="flex justify-center items-center">
                <InfoIcon />
              </div>
              <div className="flex flex-col justify-center items-center">
                <div>
                  <b>Active:</b> {activeBudgets}
                </div>
                <div>
                  <b>Total:</b> {params.row.budgets?.length}
                </div>
              </div>
            </div>
          </Tooltip>
        );
      },
    },
  ];

  return columns;
};

interface Props {
  nodePools: NodePoolResponseTypeSchema[];
  isLoading: boolean;
}

const parseIntoRows = (nodePools: NodePoolResponseTypeSchema[]): NodePoolRowEntry[] => {
  return nodePools.map((pool) => {
    return {
      name: pool.name,
      numNodes: {
        current: 0,
        min: 0,
        max: 0,
      },
      instanceTypes: pool?.instanceTypes,
      instanceTypesDisplayName: "",
      cpuRequests: pool?.cpuRequest,
      memoryRequests: pool?.memoryRequest,
      cpuAllocatable: pool?.cpuAllocatable,
      cpuUsage: pool?.cpuUsage,
      cpuDiff: 0,
      memoryDiff: 0,
      memoryAllocatable: pool?.memoryAllocatable,
      memoryUsage: pool?.memoryUsage,
      cost: pool?.cost,
      consolidate: pool?.consolidate,
      expiresAfterInSeconds: pool?.expiresAfterInSeconds,
      emptinessInSeconds: pool?.emptinessInSeconds,
      blockedConsolidationReasons: pool?.blockedConsolidationReasons,
      budgets: pool?.budgets,
      policy: pool.policy,
    };
  });
};

function NodePoolsTab({ nodePools, isLoading }: Props) {
  const [rows, setRows] = useState<NodePoolRowEntry[]>([]);
  const [searchTerm] = useQueryParam("setSearchTerm", StringParam);
  const [selectedColumns, setSelectedColumns] = useState<(string | undefined)[]>(initialColumns);

  useEffect(() => {
    let rowsToDisplay = parseIntoRows(nodePools);

    if (searchTerm && searchTerm.length > 0) {
      rowsToDisplay = rowsToDisplay.filter((row) => row.name.includes(searchTerm));
    }

    rowsToDisplay = rowsToDisplay.sort((a, b) => a.name.localeCompare(b.name));

    setRows(rowsToDisplay);
  }, [nodePools, searchTerm]);

  return (
    <div>
      <div className="w-full flex gap-4 pb-8 item">
        <div className="grow">
          <SearchNodeFilter />
        </div>
        <div>
          <MultiSelect
            selected={selectedColumns}
            setSelected={setSelectedColumns}
            options={availableColumns}
            className="w-[85px]"
            customIcon={<CustomColumnsFilterButton isFiltered={selectedColumns.length > 0} />}
          />
        </div>
      </div>
      <DataGrid
        sx={{
          ...getDataGridSx(),
        }}
        rows={rows}
        columns={getColumns(selectedColumns)}
        getRowId={(row: NodePoolRowEntry) => row.name}
        autoHeight={true}
        rowHeight={100}
        pageSize={10}
        loading={isLoading}
        disableSelectionOnClick
        getEstimatedRowHeight={() => 100}
      />
      {HAS_EXPORT_TABLE_AS_CSV && (
        <div className="mt-[-35px] ml-[10px] z-50 relative w-fit">
          <ExportCSV<CSVExportType>
            filename="node_groups.csv"
            columns={[
              "name",
              "cost",
              "instanceTypesDisplayName",
              "numNodes",
              "cpuDiff",
              "memoryDiff",
              "cpuRequests",
              "cpuAllocatable",
              "cpuUsage",
              "memoryRequests",
              "memoryAllocatable",
              "memoryUsage",
            ]}
            columnsToRound={["cost"]}
            data={
              rows.map((row) => {
                return { ...row, id: row.name };
              }) as CSVExportType[]
            }
            columnsToSum={[
              "name",
              "cost",
              "instanceTypesDisplayName",
              "numNodes",
              "cpuDiff",
              "memoryDiff",
              "cpuRequests",
              "cpuAllocatable",
              "cpuUsage",
              "memoryRequests",
              "memoryAllocatable",
              "memoryUsage",
            ]}
            customColumnNames={{
              name: "Name",
              cost: "Monthly Cost",
              instanceTypesDisplayName: "Instance Types",
              numNodes: "Number of Nodes",
              cpuDiff: "CPU",
              memoryDiff: "Memory",
              cpuRequests: "CPU Requests",
              cpuAllocatable: "CPU Allocatable",
              cpuUsage: "CPU Usage",
              memoryRequests: "Memory Requests",
              memoryAllocatable: "Memory Allocatable",
              memoryUsage: "Memory Usage",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default NodePoolsTab;
