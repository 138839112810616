import { Typography } from "@mui/material";
import LockIcon from "../../../Icons/LockIcon";
import MagicWand from "../../../Icons/MagicWand";
import ReadOnlyIcon from "../../../Icons/ReadOnlyIcon";
import ScaleOpsSpan from "../../../components/ScaleOpsSpan";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";

const UNEVICTABLE_BY_ANNOTATION = "Unevictable by Annotation";
const UNEVICTABLE_BY_PDB = "Unevictable by PDB";
const AUTO_DETECTED_WRAPPER = "flex flex-col gap-2";
const SCALEOPS_SYSTEM_NAMESPACE = "scaleops-system";

const ICON_SIZE = 12;

const AutoDetectedTitle = ({ smartPolicyName }: { smartPolicyName?: string }) => (
  <Typography variant="body2" className="flex items-center gap-2 text-guideline-darkPurple" fontWeight={500}>
    <MagicWand />
    <p>
      Auto Policy Detection - <b>{smartPolicyName}</b>
    </p>
  </Typography>
);

interface Props {
  displayPolicyName: string;
  namespace: string;
  smartPolicyName?: string;
  smartPolicyWorkloadType?: string;
}

const PolicyNameTooltip = ({ displayPolicyName, namespace, smartPolicyName, smartPolicyWorkloadType }: Props) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  let autoPolicyOrPolicyNameSection: React.ReactNode | undefined;

  const isSmartPolicy = smartPolicyName && smartPolicyName == displayPolicyName;

  switch (true) {
    case isSmartPolicy && smartPolicyWorkloadType === UNEVICTABLE_BY_ANNOTATION:
      autoPolicyOrPolicyNameSection = (
        <div className={AUTO_DETECTED_WRAPPER}>
          <AutoDetectedTitle smartPolicyName={smartPolicyName} />
          <Typography variant="caption">
            <ScaleOpsSpan /> detected a critical <b>Unevictable</b> workload restricted by <b>Annotation</b> and
            assigned <b>{smartPolicyName}</b> policy automatically.
          </Typography>
        </div>
      );
      break;
    case isSmartPolicy && smartPolicyWorkloadType === UNEVICTABLE_BY_PDB:
      autoPolicyOrPolicyNameSection = (
        <div className={AUTO_DETECTED_WRAPPER}>
          <AutoDetectedTitle smartPolicyName={smartPolicyName} />
          <Typography variant="caption">
            <ScaleOpsSpan /> detected a critical <b>Unevictable</b> workload restricted by <b>PDB</b> and assigned{" "}
            <b>{smartPolicyName}</b> policy automatically.
          </Typography>
        </div>
      );
      break;
    case smartPolicyName == displayPolicyName:
      autoPolicyOrPolicyNameSection = (
        <div className={AUTO_DETECTED_WRAPPER}>
          <AutoDetectedTitle smartPolicyName={smartPolicyName} />
          <Typography variant="caption" fontWeight={500}>
            <ScaleOpsSpan /> detected a critical <b>{smartPolicyWorkloadType}</b> workload, and assigned{" "}
            <b>{smartPolicyName}</b> policy automatically.
          </Typography>
        </div>
      );
      break;
    default:
      autoPolicyOrPolicyNameSection = (
        <Typography variant="body2" className="text-background-darkChipHover" fontWeight={500}>
          Policy - {displayPolicyName}
        </Typography>
      );
      break;
  }

  return (
    <div className="flex flex-col gap-1">
      {autoPolicyOrPolicyNameSection}
      {(namespace === SCALEOPS_SYSTEM_NAMESPACE || isReadyOnlyFrontEnd) && (
        <div className="mt-2">
          {namespace === SCALEOPS_SYSTEM_NAMESPACE && (
            <Typography variant="caption" className="flex items-center gap-1">
              <LockIcon width={ICON_SIZE} height={ICON_SIZE} />
              <p>
                <b>scaleops-system</b> have default policy.
              </p>
            </Typography>
          )}
          {isReadyOnlyFrontEnd && (
            <Typography variant="caption" className="flex items-center gap-1">
              <ReadOnlyIcon width={ICON_SIZE} height={ICON_SIZE} />
              you have <b>read-only</b> access.
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default PolicyNameTooltip;
