import FormSwitch from "../../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import { shouldDisableDefaultPolicyInputs } from "../../utils";
import { HPA_SECTION_CLASS_NAME } from "../utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const EnableHPA = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription
        title="Enable HPA optimization"
        description={<>Automate HPA workloads based on historical trends.</>}
      />
      <FormSwitch
        name="hasHPAenabled"
        disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
        disableChange={!isCustomizedPolicy}
        label="Enable HPA"
      />
    </div>
  );
};
export default EnableHPA;
