import {
  Alert,
  AlertProps,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { components } from "../../api/schema";
import useGetApi from "../../api/useGetApi";
import { SCALEOPS_COLORS } from "../../colors";
import BetaIcon from "../../Icons/BetaIcon";
import useGetUserRole from "../../MainMenu/useGetUserRole";
import * as componentUtils from "../componentUtils/overview";
import SnapshotWrapper from "../SnapshotWrapper";
import { IsSnapshotServer } from "../../utils/FeaturesHelper";

interface Props {
  isBeta?: boolean;
}

const convertDayNumberToDayOfWeekLong = (dayNum: number): string => {
  switch (dayNum) {
    case 0: {
      return "Sunday";
    }
    case 1: {
      return "Monday";
    }
    case 2: {
      return "Tuesday";
    }
    case 3: {
      return "Wednesday";
    }
    case 4: {
      return "Thursday";
    }
    case 5: {
      return "Friday";
    }
    case 6: {
      return "Saturday";
    }
    default: {
      return "";
    }
  }
};

const convertDayNumberToDayOfWeek = (dayNum: number): string => {
  switch (dayNum) {
    case 0: {
      return "S";
    }
    case 1: {
      return "M";
    }
    case 2: {
      return "T";
    }
    case 3: {
      return "W";
    }
    case 4: {
      return "T";
    }
    case 5: {
      return "F";
    }
    case 6: {
      return "S";
    }
    default: {
      return "";
    }
  }
};

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    margin: theme.spacing(0.5),
    border: 0,
    "&:not(.Mui-selected)": {
      backgroundColor: "rgba(22,68,96,0.02)",
    },
    "&:not(:first-of-type)": {
      borderRadius: "50%",
    },
    "&:first-of-type": {
      borderRadius: "50%",
    },
  },
}));

const CapacityConfiguration = ({ isBeta }: Props) => {
  const { userRole } = useGetUserRole();
  const api = useGetApi();
  const [isScheduled, setIsScheduled] = React.useState<boolean | undefined>(undefined);
  const [isSpotObserved, setIsSpotObserved] = React.useState<boolean | undefined>(undefined);
  const [days, setDays] = React.useState<number[]>();
  const [beginTime, setBeginTime] = React.useState<string>();
  const [endTime, setEndTime] = React.useState<string>();
  const [staticHeadroom, setStaticHeadroom] = React.useState<string>("Static");
  const [saveEnable, setSaveEnable] = React.useState<boolean>(false);
  const [headroomSpot, setHeadroomSpot] = React.useState<components["schemas"]["ConfigTypeHeadroom"]>();
  const [headroomOnDemand, setHeadroomOnDemand] = React.useState<components["schemas"]["ConfigTypeHeadroom"]>();
  const [snackbar, setSnackbar] = useState<Pick<AlertProps, "children" | "severity"> | null>(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  useEffect(() => {
    api
      .getFetcher()
      .path("/api/v1/headroom/")
      .method("get")
      .create()({})
      .then((response) => {
        if (response.ok) {
          if (response.data != undefined) {
            setIsScheduled(response.data.isScheduled);
            setIsSpotObserved(response.data.isLifeCycleLabelObserved);
            setDays(response.data.days);
            setBeginTime(response.data.beginTime);
            setEndTime(response.data.endTime);
            setStaticHeadroom(response.data.isAutomatedHeadroom ? "Proportional" : "Static");

            if (response.data.headrooms != undefined) {
              const spot = response.data.headrooms.find((w) => w.label === "spot");

              if (spot != undefined) {
                setHeadroomSpot(spot);
              } else {
                setHeadroomSpot({
                  cpu: 0,
                  cpuClusterProportion: 0,
                  label: "spot",
                  memory: 0,
                  memoryClusterProportion: 0,
                });
              }
            }

            if (response.data.headrooms != undefined) {
              const onDemand = response.data.headrooms.find((w) => w.label === "onDemand");
              if (onDemand != undefined) {
                setHeadroomOnDemand(onDemand);
              } else {
                setHeadroomOnDemand({
                  cpu: 0,
                  cpuClusterProportion: 0,
                  label: "onDemand",
                  memory: 0,
                  memoryClusterProportion: 0,
                });
              }
            }
          }
        }
      })
      .catch((err) => console.log(err));
  }, [api]);

  function buildTextFieldForTimeSelection(
    value: string | undefined,
    label: string,
    updateTimeFunction: React.Dispatch<React.SetStateAction<string | undefined>>,
    disable: boolean
  ) {
    return (
      <TimePicker
        disabled={disable}
        label={label}
        ampm={false}
        inputFormat={"HH:mm"}
        value={dayjs(value, "HH:mm")}
        onChange={(newValue: Dayjs | null) => {
          if (newValue != null) {
            updateTimeFunction(newValue.format("HH:mm"));
            saveEnableChange();
          }
        }}
        renderInput={(params) => <TextField size="small" sx={{ width: "110px" }} {...params} />}
      />
    );
  }

  const saveEnableChange = () => {
    if (!saveEnable) {
      setSaveEnable(true);
    }
  };

  const saveHeadroom = () => {
    const headroomsToUpdate: components["schemas"]["ConfigTypeHeadroom"][] = [];

    if (headroomSpot != undefined) {
      headroomsToUpdate.push(headroomSpot);
    }

    if (headroomOnDemand != undefined) {
      headroomsToUpdate.push(headroomOnDemand);
    }

    api
      .getFetcher()
      .path("/api/v1/headroom/")
      .method("put")
      .create()({
        isScheduled: isScheduled,
        beginTime: beginTime,
        endTime: endTime,
        days: days,
        headrooms: headroomsToUpdate,
        isAutomatedHeadroom: staticHeadroom !== "Static",
      })
      .then(() => {
        handleProcessRowUpdateSuccess("Successfully update headroom configuration");
        setSaveEnable(false);
      })
      .catch((error) => {
        console.error(error);
        handleProcessRowUpdateError("Failed to update headroom configuration");
      });
  };

  const handleProcessRowUpdateError = useCallback((message: string) => {
    setSnackbar({ children: message, severity: "error" });
  }, []);

  const handleProcessRowUpdateSuccess = useCallback((message: string) => {
    setSnackbar({ children: message, severity: "success" });
  }, []);

  function buildTextFieldFunc(
    labelValue: string,
    value: number,
    setValueFunc: (value: number) => void,
    disabled: boolean,
    proportional: boolean
  ) {
    return (
      <FormControl style={{ height: "fit-content" }}>
        <TextField
          style={{ width: "120px", height: "fit-content" }}
          label={labelValue}
          type={"number"}
          defaultValue={value}
          onBlur={(event) => {
            let valueToSet = Number(event.target.value);
            if (proportional && valueToSet < 0) {
              valueToSet = 0;
            } else if (proportional && valueToSet > 50) {
              valueToSet = 50;
            }

            if (!proportional && valueToSet < 0) {
              valueToSet = 0;
            }

            setValueFunc(valueToSet);
            saveEnableChange();
          }}
          inputProps={
            proportional
              ? {
                  step: 1,
                  min: 0,
                  max: 50,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }
              : {
                  step: 1,
                  min: 0,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }
          }
          disabled={disabled}
        />
      </FormControl>
    );
  }

  function buildStaticResource(
    label: string,
    cpuLabel: string,
    memoryLabel: string,
    cpuValue: number,
    setCpuHeadroomFunc: (value: number) => void,
    memoryValue: number,
    setMemoryHeadroomFunc: (value: number) => void,
    disabled: boolean,
    proportional = false
  ) {
    return (
      <div>
        <div style={{ paddingLeft: "10px" }}>
          <Typography style={{ fontWeight: 500 }}>{label}</Typography>
          <div
            style={{
              width: "260px",
              justifyContent: "space-between",
              display: "flex",
              paddingTop: "15px",
              paddingLeft: "10px",
            }}
          >
            {buildTextFieldFunc(cpuLabel, cpuValue, setCpuHeadroomFunc, disabled, proportional)}
            {buildTextFieldFunc(memoryLabel, memoryValue, setMemoryHeadroomFunc, disabled, proportional)}
          </div>
        </div>
      </div>
    );
  }

  function handleStaticCpuSpotChange(value: number) {
    if (headroomSpot != undefined) {
      headroomSpot.cpu = value;
      setHeadroomSpot({ ...headroomSpot });
    }
  }

  function handleStaticCpuOnDemandChange(value: number) {
    if (headroomOnDemand != undefined) {
      headroomOnDemand.cpu = value;
      setHeadroomOnDemand({ ...headroomOnDemand });
    }
  }

  function handleStaticMemorySpotChange(value: number) {
    if (headroomSpot != undefined) {
      headroomSpot.memory = value;
      setHeadroomSpot({ ...headroomSpot });
    }
  }

  function handleStaticMemoryOnDemandChange(value: number) {
    if (headroomOnDemand != undefined) {
      headroomOnDemand.memory = value;
      setHeadroomOnDemand({ ...headroomOnDemand });
    }
  }

  function handleProportionalCpuSpotChange(value: number) {
    if (headroomSpot != undefined) {
      headroomSpot.cpuClusterProportion = value;
      setHeadroomSpot(headroomSpot);
    }
  }

  function handleProportionalCpuOnDemandChange(value: number) {
    if (headroomOnDemand != undefined) {
      headroomOnDemand.cpuClusterProportion = value;
      setHeadroomOnDemand(headroomOnDemand);
    }
  }

  function handleProportionalMemorySpotChange(value: number) {
    if (headroomSpot != undefined) {
      headroomSpot.memoryClusterProportion = value;
      setHeadroomSpot(headroomSpot);
    }
  }

  function handleProportionalMemoryOnDemandChange(value: number) {
    if (headroomOnDemand != undefined) {
      headroomOnDemand.memoryClusterProportion = value;
      setHeadroomOnDemand(headroomOnDemand);
    }
  }

  function handleScheduledChange(checked: boolean) {
    setIsScheduled(checked);
    saveEnableChange();
  }

  return (
    <SnapshotWrapper inactive={isBeta} wrappedType={"paper"}>
      {componentUtils.getEmptyPaper(
        <div className="p-8">
          <Typography variant="h5" className="flex gap-2 items-center">
            Capacity Configuration
            {(isBeta || IsSnapshotServer()) && (
              <BetaIcon width={30} height={30} color={SCALEOPS_COLORS.primary.purpleBlue} />
            )}
          </Typography>

          <div style={{ paddingTop: "10px", minHeight: "550px", paddingLeft: "10px" }}>
            <Typography>Capacity Headroom</Typography>
            <Box
              style={{ paddingLeft: "20px" }}
              key={(headroomSpot?.toString() || "") + (headroomOnDemand?.toString() || "")}
            >
              <div>
                <RadioGroup
                  row
                  key={staticHeadroom.toString()}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue={staticHeadroom.toString()}
                  onChange={(event, value) => {
                    setStaticHeadroom(value);
                    saveEnableChange();
                  }}
                >
                  <FormControlLabel
                    value="Static"
                    control={<Radio />}
                    label={<Typography fontSize="small">Static Headroom</Typography>}
                  />
                </RadioGroup>
                <div>
                  <div style={{ width: "700px", paddingLeft: "33px" }}>
                    <Typography style={{ paddingBottom: "5px" }}>
                      Add a resource buffer on total cluster resources.
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "480px" }}>
                      {buildStaticResource(
                        "On Demand",
                        "CPU (cores)",
                        "Memory (GiBs)",
                        headroomOnDemand?.cpu || 0,
                        handleStaticCpuOnDemandChange,
                        headroomOnDemand?.memory || 0,
                        handleStaticMemoryOnDemandChange,
                        staticHeadroom !== "Static"
                      )}
                      {isSpotObserved &&
                        buildStaticResource(
                          "Spot",
                          "CPU (cores)",
                          "Memory (GiBs)",
                          headroomSpot?.cpu || 0,
                          handleStaticCpuSpotChange,
                          headroomSpot?.memory || 0,
                          handleStaticMemorySpotChange,
                          staticHeadroom !== "Static"
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: "20px" }}>
                <RadioGroup
                  row
                  key={staticHeadroom.toString()}
                  defaultValue={staticHeadroom.toString()}
                  onChange={(event, value) => {
                    setStaticHeadroom(value);
                    saveEnableChange();
                  }}
                >
                  <FormControlLabel
                    value="Proportional"
                    control={<Radio size="small" />}
                    label={
                      <Typography color="black" fontSize="small">
                        Proportional Headroom
                      </Typography>
                    }
                  />
                </RadioGroup>
                <div style={{ width: "700px", paddingLeft: "30px" }}>
                  <Typography style={{ paddingBottom: "5px" }}>
                    Automatically define the capacity buffer on your cluster.
                  </Typography>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "480px" }}>
                    {buildStaticResource(
                      "On Demand",
                      "CPU (%)",
                      "Memory (%)",
                      headroomOnDemand?.cpuClusterProportion || 0,
                      handleProportionalCpuOnDemandChange,
                      headroomOnDemand?.memoryClusterProportion || 0,
                      handleProportionalMemoryOnDemandChange,
                      staticHeadroom === "Static",
                      true
                    )}
                    {isSpotObserved &&
                      buildStaticResource(
                        "Spot",
                        "CPU (%)",
                        "Memory (%)",
                        headroomSpot?.cpuClusterProportion || 0,
                        handleProportionalCpuSpotChange,
                        headroomSpot?.memoryClusterProportion || 0,
                        handleProportionalMemorySpotChange,
                        staticHeadroom === "Static",
                        true
                      )}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <FormControlLabel
                    key={isScheduled?.toString()}
                    control={
                      <Checkbox checked={isScheduled} onChange={(value, checked) => handleScheduledChange(checked)} />
                    }
                    label={
                      <Typography color="black" fontSize="small">
                        Schedule headroom
                      </Typography>
                    }
                    style={{ paddingTop: "20px" }}
                  />
                </div>
                <div style={{ height: "60px" }}>
                  {
                    <div style={{ paddingLeft: "25px" }}>
                      <StyledToggleButtonGroup
                        size="small"
                        onChange={(event: React.MouseEvent<HTMLElement>, newDays: number[]) => {
                          setDays(newDays);
                          saveEnableChange();
                        }}
                        value={days}
                      >
                        {[0, 1, 2, 3, 4, 5, 6].map((index) => (
                          <ToggleButton
                            disabled={isScheduled == false}
                            sx={{ width: "30px", height: "30px" }}
                            value={index}
                            color={"info"}
                            size={"small"}
                            aria-label={convertDayNumberToDayOfWeekLong(index)}
                          >
                            {convertDayNumberToDayOfWeek(index)}
                          </ToggleButton>
                        ))}
                      </StyledToggleButtonGroup>
                      <LocalizationProvider dateAdapter={AdapterDayjs} key={beginTime?.toString() || ""}>
                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                          <div style={{ paddingLeft: "5px", paddingTop: "15px" }}>
                            {buildTextFieldForTimeSelection(beginTime, "From", setBeginTime, isScheduled == false)}
                          </div>
                          <div style={{ paddingLeft: "5px", paddingTop: "15px" }}>
                            {buildTextFieldForTimeSelection(endTime, "To", setEndTime, isScheduled == false)}
                          </div>
                        </div>
                      </LocalizationProvider>
                    </div>
                  }
                </div>
              </div>
            </Box>
          </div>
          <div className="pb-8">
            <Button
              variant="contained"
              style={{ float: "right" }}
              disabled={!saveEnable || userRole !== "Admin"}
              onClick={() => {
                saveHeadroom();
              }}
            >
              Save
            </Button>
          </div>
        </div>,
        "100%"
      )}
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </SnapshotWrapper>
  );
};

export default CapacityConfiguration;
