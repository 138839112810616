import { Typography } from "@mui/material";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import Chip, { CHIP_THEME } from "../../../components/Chip";
import DollarIcon from "../../../Icons/DollarIcon";
import RecommendationIcon from "../../../Icons/RecommendationIcon";
import RightArrowIcon from "../../../Icons/RightArrowIcon";

interface Props {
  onSelect: (group: AnalyticsGroups) => void;
}

export enum AnalyticsGroups {
  resources = "Resources",
  cost = "Cost",
}

const chipIcon = {
  Cost: <DollarIcon width={14} height={14} />,
  Resources: <RecommendationIcon width={14} height={14} />,
};

export const AnalyticsViewGroups = ({ onSelect }: Props) => {
  const [selected, setIsSelected] = useQueryParam(
    "selectedAnalyticsViewProfile",
    withDefault(StringParam, AnalyticsGroups.resources)
  );

  return (
    <div className="flex gap-2 items-center">
      <Typography variant="body2" className="w-[126px]">
        Select view profile
      </Typography>
      <RightArrowIcon width={14} height={14} />
      <div className="flex flex-row gap-2">
        {[AnalyticsGroups.resources, AnalyticsGroups.cost].map((name: AnalyticsGroups) => {
          return (
            <button
              className={name == AnalyticsGroups.resources ? "w-[120px]" : ""}
              onClick={() => {
                onSelect(name);
                setIsSelected(name);
              }}
            >
              <Chip
                label={
                  <div className={"flex gap-2 items-center justify-between px-2"}>
                    {chipIcon[name]}
                    <Typography variant="caption" fontWeight={400}>
                      {name}
                    </Typography>
                  </div>
                }
                className={
                  selected === name
                    ? "hover:bg-background-darkChipHover cursor-pointer"
                    : "hover:bg-background-chipHover cursor-pointer"
                }
                theme={selected === name ? CHIP_THEME.DARK : CHIP_THEME.DEFAULT}
              />
            </button>
          );
        })}
      </div>
    </div>
  );
};
