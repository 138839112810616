import { GridRenderCellParams } from "@mui/x-data-grid";
import { FeatureEnabled } from "../../../utils/FeaturesHelper";
import { components } from "../../../api/schema";
import WarningPopup from "../../warningPopup/WarningPopup";
import Tooltip from "../../Tooltip";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import WarningIcon from "../../../Icons/WarningIcon";
import InfoIcon from "../../../Icons/InfoIcon";

export default function IssuesColumn({
  params,
}: {
  params: GridRenderCellParams<
    components["schemas"]["DashIssues"],
    components["schemas"]["UtilsWorkload"],
    components["schemas"]["DashIssues"]
  >;
}) {
  const shouldShowWarningInNonDemoEnv =
    !FeatureEnabled("DemoVersion") &&
    params.row.oomLastTimestamp &&
    params.row.oomLastTimestamp.length > 0 &&
    params.row.oomCountLast24h &&
    params.row.oomCountLast24h > 0;

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {FeatureEnabled("DemoVersion") && params.value && <WarningPopup warnings={params.value} />}
      {shouldShowWarningInNonDemoEnv ? (
        <Tooltip
          title={
            <Typography variant="body2">
              <b>Last out of memory: </b> {dayjs.utc(params.row.oomLastTimestamp).format("YYYY-MM-DD HH:mm:ss")}
              <br />
              <b>Number of out of memory events in the last 24 hours: </b>
              {params.row.oomCountLast24h}
            </Typography>
          }
          maxWidth={1200}
        >
          <WarningIcon />
        </Tooltip>
      ) : null}
      {params.row.eventReason != undefined && params.row.auto && params.row.eventReason !== "" && (
        <Tooltip title={<>{params.row.eventReason}</>} maxWidth={500}>
          <InfoIcon />
        </Tooltip>
      )}
      {params.row.workloadErrors && params.row.workloadErrors?.length > 0 && (
        <Tooltip
          title={
            <Typography variant="body2">
              <b>Workload isn't running but consuming resources. Reason:</b>
              <br />
              <ul>
                {params.row.workloadErrors?.map((e) => (
                  <li>{e}</li>
                ))}
              </ul>
            </Typography>
          }
          maxWidth={500}
        >
          <WarningIcon />
        </Tooltip>
      )}
    </div>
  );
}
