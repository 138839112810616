import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import { AlertProps } from "@mui/material";

export const isUnallocatedRow = (wlrow: components["schemas"]["UtilsWorkload"]) => {
  return wlrow.workloadName == "__unallocated__";
};

export const WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE = (
  <>
    Click to open <b className="text-primary-purpleBlue">Workload Overview</b>.
    <br />
    <br />
    Examine workload <b>resource allocation and events</b> for a comprehensive view.
  </>
);

export const WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY = 1000;

export const getPercentageOfOptimizedReplicas = (
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>
) => (params.row.replicas ? Math.round((params.row?.optimizedReplicas ?? 0 / params.row.replicas) * 100) : 0);

export type SnackbarProps =
  | (Pick<AlertProps, "severity"> & {
      children: { message?: string };
    })
  | null;
