import clsx from "clsx";
import dayjs from "dayjs";
import prettyBytes from "pretty-bytes";
import { useState } from "react";
import { SCALEOPS_COLORS } from "../../../../colors";
import useHpaOptimizationEnabled from "../../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import { FeatureEnabled } from "../../../../utils/FeaturesHelper";
import { OPTIMIZED_REQUEST } from "../../../../utils/namesUtils";
import { GRAY_SIDE_TEXT } from "../../../../utils/styleUtils";
import { AnalyticsGroups } from "../AnalyticsViewGroups";
import { CORE_ELEMENT_CLASS_NAME, DateType, GroupBy } from "../utils";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import MultipleLinesChart from "./MultipleLinesChart";
import ResourcesOverTimeChartsContainer from "./ResourcesOverTimeChartsContainer";
import { GraphLine } from "./graphUtils";

const HAS_UNKNOWN_OWNERS_GRAPHS = false;
interface Props {
  date: DateType;
  setDate: (date: DateType) => void;
  isMulticluster?: boolean;
  viewGroup: AnalyticsGroups;
}

enum graphs {
  // Resources graphs:
  CpuMemory = "CpuAndMemory",
  RightSizingPods = "RightSizingPods",
  UnevcitablePods = "UnevcitablePods",
  AutomatedCPURequests = "AutomatedCPURequests",
  AutomatedMemoryRequests = "AutomatedMemoryRequests",
  CPUblocked = "CPUblocked",
  MemoryBlocked = "MemoryBlocked",
  HPA = "HPA",

  // Costs graphs:
  Cost = "Cost",
  Nodes = "Nodes",
  ActiveSavings = "NotScalingDownReasons",
  SavingsAvailable = "InstanceInfo",
  NumberOfPods = "NumberOfPods",
  NumberOfWorkloads = "NumberOfWorkloads",
  NumberOfWorkloadsWithHPA = "NumberOfWorkloadsWithHPA",
}

const inViewGroup = (graph: graphs, viewGroup: AnalyticsGroups) => {
  switch (viewGroup) {
    case AnalyticsGroups.resources:
      return [
        graphs.CpuMemory,
        graphs.RightSizingPods,
        graphs.UnevcitablePods,
        graphs.AutomatedCPURequests,
        graphs.AutomatedMemoryRequests,
        graphs.CPUblocked,
        graphs.MemoryBlocked,
        graphs.HPA,
      ].includes(graph);
    case AnalyticsGroups.cost:
      return [
        graphs.Cost,
        graphs.Nodes,
        graphs.ActiveSavings,
        graphs.SavingsAvailable,
        graphs.NumberOfPods,
        graphs.NumberOfWorkloads,
        graphs.NumberOfWorkloadsWithHPA,
      ].includes(graph);
    default:
      return false;
  }
};

const GraphsContainer = ({ date, setDate, isMulticluster, viewGroup }: Props) => {
  const [selectedTab] = useState<GroupBy>(GroupBy.Day);
  const isDemoVersion = FeatureEnabled("DemoVersion");
  const enableHpaOptimization = useHpaOptimizationEnabled();

  return (
    <div className="w-full">
      <div className={clsx(CORE_ELEMENT_CLASS_NAME, "p-4 rounded-tl-none flex flex-col gap-[20px]")}>
        {inViewGroup(graphs.CpuMemory, viewGroup) && (
          <ResourcesOverTimeChartsContainer
            queryParams={{
              from: date.range ? undefined : date.from,
              to: date.range ? undefined : date.to,
              range: date.range,
              groupBy: "hour",
            }}
            setDate={setDate}
            displayNameFormatter={(name: string) => {
              if (name === "Requests origin") {
                return "Original requests";
              }
              if (name.toLocaleLowerCase() === GraphLine.Recommendation) {
                return OPTIMIZED_REQUEST;
              }
              return name;
            }}
            isMulticluster={isMulticluster}
          />
        )}

        {/* Right sizing pods / Unevcitable pods: */}
        <div className="flex w-full gap-[20px]">
          {inViewGroup(graphs.RightSizingPods, viewGroup) && (
            <div className="w-[calc(50%-10px)]">
              <MultipleLinesChart
                title="Automated pods"
                elements={[
                  {
                    key: "totalNumberOfPods",
                    label: "Number of pods",
                    color: "#6C70FF",
                  },
                  {
                    key: "numberOfAutomatedPods",
                    label: "Number of optimized pods",
                    color: SCALEOPS_COLORS.main.green,
                    fill: SCALEOPS_COLORS.main.green,
                  },
                ]}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  types: ["totalNumberOfPods", "numberOfAutomatedPods"],
                  groupBy: "hour",
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
              />
            </div>
          )}
          {inViewGroup(graphs.UnevcitablePods, viewGroup) && (
            <div className="w-[calc(50%-10px)]">
              <MultipleLinesChart
                title="Unevictable pods"
                elements={[
                  {
                    key: "totalNumberOfWorkloads",
                    label: "Number of unevcitable pods",
                    color: "#6C70FF",
                  },
                  {
                    key: "totalAutomatedWorkloads",
                    label: "Number of optimized unevcitable pods",
                    color: SCALEOPS_COLORS.main.green,
                    fill: SCALEOPS_COLORS.main.green,
                  },
                ]}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  types: ["totalAutomatedWorkloads", "totalNumberOfWorkloads"],
                  groupBy: "hour",
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
              />
            </div>
          )}
        </div>

        {/* Automated requests graphs: */}
        <div className="flex w-full gap-[20px]">
          {inViewGroup(graphs.AutomatedCPURequests, viewGroup) && (
            <div className="w-[calc(50%-10px)]">
              <MultipleLinesChart
                title="Automated CPU requests"
                elements={[
                  {
                    key: "totalAutomatedCpu",
                    label: "Total automated CPU requests",
                    color: SCALEOPS_COLORS.main.green,
                    fill: SCALEOPS_COLORS.main.green,
                  },
                  {
                    key: "totalCpuUsed",
                    label: "Total CPU requests",
                    color: SCALEOPS_COLORS.main.orange,
                  },
                ]}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  types: ["totalAutomatedCpu", "totalCpuUsed"],
                  groupBy: "hour",
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
              />
            </div>
          )}
          {inViewGroup(graphs.AutomatedMemoryRequests, viewGroup) && (
            <div className="w-[calc(50%-10px)]">
              <MultipleLinesChart
                title="Automated memory requests"
                elements={[
                  {
                    key: "totalAutomatedMemory",
                    label: "Total automated memory requests",
                    color: SCALEOPS_COLORS.main.green,
                    fill: SCALEOPS_COLORS.main.green,
                  },
                  {
                    key: "totalMemoryUsed",
                    label: "Total memory requests",
                    color: SCALEOPS_COLORS.main.orange,
                  },
                ]}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  types: ["totalAutomatedMemory", "totalMemoryUsed"],
                  groupBy: "hour",
                }}
                setDate={setDate}
                YAxisFormatter={(tick) => {
                  if (Number(tick) < 0 || Number.isNaN(Number(tick))) return "";
                  return prettyBytes(Number(tick) || 0.0, {
                    bits: false,
                    binary: true,
                  });
                }}
                isMulticluster={isMulticluster}
              />
            </div>
          )}
        </div>

        {/*  CPUblocked / MemoryBlocked graphs: */}
        {inViewGroup(graphs.CPUblocked, viewGroup) && (
          <div className="flex w-full gap-[20px]">
            <div className="w-[calc(50%-10px)]">
              <MultipleLinesChart
                title="Blocked CPU allocatable by unevictable pods"
                elements={[
                  {
                    key: "blockedCpuAllocatable",
                    label: "Blocked CPU allocatable by unevictable pods",
                    color: SCALEOPS_COLORS.main.orange,
                  },
                ]}
                queryParams={{
                  from: date.range ? undefined : dayjs(date.from).startOf("day").unix() * 1000,
                  to: date.range ? undefined : dayjs(date.to).startOf("day").unix() * 1000,
                  range: date.range,
                  types: ["blockedCpuAllocatable"],
                  groupBy: "hour",
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
                hasLegend={false}
              />
            </div>
            <div className="w-[calc(50%-10px)]">
              <MultipleLinesChart
                title="Blocked memory allocatable by unevictable pods"
                elements={[
                  {
                    key: "blockedMemoryAllocatable",
                    label: "Blocked memory allocatable by unevictable pods",
                    color: SCALEOPS_COLORS.main.orange,
                  },
                ]}
                queryParams={{
                  from: date.range ? undefined : dayjs(date.from).startOf("day").unix() * 1000,
                  to: date.range ? undefined : dayjs(date.to).startOf("day").unix() * 1000,
                  range: date.range,
                  types: ["blockedMemoryAllocatable"],
                  groupBy: "hour",
                }}
                setDate={setDate}
                YAxisFormatter={(tick) => `${Number(tick)} GiB`}
                isMulticluster={isMulticluster}
                hasLegend={false}
              />
            </div>
          </div>
        )}

        {/* Cost/Nodes graphs: */}
        {inViewGroup(graphs.Cost, viewGroup) && (
          <div className="flex w-full gap-[20px]">
            <div className="w-[calc(50%-10px)]">
              <BarChart
                title={
                  <>
                    Cost <span className={GRAY_SIDE_TEXT}>(daily)</span>
                  </>
                }
                color="#FFD747"
                label="cost"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  types: ["costOverTime"],
                  groupBy: selectedTab,
                }}
                setDate={setDate}
                tooltipValuePrefix="$"
                isMulticluster={isMulticluster}
              />
            </div>
            {!isDemoVersion && (
              <div className="w-[calc(50%-10px)]">
                <BarChart
                  title="Nodes"
                  color="#FF9141"
                  label="nodes"
                  queryParams={{
                    from: date.range ? undefined : date.from,
                    to: date.range ? undefined : date.to,
                    range: date.range,
                    types: ["numberOfNodes"],
                    groupBy: selectedTab,
                  }}
                  shouldRoundValues
                  setDate={setDate}
                  isMulticluster={isMulticluster}
                />
              </div>
            )}
            {isDemoVersion && (
              <div className="w-[calc(50%-10px)]">
                <LineChart
                  title="Nodes"
                  color="#FF9141"
                  label="nodes"
                  queryParams={{
                    from: date.range ? undefined : dayjs(date.from).startOf("day").unix() * 1000,
                    to: date.range ? undefined : dayjs(date.to).startOf("day").unix() * 1000,
                    range: date.range,
                    types: ["demoNodes"],
                  }}
                  setDate={setDate}
                  xAxisInterval={5}
                  isDemoVersion
                  isMulticluster={isMulticluster}
                />
              </div>
            )}
          </div>
        )}

        {/* Savings graphs: */}
        <div className="flex w-full gap-[20px]">
          <div className="w-[calc(50%-10px)]">
            {inViewGroup(graphs.ActiveSavings, viewGroup) && (
              <BarChart
                title={
                  <>
                    Active savings <span className={GRAY_SIDE_TEXT}>(daily)</span>
                  </>
                }
                color={SCALEOPS_COLORS.main.green}
                label="savings"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  types: [enableHpaOptimization ? "activeSavingsOverTimeWithReplicas" : "activeSavingsOverTime"],
                  groupBy: selectedTab,
                }}
                setDate={setDate}
                tooltipValuePrefix="$"
                isMulticluster={isMulticluster}
              />
            )}
          </div>
          <div className="w-[calc(50%-10px)]">
            {inViewGroup(graphs.SavingsAvailable, viewGroup) && (
              <BarChart
                title={
                  <>
                    Savings available <span className={GRAY_SIDE_TEXT}>(daily)</span>
                  </>
                }
                color={SCALEOPS_COLORS.main.red}
                label="savings"
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  types: [enableHpaOptimization ? "availableSavingsOverTimeWithReplicas" : "availableSavingsOverTime"],
                  groupBy: selectedTab,
                }}
                setDate={setDate}
                tooltipValuePrefix="$"
                shouldRoundValues
                isMulticluster={isMulticluster}
              />
            )}
          </div>
        </div>

        {/* Number of pods/Number of workloads: */}
        <div className="flex w-full gap-[20px]">
          {inViewGroup(graphs.NumberOfPods, viewGroup) && (
            <div className="w-[calc(50%-10px)]">
              <MultipleLinesChart
                title="Number of pods"
                elements={[
                  {
                    key: "totalNumberOfPods",
                    label: "Total number of pods",
                    color: "#6C70FF",
                  },
                  {
                    key: "numberOfAutomatedPods",
                    label: "Total automated pods",
                    color: SCALEOPS_COLORS.main.green,
                    fill: SCALEOPS_COLORS.main.green,
                  },
                ]}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  types: ["totalNumberOfPods", "numberOfAutomatedPods"],
                  groupBy: "hour",
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
              />
            </div>
          )}
          {inViewGroup(graphs.NumberOfWorkloads, viewGroup) && (
            <div className="w-[calc(50%-10px)]">
              <MultipleLinesChart
                title="Number of workloads"
                elements={[
                  {
                    key: "totalNumberOfWorkloads",
                    label: "Total number of workloads",
                    color: "#6C70FF",
                  },
                  {
                    key: "totalAutomatedWorkloads",
                    label: "Total automated workloads",
                    color: SCALEOPS_COLORS.main.green,
                    fill: SCALEOPS_COLORS.main.green,
                  },
                ]}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  types: ["totalAutomatedWorkloads", "totalNumberOfWorkloads"],
                  groupBy: "hour",
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
              />
            </div>
          )}
        </div>
        {inViewGroup(graphs.NumberOfWorkloadsWithHPA, viewGroup) && enableHpaOptimization && (
          <div className="flex w-full gap-[20px]">
            <div className="w-[calc(50%-10px)]">
              <MultipleLinesChart
                title="Workloads with HPA"
                elements={[
                  {
                    key: "totalNumberOfWorkloadsWithHpa",
                    label: "Number of workloads with HPA",
                    color: "#6C70FF",
                  },
                  {
                    key: "totalHpaAutomatedWorkloads",
                    label: "Number of automated HPA workloads",
                    color: SCALEOPS_COLORS.main.green,
                    fill: SCALEOPS_COLORS.main.green,
                  },
                ]}
                queryParams={{
                  from: date.range ? undefined : date.from,
                  to: date.range ? undefined : date.to,
                  range: date.range,
                  types: ["totalHpaAutomatedWorkloads", "totalNumberOfWorkloadsWithHpa"],
                  groupBy: "hour",
                }}
                setDate={setDate}
                isMulticluster={isMulticluster}
              />
            </div>
          </div>
        )}

        {HAS_UNKNOWN_OWNERS_GRAPHS && (
          <div className="flex w-full gap-[20px]">
            <ResourcesOverTimeChartsContainer
              queryParams={{
                from: date.range ? undefined : date.from,
                to: date.range ? undefined : date.to,
                range: date.range,
                groupBy: selectedTab,
              }}
              setDate={setDate}
              displayNameFormatter={(name: string) => {
                if (name.toLocaleLowerCase().includes("usage")) {
                  return "Usage";
                }
                if (name.toLocaleLowerCase().includes("requests")) {
                  return "Requests";
                }
                return name;
              }}
              isMulticluster={isMulticluster}
              showUnknownGraphs
            />
          </div>
        )}
        {/* HPA graphs: */}
        {inViewGroup(graphs.HPA, viewGroup) && enableHpaOptimization && (
          <>
            <div className="flex w-full gap-[20px]">
              <div className="w-[calc(50%-10px)]">
                <MultipleLinesChart
                  title="Automated HPA CPU requests"
                  elements={[
                    {
                      key: "totalHpaCpuRequests",
                      label: "Number of HPA CPU requests",
                      color: SCALEOPS_COLORS.main.orange,
                    },
                    {
                      key: "totalAutomatedHpaCpuRequests",
                      label: "Number of optimized HPA CPU requests",
                      color: SCALEOPS_COLORS.main.green,
                      fill: SCALEOPS_COLORS.main.green,
                    },
                  ]}
                  queryParams={{
                    from: date.range ? undefined : date.from,
                    to: date.range ? undefined : date.to,
                    range: date.range,
                    types: ["totalAutomatedHpaCpuRequests", "totalHpaCpuRequests"],
                    groupBy: "hour",
                  }}
                  setDate={setDate}
                  isMulticluster={isMulticluster}
                />
              </div>
              <div className="w-[calc(50%-10px)]">
                <MultipleLinesChart
                  title="Automated HPA memory requests"
                  elements={[
                    {
                      key: "totalHpaMemoryRequests",
                      label: "Total HPA memory requests",
                      color: SCALEOPS_COLORS.main.orange,
                    },
                    {
                      key: "totalAutomatedHpaMemoryRequests",
                      label: "Total automated HPA memory requests",
                      color: SCALEOPS_COLORS.main.green,
                      fill: SCALEOPS_COLORS.main.green,
                    },
                  ]}
                  queryParams={{
                    from: date.range ? undefined : date.from,
                    to: date.range ? undefined : date.to,
                    range: date.range,
                    types: ["totalAutomatedHpaMemoryRequests", "totalHpaMemoryRequests"],
                    groupBy: "hour",
                  }}
                  setDate={setDate}
                  YAxisFormatter={(tick) => {
                    if (Number(tick) < 0 || Number.isNaN(Number(tick))) return "";
                    return prettyBytes(Number(tick) || 0.0, {
                      bits: false,
                      binary: true,
                    });
                  }}
                  isMulticluster={isMulticluster}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GraphsContainer;
