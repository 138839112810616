import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import CircleUpArrowIcon from "../Icons/CircleUpArrowIcon";
import { useMainContext } from "../MainContext";
import { GetVersion, GetVersionResponse } from "../api/fetcher";
import CodeSnippet, { THEME } from "../components/CodeSnippet";
import Dialog from "../components/Dialog";
import Tooltip from "../components/Tooltip";
import { GetConfig } from "../utils/ConfigHelper";
import { LINK_CLASS_NAME } from "../utils/styleUtils";
import { documentationUrl } from "../utils/urlUtils";

const { queryKey, queryFn } = GetVersion();

const UpgradeVersion = () => {
  const config = GetConfig();
  const { currentCluster } = useMainContext();
  const [isOpen, setIsOpen] = useState(false);
  const [releaseNodeUrl, setReleaseNodeUrl] = useState("");

  const { data, error } = useQuery<GetVersionResponse, Error>({
    queryKey: [queryKey, currentCluster],
    queryFn: queryFn,
  });

  useEffect(() => {
    if (data?.nextVersion) {
      const releaseNodeUrlValue = documentationUrl({
        path: "release-notes",
        subPath: data.nextVersion?.replace(/[^a-zA-Z0-9]/g, ""),
        token: config.token,
      });

      setReleaseNodeUrl(releaseNodeUrlValue);
    }
  }, [data]);

  if (error || !data || !data.shouldUpdate) {
    return null;
  }

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title={
          <div className="flex items-end gap-1">
            <span>Upgrade ScaleOps version</span>
            <span className="bg-main-green text-white rounded-full px-2 text-[0.9rem] py-[0.1rem]">
              {data.nextVersion}
            </span>
          </div>
        }
        dialogContentStyle={{
          padding: "0px",
        }}
        fullWidth={false}
      >
        <div className="p-6 flex flex-col gap-2">
          <CodeSnippet codeSnippet={String(data.upgradeCommand) ?? ""} theme={THEME.light} />
          <Typography variant="caption" className="mt-2">
            <div className="mt-2 ml-1">
              For more details please read the{" "}
              <a target="_blank" href={releaseNodeUrl} className={LINK_CLASS_NAME}>
                Release Notes
              </a>
              .
            </div>
          </Typography>
        </div>
      </Dialog>
      <Tooltip
        title={
          <>
            <p>
              New version available. <span className="text-guideline-darkGreen">({data.nextVersion})</span>
            </p>
            <span>
              <b>Click to get more info.</b>
            </span>
          </>
        }
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <CircleUpArrowIcon
          width={20}
          height={20}
          className="text-main-green hover:text-guideline-darkGreen rounded-full cursor-pointer"
        />
      </Tooltip>
    </>
  );
};

export default UpgradeVersion;
