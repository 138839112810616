import { chartStyle } from "../../utils/styleUtils";

export const SYNC_ID = "analyticsPageSyncId";

export enum RightsizeGraphLine {
  Pods = "pods",
  Total = "total",
}

export enum UnevictableGraphLine {
  Pods = "pods",
  Total = "total",
}

export enum GraphLine {
  Usage = "usage",
  RequestsOrigin = "requestsOrigin",
  Requests = "requests",
  Recommendation = "recommendation",
  Allocatable = "allocatable",
  Savings = "savings",
}

export const graphLineNames = {
  [GraphLine.Usage]: "Usage",
  [GraphLine.Requests]: "Requests",
  [GraphLine.RequestsOrigin]: "Original requests",
  [GraphLine.Recommendation]: "Recommendation",
  [GraphLine.Allocatable]: "Allocatable",
  [GraphLine.Savings]: "Savings",
};

export const LineStyle = {
  [GraphLine.Usage]: {
    type: chartStyle.usage.type,
    fill: chartStyle.usage.fill,
    fillOpacity: chartStyle.usage.fillOpacity,
    stroke: chartStyle.usage.stroke,
    strokeWidth: chartStyle.usage.strokeWidth,
  },
  [GraphLine.Requests]: {
    type: chartStyle.request.type,
    fill: "white",
    fillOpacity: 1,
    stroke: chartStyle.request.stroke,
    strokeWidth: chartStyle.request.strokeWidth,
  },
  [GraphLine.RequestsOrigin]: {
    type: chartStyle.RequestsOrigin.type,
    fill: chartStyle.RequestsOrigin.fill,
    fillOpacity: chartStyle.RequestsOrigin.fillOpacity,
    stroke: chartStyle.RequestsOrigin.stroke,
    strokeWidth: chartStyle.RequestsOrigin.strokeWidth,
  },
  [GraphLine.Recommendation]: {
    type: chartStyle.Recommendation.type,
    fill: chartStyle.Recommendation.fill,
    fillOpacity: chartStyle.Recommendation.fillOpacity,
    stroke: chartStyle.Recommendation.stroke,
    strokeWidth: chartStyle.Recommendation.strokeWidth,
  },
  [GraphLine.Allocatable]: {
    type: chartStyle.Allocatable.type,
    fill: chartStyle.Allocatable.fill,
    fillOpacity: chartStyle.Allocatable.fillOpacity,
    stroke: chartStyle.Allocatable.stroke,
    strokeWidth: chartStyle.Allocatable.strokeWidth,
  },
  [GraphLine.Savings]: {
    type: chartStyle.Savings.type,
    fill: chartStyle.Savings.fill,
    fillOpacity: chartStyle.Savings.fillOpacity,
    stroke: chartStyle.Savings.stroke,
    strokeWidth: chartStyle.Savings.strokeWidth,
  },
  [RightsizeGraphLine.Pods]: {
    type: chartStyle.RequestsOrigin.type,
    fill: chartStyle.RequestsOrigin.fill,
    fillOpacity: chartStyle.RequestsOrigin.fillOpacity,
    stroke: chartStyle.RequestsOrigin.stroke,
    strokeWidth: chartStyle.RequestsOrigin.strokeWidth,
  },
  [RightsizeGraphLine.Total]: {
    type: chartStyle.request.type,
    fill: chartStyle.request.fill,
    fillOpacity: chartStyle.request.fillOpacity,
    stroke: chartStyle.request.stroke,
    strokeWidth: chartStyle.request.strokeWidth,
  },
};

export enum ResourceType {
  CPU = "cpu",
  Memory = "memory",
  Rightsize = "rightsize",
  Unevictable = "unevictable",
}
