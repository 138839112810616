import { IconProps } from "./utils/utils";

const RightSizingIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 20 20" {...props}>
      <path d="M10 7.62939e-05C4.48438 7.62939e-05 0 4.48446 0 10.0001C0 15.5157 4.48438 20.0001 10 20.0001C15.5156 20.0001 20 15.5157 20 10.0001C20 4.48446 15.5156 7.62939e-05 10 7.62939e-05ZM10 18.7501C5.17578 18.7501 1.25 14.8243 1.25 10.0001C1.25 5.17586 5.17578 1.25008 10 1.25008C14.8242 1.25008 18.75 5.17586 18.75 10.0001C18.75 14.8243 14.8242 18.7501 10 18.7501Z" />
      <path d="M14.5161 15.0001C14.4005 15.0003 14.2887 14.9528 14.2011 14.8663L5.16888 5.9774C5.07146 5.88147 5.01122 5.74504 5.00142 5.59812C4.99161 5.45121 5.03304 5.30583 5.11658 5.19398C5.20013 5.08213 5.31896 5.01297 5.44692 5.00171C5.57488 4.99045 5.7015 5.03801 5.79891 5.13394L14.8311 14.0228C14.9062 14.0967 14.9597 14.1952 14.9845 14.3051C15.0093 14.415 15.0042 14.5309 14.9699 14.6374C14.9356 14.7438 14.8737 14.8357 14.7926 14.9005C14.7114 14.9654 14.6149 15.0001 14.5161 15.0001Z" />
      <path d="M5.48387 9.81491C5.35555 9.81487 5.23249 9.75633 5.14176 9.65215C5.05102 9.54797 5.00003 9.40669 5 9.25936V5.55567C5.00003 5.40834 5.05102 5.26705 5.14176 5.16287C5.23249 5.0587 5.35555 5.00015 5.48387 5.00011H8.70965C8.83798 5.00011 8.96105 5.05864 9.0518 5.16283C9.14254 5.26702 9.19352 5.40833 9.19352 5.55567C9.19352 5.70301 9.14254 5.84432 9.0518 5.9485C8.96105 6.05269 8.83798 6.11122 8.70965 6.11122H5.96773V9.25936C5.9677 9.40669 5.91671 9.54797 5.82597 9.65215C5.73524 9.75633 5.61219 9.81487 5.48387 9.81491Z" />
      <path d="M14.5161 15.0001H11.2903C11.162 15.0001 11.0389 14.9416 10.9482 14.8374C10.8574 14.7332 10.8064 14.5919 10.8064 14.4445C10.8064 14.2972 10.8574 14.1559 10.9482 14.0517C11.0389 13.9475 11.162 13.889 11.2903 13.889H14.0322V10.7409C14.0322 10.5935 14.0832 10.4522 14.1739 10.348C14.2647 10.2438 14.3878 10.1853 14.5161 10.1853C14.6444 10.1853 14.7675 10.2438 14.8582 10.348C14.949 10.4522 15 10.5935 15 10.7409V14.4445C14.9999 14.5919 14.9489 14.7332 14.8582 14.8373C14.7675 14.9415 14.6444 15.0001 14.5161 15.0001Z" />
    </svg>
  );
};

export default RightSizingIcon;
