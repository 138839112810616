import { useEffect } from "react";
import ChartTooltipElement from "../../../../components/ChartTooltipElement";
import ChartTooltipTime from "../../../../components/ChartTooltipTime";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../../../utils/styleUtils";
import FreezeTooltipWarning from "../../../Analytics/AnalyticsV2/Graphs/hooks/FreezeTooltipWarning";
import { TooltipTrigger, UpdateActiveTooltips } from "../../../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../../Analytics/AnalyticsV2/Graphs/hooks/utils";

interface CustomTooltipProps {
  active?: boolean;
  payload?: { value: string | number; name?: string }[];
  label?: string;
  displayLabel?: string;
  color: string;
  tooltipValueSuffix?: string;
  hasBooleanValue?: boolean;
  booleanOptions?: {
    true: string;
    false: string;
  };
  frozenTooltipType?: FrozenTooltipType;
  tooltipTrigger?: TooltipTrigger;
  tooltipId?: string;
  updateActiveTooltips?: UpdateActiveTooltips;
}
const CustomTooltip = ({
  active,
  payload,
  label,
  displayLabel,
  color,
  tooltipValueSuffix,
  hasBooleanValue,
  booleanOptions = {
    true: "Yes",
    false: "No",
  },
  frozenTooltipType,
  tooltipTrigger,
  tooltipId,
  updateActiveTooltips,
}: CustomTooltipProps) => {
  useEffect(() => {
    updateActiveTooltips && active && updateActiveTooltips(String(tooltipId), true);

    return () => {
      updateActiveTooltips && updateActiveTooltips(String(tooltipId), false);
    };
  }, [active, updateActiveTooltips]);

  if (active && payload && payload.length) {
    let value;
    let booleanValue;

    switch (true) {
      case hasBooleanValue:
        booleanValue =
          payload[0].value === "true" || Number(payload[0].value) > 0 ? booleanOptions.true : booleanOptions.false;
        value = `${booleanValue}${tooltipValueSuffix ?? ""}`;
        break;
      default:
        value = `${payload[0].value}${tooltipValueSuffix ?? ""}`;
    }

    return (
      <div className={TOOLTIP_WRAPPER_CLASS_NAME}>
        {label && <ChartTooltipTime timestamp={label} />}
        <ChartTooltipElement color={color} value={value} label={displayLabel ?? payload[0].name} />
        <FreezeTooltipWarning tooltipTrigger={tooltipTrigger} frozenTooltipType={frozenTooltipType} />
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
