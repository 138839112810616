import clsx from "clsx";
import { useState } from "react";
import { NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import RangePicker, { RangeOptions } from "../../../components/RangePicker/RangePicker";
import { GAPPED_COLUMN } from "../../../utils/styleUtils";
import AnalyticFilters from "./AnalyticFilters";
import { AnalyticsGroups, AnalyticsViewGroups } from "./AnalyticsViewGroups";
import GraphsContainer from "./Graphs/GraphsContainer";
import TopKContainer from "./Graphs/TopKContainer";
import NumberOfElementsFilter from "./NumberOfElementsFilter";
import SelectAggregationView from "./SelectAggregationView";
import { DateType, getFromDate } from "./utils";
import { PreBuiltViewGroups, ViewGroups } from "./ViewGroups";

export const HAS_MULTILEVEL_ANALYTICS = false;

interface Props {
  isMultiCluster?: boolean;
  hasTopKContainer?: boolean;
  hasGraphsContainer?: boolean;
  hasAnalyticsView?: boolean;
}

const AnalyticsContainer = ({ isMultiCluster, hasTopKContainer, hasGraphsContainer, hasAnalyticsView }: Props) => {
  const [currentEpoch] = useState(Date.now());
  const [fromDate, setFromDate] = useQueryParam(
    "fromDate",
    withDefault(NumberParam, getFromDate(hasTopKContainer ? 2 : 6, currentEpoch))
  );
  const [toDate, setToDate] = useQueryParam("toDate", withDefault(NumberParam, currentEpoch));
  const [range, setRange] = useQueryParam("range", withDefault(StringParam, hasTopKContainer ? "3d" : "7d"));

  const date: DateType = {
    from: fromDate,
    to: toDate,
    range: range,
  };

  const setDate = (date: DateType) => {
    setFromDate(date.from);
    setToDate(date.to);
    setRange(date.range);
  };

  const [viewChip, setViewChip] = useQueryParam(
    "selectedViewProfile",
    withDefault(StringParam, PreBuiltViewGroups.highlight)
  );

  const [analyticsViewChip, setAnalyticsViewChip] = useQueryParam(
    "selectedAnalyticsViewProfile",
    withDefault(StringParam, AnalyticsGroups.resources)
  );

  return (
    <div className={GAPPED_COLUMN}>
      <div className="rounded-lg flex flex-wrap gap-2 justify-between items-center relative px-4 py-4 bg-white">
        {hasTopKContainer && (
          <ViewGroups
            onSelect={(group: PreBuiltViewGroups) => {
              setViewChip(group);
            }}
          />
        )}
        <div className={"flex items-center justify-end gap-2"}>
          {hasAnalyticsView && (
            <AnalyticsViewGroups
              onSelect={(group: AnalyticsGroups) => {
                setAnalyticsViewChip(group);
              }}
            />
          )}
        </div>
        <div className={"flex items-center justify-end gap-2"}>
          {hasTopKContainer && !isMultiCluster && <SelectAggregationView />}
          <div className="">
            {isMultiCluster && <AnalyticFilters isMultiCluster={isMultiCluster} hasLeftBorder={!!hasTopKContainer} />}
          </div>
          {hasTopKContainer && <NumberOfElementsFilter />}
          <RangePicker
            date={date}
            setDate={setDate}
            rangeOptions={hasTopKContainer ? RangeOptions.SHORT : RangeOptions.LONG}
            minDateRangeInDays={hasTopKContainer ? 14 : undefined}
          />
        </div>
      </div>
      <div className={clsx("items-center", GAPPED_COLUMN)}>
        {/* Removed due to New analytics design {!hasTopKContainer && <TopMetrics date={date} isMulticluster={isMultiCluster} />} */}
        {hasTopKContainer && (
          <div className="w-full">
            <TopKContainer
              date={date}
              setDate={setDate}
              isMulticluster={isMultiCluster}
              viewGroup={viewChip as PreBuiltViewGroups}
            />
          </div>
        )}
        {hasGraphsContainer && (
          <GraphsContainer
            date={date}
            setDate={setDate}
            isMulticluster={isMultiCluster}
            viewGroup={analyticsViewChip as AnalyticsGroups}
          />
        )}
      </div>
    </div>
  );
};

export default AnalyticsContainer;
