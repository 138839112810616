import { Typography } from "@mui/material";
import { GetConfig } from "../../../utils/ConfigHelper";

export const DOCS_BASE_URL = "https://docs.scaleops.com";

interface Props {
  relativePath: string;
  linkTitle: string;
}

const PermissionsDocsLink = ({ relativePath, linkTitle }: Props) => {
  const conf = GetConfig();

  return (
    <Typography variant="caption" className="text-text-lightBlack">
      For more information, visit our{" "}
      <a
        href={`${DOCS_BASE_URL}${relativePath}?token=${conf.token ?? ""}`}
        target="_blank"
        className="text-main-linkBlue hover:underline"
        style={{
          fontWeight: 600,
        }}
      >
        {linkTitle}.
      </a>
    </Typography>
  );
};

export default PermissionsDocsLink;
