import { Typography } from "@mui/material";
import clsx from "clsx";
import { cloneElement } from "react";

const ICON_SIZE = 10;

interface Props {
  label: React.ReactNode;
  value: React.ReactNode;
  icon?: React.ReactElement;
  className?: string;
  style?: React.CSSProperties;
}

const SavingsRow = ({ label, value, icon, className, style }: Props) => {
  return (
    <Typography variant="caption" className={clsx(className, "flex items-start justify-between")} style={style}>
      <div className="flex items-center gap-2">
        {!!icon &&
          cloneElement(icon, {
            width: ICON_SIZE,
            height: ICON_SIZE,
          })}
        {label}
      </div>
      <div>{value}</div>
    </Typography>
  );
};

export default SavingsRow;
