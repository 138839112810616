import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import CheckedIcon from "../../Icons/CheckedIcon";
import { components } from "../../api/schema";
import { getDataGridSx } from "../../utils/styleUtils";
import CustomColumnsFilterButton from "../CustomColumnsFilterButton";
import MultiSelect from "../MultiSelect";
import ExportCSV, { HAS_EXPORT_TABLE_AS_CSV } from "../exportCSV/ExportCSV";
import CustomHeader from "./CustomHeader";
import SearchNodeFilter from "./SearchNodeFilter";
import UsageAndRequestChart, { Elements } from "./UsageAndRequestChart";
import { DIFF_DEFAULT_PROPS, formatXDigits, SimpleEllipsisWithTooltip } from "./Utils";

type CSVExportType = {
  name: string;
  cost: number;
  instanceTypesDisplayName: string;
  numNodes: { min: number; current: number; max: number };
  cpuDiff: number;
  memoryDiff: number;
  cpuRequests: number;
  cpuAllocatable: number;
  cpuUsage: number;
  memoryRequests: number;
  memoryAllocatable: number;
  memoryUsage: number;
  id: string;
};

const secondsToHours = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const days = Math.floor(hours / 24);
  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  }
  if (minutes > 0) {
    return `${minutes}m`;
  }
  return `${seconds}s`;
};

enum ProvisionersColumns {
  // Name = "Name",
  MonthlyCost = "Monthly Cost",
  InstanceTypes = "Instance Types",
  CPU = "CPU",
  Memory = "Memory",
  Consolidate = "Consolidate",
  Expiration = "Expiration",
  Emptiness = "Emptiness",
  CpuRequestVsAllocatableDiff = "CPU (Request vs Allocatable)",
  CpuUsageVsAllocatableDiff = "CPU (Usage vs Allocatable)",
  MemoryRequestVsAllocatableDiff = "Memory (Request vs Allocatable)",
  MemoryUsageVsAllocatableDiff = "Memory (Usage vs Allocatable)",
}

const initialColumns = [
  ProvisionersColumns.MonthlyCost,
  ProvisionersColumns.InstanceTypes,
  // ProvisionersColumns.CPU,
  // ProvisionersColumns.Memory,
  ProvisionersColumns.CpuRequestVsAllocatableDiff,
  ProvisionersColumns.CpuUsageVsAllocatableDiff,
  ProvisionersColumns.MemoryRequestVsAllocatableDiff,
  ProvisionersColumns.MemoryUsageVsAllocatableDiff,
  // ProvisionersColumns.Consolidate,
  // ProvisionersColumns.Expiration,
  // ProvisionersColumns.Emptiness,
];

const availableColumns = [
  ProvisionersColumns.MonthlyCost,
  ProvisionersColumns.InstanceTypes,
  // ProvisionersColumns.CPU,
  // ProvisionersColumns.Memory,
  ProvisionersColumns.CpuRequestVsAllocatableDiff,
  ProvisionersColumns.CpuUsageVsAllocatableDiff,
  ProvisionersColumns.MemoryRequestVsAllocatableDiff,
  ProvisionersColumns.MemoryUsageVsAllocatableDiff,
  ProvisionersColumns.Consolidate,
  ProvisionersColumns.Expiration,
  ProvisionersColumns.Emptiness,
];

export type NodeGroupRowEntry = {
  name: string;
  instanceTypes: string[];
  instanceTypesDisplayName: string;
  cpuDiff: number;
  memoryDiff: number;
  numNodes: { min: number; current: number; max: number };
  cpuRequests: number;
  cpuAllocatable: number;
  cpuUsage: number;
  memoryRequests: number;
  memoryAllocatable: number;
  memoryUsage: number;
  cost: number;
  consolidate?: boolean;
  expirationInSeconds?: number | null;
  emptinessInSeconds?: number | null;
};

type NodeProvisionerResponseTypeSchema = components["schemas"]["UtilsProvisionerInfo"];

const renderNameCell = (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
  return <SimpleEllipsisWithTooltip text={params.row.name} />;
};

const renderCostCell = (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
  return <p>${formatXDigits(params.row.cost)}</p>;
};

const renderInstanceTypesCell = (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
  return <SimpleEllipsisWithTooltip text={params.row.instanceTypes?.join(", ")} />;
};

const renderMemoryCell = (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
  if (params.row.memoryAllocatable === 0) {
    return null;
  }
  return (
    <UsageAndRequestChart
      usage={Math.round((params.row.memoryUsage / params.row.memoryAllocatable) * 100)}
      request={Math.round((params.row.memoryRequests / params.row.memoryAllocatable) * 100)}
      tooltipData={{
        usage: params.row.memoryUsage,
        request: params.row.memoryRequests,
        allocatable: params.row.memoryAllocatable,
      }}
      elementToDisplay={[Elements.Usage, Elements.Request]}
    />
  );
};

const renderCpuCell = (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
  if (params.row.cpuAllocatable === 0) {
    return null;
  }
  return (
    <UsageAndRequestChart
      usage={Math.round((params.row.cpuUsage / params.row.cpuAllocatable) * 100)}
      request={Math.round((params.row.cpuRequests / params.row.cpuAllocatable) * 100)}
      tooltipData={{
        usage: params.row.cpuUsage,
        request: params.row.cpuRequests,
        allocatable: params.row.cpuAllocatable,
      }}
      elementToDisplay={[Elements.Usage, Elements.Request]}
    />
  );
};

const getColumns = (selectedColumns: (string | undefined)[]) => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: 300,
      type: "string",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: renderNameCell,
    },
    {
      field: "cost",
      hide: !selectedColumns.includes(ProvisionersColumns.MonthlyCost),
      headerName: "Monthly Cost",
      description: "Monthly Cost",
      flex: 2,
      minWidth: 150,
      type: "number",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: renderCostCell,
    },
    {
      field: "instanceTypesDisplayName",
      hide: !selectedColumns.includes(ProvisionersColumns.InstanceTypes),
      headerName: "Instance Types",
      description: "Instance Types",
      flex: 2,
      minWidth: 150,
      type: "string",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: renderInstanceTypesCell,
    },
    {
      field: "cpuDiff",
      hide: !selectedColumns.includes(ProvisionersColumns.CPU),
      headerName: "CPU",
      description: "Current CPU requests",
      flex: 1.3,
      minWidth: 270,
      type: "string",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: renderCpuCell,
    },
    {
      field: "memoryDiff",
      hide: !selectedColumns.includes(ProvisionersColumns.Memory),
      headerName: "Memory",
      description: "Current Memory requests",
      flex: 1.3,
      minWidth: 270,
      type: "string",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: renderMemoryCell,
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "cpuRequestVsAllocatableDiff",
        headerName: "cpuRequestVsAllocatableDiff",
        hide: !selectedColumns.includes(ProvisionersColumns.CpuRequestVsAllocatableDiff),
        renderHeader: () => <CustomHeader title="CPU Request" tooltipContent="Request vs Allocatable" />,
        renderCell: (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
          if (params.row.cpuAllocatable === 0) {
            return null;
          }
          return (
            <UsageAndRequestChart
              usage={Math.round((params.row.cpuUsage / params.row.cpuAllocatable) * 100)}
              request={Math.round((params.row.cpuRequests / params.row.cpuAllocatable) * 100)}
              tooltipData={{
                usage: params.row.cpuUsage,
                request: params.row.cpuRequests,
                allocatable: params.row.cpuAllocatable,
              }}
              elementToDisplay={[Elements.Request]}
              showMetricsTitles={false}
              showAllocatableBellow
            />
          );
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "cpuUsageVsAllocatableDiff",
        headerName: "cpuUsageVsAllocatableDiff",
        hide: !selectedColumns.includes(ProvisionersColumns.CpuUsageVsAllocatableDiff),
        renderHeader: () => <CustomHeader title="CPU Usage" tooltipContent="Usage vs Allocatable" />,
        renderCell: (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
          if (params.row.cpuAllocatable === 0) {
            return null;
          }
          return (
            <UsageAndRequestChart
              usage={Math.round((params.row.cpuUsage / params.row.cpuAllocatable) * 100)}
              request={Math.round((params.row.cpuRequests / params.row.cpuAllocatable) * 100)}
              tooltipData={{
                usage: params.row.cpuUsage,
                request: params.row.cpuRequests,
                allocatable: params.row.cpuAllocatable,
              }}
              elementToDisplay={[Elements.Usage]}
              showMetricsTitles={false}
              showAllocatableBellow
            />
          );
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "memoryRequestVsAllocatableDiff",
        headerName: "memoryRequestVsAllocatableDiff",
        hide: !selectedColumns.includes(ProvisionersColumns.MemoryRequestVsAllocatableDiff),
        renderHeader: () => <CustomHeader title="Memory Request" tooltipContent="Request vs Allocatable" />,
        renderCell: (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
          if (params.row.memoryAllocatable === 0) {
            return null;
          }
          return (
            <UsageAndRequestChart
              usage={Math.round((params.row.memoryUsage / params.row.memoryAllocatable) * 100)}
              request={Math.round((params.row.memoryRequests / params.row.memoryAllocatable) * 100)}
              tooltipData={{
                usage: params.row.memoryUsage,
                request: params.row.memoryRequests,
                allocatable: params.row.memoryAllocatable,
              }}
              elementToDisplay={[Elements.Request]}
              showMetricsTitles={false}
              showAllocatableBellow
            />
          );
        },
      },
    },
    {
      ...DIFF_DEFAULT_PROPS,
      ...{
        field: "memoryUsageVsAllocatableDiff",
        headerName: "memoryUsageVsAllocatableDiff",
        hide: !selectedColumns.includes(ProvisionersColumns.MemoryUsageVsAllocatableDiff),
        ...DIFF_DEFAULT_PROPS,
        ...{
          renderHeader: () => <CustomHeader title="Memory Usage" tooltipContent="Usage vs Allocatable" />,
          renderCell: (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
            if (params.row.memoryAllocatable === 0) {
              return null;
            }
            return (
              <UsageAndRequestChart
                usage={Math.round((params.row.memoryUsage / params.row.memoryAllocatable) * 100)}
                request={Math.round((params.row.memoryRequests / params.row.memoryAllocatable) * 100)}
                tooltipData={{
                  usage: params.row.memoryUsage,
                  request: params.row.memoryRequests,
                  allocatable: params.row.memoryAllocatable,
                }}
                elementToDisplay={[Elements.Usage]}
                showMetricsTitles={false}
              />
            );
          },
        },
      },
    },
    {
      field: "consolidate",
      hide: !selectedColumns.includes(ProvisionersColumns.Consolidate),
      headerName: "Consolidate",
      description: "Consolidate",
      flex: 1,
      minWidth: 150,
      type: "boolean",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
        return params.row.consolidate ? <CheckedIcon className="text-main-green" /> : "No";
      },
    },
    {
      field: "expirationInSeconds",
      hide: !selectedColumns.includes(ProvisionersColumns.Expiration),
      headerName: "Expiration",
      description: "Expiration",
      flex: 1,
      // width: 150,
      type: "number",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
        return params.row.expirationInSeconds ? secondsToHours(params.row.expirationInSeconds) : "N/A";
      },
    },
    {
      field: "emptinessInSeconds",
      hide: !selectedColumns.includes(ProvisionersColumns.Emptiness),
      headerName: "Emptiness",
      description: "Emptiness",
      flex: 1,
      // width: 150,
      type: "number",
      align: "center",
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params: GridRenderCellParams<string, NodeGroupRowEntry, string>) => {
        return params.row.emptinessInSeconds ? secondsToHours(params.row.emptinessInSeconds) : "N/A";
      },
    },
  ];

  return columns;
};

interface Props {
  nodeProvisioners: NodeProvisionerResponseTypeSchema[];
  isLoading: boolean;
}

const parseIntoRows = (nodeProvisioners: NodeProvisionerResponseTypeSchema[]): NodeGroupRowEntry[] => {
  return nodeProvisioners.map((nodeProvisioner) => {
    return {
      name: nodeProvisioner.name,
      numNodes: {
        current: 0,
        min: 0,
        max: 0,
      },
      instanceTypes: nodeProvisioner.instanceTypes,
      instanceTypesDisplayName: "",
      cpuRequests: nodeProvisioner.cpuRequest,
      memoryRequests: nodeProvisioner.memoryRequest,
      cpuAllocatable: nodeProvisioner.cpuAllocatable,
      cpuUsage: nodeProvisioner.cpuUsage,
      cpuDiff: 0,
      memoryDiff: 0,
      memoryAllocatable: nodeProvisioner.memoryAllocatable,
      memoryUsage: nodeProvisioner.memoryUsage,
      cost: nodeProvisioner.cost,
      consolidate: nodeProvisioner.consolidate,
      expirationInSeconds: nodeProvisioner.expirationInSeconds,
      emptinessInSeconds: nodeProvisioner.emptinessInSeconds,
    };
  });
};

function NodeProvisionersTab({ nodeProvisioners, isLoading }: Props) {
  const [rows, setRows] = useState<NodeGroupRowEntry[]>([]);
  const [searchTerm] = useQueryParam("setSearchTerm", StringParam);
  const [selectedColumns, setSelectedColumns] = useState<(string | undefined)[]>(initialColumns);

  useEffect(() => {
    let rowsToDisplay = parseIntoRows(nodeProvisioners);

    if (searchTerm && searchTerm.length > 0) {
      rowsToDisplay = rowsToDisplay.filter((row) => row.name.includes(searchTerm));
    }

    rowsToDisplay = rowsToDisplay.sort((a, b) => a.name.localeCompare(b.name));

    setRows(rowsToDisplay);
  }, [nodeProvisioners, searchTerm]);

  return (
    <div>
      <div className="w-full flex gap-4 pb-8 item">
        <div className="grow">
          <SearchNodeFilter />
        </div>
        <div>
          <MultiSelect
            selected={selectedColumns}
            setSelected={setSelectedColumns}
            options={availableColumns}
            className="w-[85px]"
            customIcon={<CustomColumnsFilterButton isFiltered={selectedColumns.length > 0} />}
          />
        </div>
      </div>
      <DataGrid
        sx={{
          ...getDataGridSx(),
        }}
        rows={rows}
        columns={getColumns(selectedColumns)}
        getRowId={(row: NodeGroupRowEntry) => row.name}
        autoHeight={true}
        rowHeight={100}
        pageSize={10}
        loading={isLoading}
        disableSelectionOnClick
        getEstimatedRowHeight={() => 100}
      />
      {HAS_EXPORT_TABLE_AS_CSV && (
        <div className="mt-[-35px] ml-[10px] z-50 relative w-fit">
          <ExportCSV<CSVExportType>
            filename="node_groups.csv"
            columns={[
              "name",
              "cost",
              "instanceTypesDisplayName",
              "numNodes",
              "cpuDiff",
              "memoryDiff",
              "cpuRequests",
              "cpuAllocatable",
              "cpuUsage",
              "memoryRequests",
              "memoryAllocatable",
              "memoryUsage",
            ]}
            columnsToRound={["cost"]}
            data={
              rows.map((row) => {
                return { ...row, id: row.name };
              }) as CSVExportType[]
            }
            columnsToSum={[
              "name",
              "cost",
              "instanceTypesDisplayName",
              "numNodes",
              "cpuDiff",
              "memoryDiff",
              "cpuRequests",
              "cpuAllocatable",
              "cpuUsage",
              "memoryRequests",
              "memoryAllocatable",
              "memoryUsage",
            ]}
            customColumnNames={{
              name: "Name",
              cost: "Monthly Cost",
              instanceTypesDisplayName: "Instance Types",
              numNodes: "Number of Nodes",
              cpuDiff: "CPU",
              memoryDiff: "Memory",
              cpuRequests: "CPU Requests",
              cpuAllocatable: "CPU Allocatable",
              cpuUsage: "CPU Usage",
              memoryRequests: "Memory Requests",
              memoryAllocatable: "Memory Allocatable",
              memoryUsage: "Memory Usage",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default NodeProvisionersTab;
