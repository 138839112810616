import { Typography } from "@mui/material";
import useGetUserRole from "./useGetUserRole";

const UserRole = () => {
  const { userRole } = useGetUserRole();

  if (!userRole) return null;

  return (
    <Typography variant="caption" className="opacity-50 px-4">
      Role: {userRole}
    </Typography>
  );
};

export default UserRole;
