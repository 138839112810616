import { Checkbox, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import prettyBytes from "pretty-bytes";
import { useEffect, useState } from "react";
import { components } from "../../../api/schema";
import { SCALEOPS_COLORS } from "../../../colors";
import InfoIcon from "../../../Icons/InfoIcon";
import SelectedCheckBoxIcon from "../../../Icons/SelectedCheckBoxIcon";
import { currencyFormatter, spacedCamelCase } from "../../../utils/formatterUtils";
import { getDataGridSx } from "../../../utils/styleUtils";
import Tooltip from "../../Tooltip";
import { NodeFilterBar, NodeFilters } from "../NodeFilterBar";
import { getNodeGroupInfoWithFilters } from "../NodeGroups";
import { SimpleEllipsisWithTooltip } from "../Utils";
import { ConsolidationState, DisqualifiedNodes, NodeRow, ROW } from "./utils";

const CHECKBOX_SIZE = 16;
const HAS_FILTERS = true;

export const CONSOLIDATION_TABLE_QUERY_PREFIX = "consolidationTableQuery";

const isRowSelectable = (row: ROW) => !!row?.name && !!row?.status;

const PAGE_SIZE = 4;

const renderTruncatedTableCell = (params: GridRenderCellParams<string, ROW, string>) => {
  return (
    <Tooltip title={params.value} className="w-full cursor-default truncate">
      {params.value}
    </Tooltip>
  );
};

const getColumns = (
  data: components["schemas"]["UtilsNodeGroupInfoResponse"] | undefined,
  forfeitsArr: string[],
  disqualifiedNodes: DisqualifiedNodes[],
  hasNodePoolColumn: boolean,
  hasNodeGroupColumn: boolean,
  selectedRows: ROW[],
  setSelectedRows: (rows: ROW[]) => void
): GridColDef[] => [
  {
    field: "__check__",
    width: 50,
    headerName: "",
    sortable: false,
    align: "center",
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, ROW, string>) => {
      const disabled = !isRowSelectable(params.row);
      const checked = selectedRows.map((row) => row.name).includes(params.row.name);

      return (
        <div
          className={clsx({
            "opacity-30": disabled,
          })}
        >
          <Checkbox
            checked={checked}
            sx={{
              "& .MuiSvgIcon-root": {
                color: SCALEOPS_COLORS.primary.purpleBlue,
              },
            }}
            icon={<SelectedCheckBoxIcon width={CHECKBOX_SIZE} height={CHECKBOX_SIZE} />}
            checkedIcon={<SelectedCheckBoxIcon width={CHECKBOX_SIZE} height={CHECKBOX_SIZE} checked />}
            indeterminateIcon={<SelectedCheckBoxIcon width={CHECKBOX_SIZE} height={CHECKBOX_SIZE} indeterminate />}
            onClick={() => {
              if (disabled) return;

              const newSelectedRows = checked
                ? selectedRows.filter((row) => row.name !== params.row.name)
                : [...selectedRows, params.row];

              setSelectedRows(newSelectedRows);
            }}
            disabled={disabled}
          />
        </div>
      );
    },
  },
  {
    field: "name",
    headerName: "Name",
    flex: 8,
    sortable: true,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, components["schemas"]["UtilsNodeStats"], string>) => {
      return (
        <div className="w-full flex gap-2 items-center">
          <p className="truncate w-full">
            <SimpleEllipsisWithTooltip text={params.row.name} />
          </p>
        </div>
      );
    },
  },
  {
    field: "nodePool",
    hide: !hasNodePoolColumn,
    disableColumnMenu: true,
    sortable: true,
    flex: 4,
    headerName: "Node pool",
    renderCell: renderTruncatedTableCell,
  },
  {
    field: "nodeGroup",
    hide: !hasNodeGroupColumn,
    disableColumnMenu: true,
    sortable: true,
    flex: 4,
    headerName: "Node group",
    renderCell: renderTruncatedTableCell,
  },
  {
    field: "monthlyCost",
    headerName: "Savings",
    flex: 3,
    sortable: true,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, ROW, string>) => {
      const roundedValue = currencyFormatter(Number(params.row.monthlyCost) || 0.0);
      return (
        <div className="w-full flex justify-center text-guideline-darkGreen">
          {roundedValue}{" "}
          <Typography variant="caption" className="text-text-darkGray">
            /mo
          </Typography>
        </div>
      );
    },
  },
  {
    field: "cpu",
    headerName: "CPU",
    headerAlign: "center",
    flex: 3,
    sortable: true,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, ROW, string>) => {
      const displayValue = !Number.isNaN(params.value) ? Math.round(Number(params.value) * 100) / 100 : 0;
      return <div className="w-full flex justify-center text-guideline-darkGreen">{displayValue}</div>;
    },
  },
  {
    field: "memory",
    headerName: "Memory",
    headerAlign: "center",
    flex: 3,
    sortable: true,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, ROW, string>) => {
      const value = !Number.isNaN(params.value) ? Number(params.value) * 1024 * 1024 * 1024 : 0;
      const displayValue = prettyBytes(value, { bits: false, binary: true });
      return <div className="w-full flex justify-center text-guideline-darkGreen">{displayValue}</div>;
    },
  },
  {
    field: "status",
    headerName: "Consolidation status",
    headerAlign: "center",
    flex: 5,
    sortable: true,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<string, ROW, string>) => {
      let reason: string | undefined = disqualifiedNodes.filter((node) => node.nodeName === params.row?.name)[0]
        ?.reason;
      reason = reason ? spacedCamelCase(reason) : reason;

      let displayValue = <></>;

      switch (true) {
        case params.row?.name && forfeitsArr.includes(params.row?.name):
          displayValue = <span className="text-status-skipped">Skipped</span>;
          break;
        case params.row.status === ConsolidationState.Pending:
          displayValue = <span className="text-status-pending">Pending</span>;
          break;
        case params.row.status === ConsolidationState.Running:
          displayValue = <span className="text-status-inProgress">In progress</span>;
          break;
        case params.row.status === ConsolidationState.Completed:
          displayValue = <span className="text-status-completed">Completed</span>;
          break;
        case params.row.status === ConsolidationState.Failed:
          displayValue = <span className="text-status-failed">Failed</span>;
          break;
        case params.row.status === ConsolidationState.Ready:
          displayValue = <span className="text-status-ready">Ready</span>;
          break;
        default:
          break;
      }
      return (
        <Tooltip title={reason} className="w-full cursor-default" disabled={!reason}>
          <div className="w-full flex items-center justify-center gap-1">
            <span>{displayValue}</span>
            {reason && <InfoIcon className="text-status-skipped" width={12} height={12} />}
          </div>
        </Tooltip>
      );
    },
  },
];

interface Props {
  nodeData: { [key: string]: (components["schemas"]["Node_detailsNodeData"] & NodeRow) | undefined };
  forfeitsArr: string[];
  disqualifiedNodes: DisqualifiedNodes[];
  selectedRows: ROW[];
  setSelectedRows: (rows: ROW[]) => void;
  state?: ConsolidationState | undefined;
  isLoading?: boolean;
}

const ConsolidateTable = ({
  nodeData,
  forfeitsArr,
  disqualifiedNodes,
  selectedRows,
  setSelectedRows,
  state,
  isLoading,
}: Props) => {
  const { data } = getNodeGroupInfoWithFilters();

  const [rows, setRows] = useState<ROW[]>([]);
  const [hasNodePoolColumn, setHasNodePoolColumn] = useState(false);
  const [hasNodeGroupColumn, setHasNodeGroupColumn] = useState(false);

  useEffect(() => {
    const rowsData = Object.entries(nodeData ?? {}).map(([key, value]) => ({ ...value, name: key, id: key }));

    rowsData?.sort((a, b) => {
      const statusOrder = [
        ConsolidationState.Running,
        ConsolidationState.Pending,
        ConsolidationState.Completed,
        ConsolidationState.Failed,
      ] as string[];
      return Number(statusOrder?.indexOf(a?.status ?? "")) - Number(statusOrder.indexOf(b?.status ?? ""));
    });

    rowsData?.sort((a, b) => {
      const aIsSkipped = forfeitsArr.includes(a.name ?? "");
      const bIsSkipped = forfeitsArr.includes(b.name ?? "");
      if (!aIsSkipped && bIsSkipped) return -1;
      if (aIsSkipped && !bIsSkipped) return 1;
      return 0;
    });

    setSelectedRows(rowsData.filter((row) => isRowSelectable(row)));
    setHasNodePoolColumn(!!rowsData.find((row) => row.nodePool && row.nodePool.length > 0));
    setHasNodeGroupColumn(!!rowsData.find((row) => row.nodeGroup && row.nodeGroup.length > 0));
    setRows(rowsData);
  }, [nodeData, data]);

  return (
    <div
      className={clsx("w-full", {
        "opacity-30": state === ConsolidationState.Pending,
      })}
    >
      {HAS_FILTERS && (
        <div className="max-w-[1152px]">
          <NodeFilterBar
            filters={[
              NodeFilters.Search,
              NodeFilters.NodeGroups,
              NodeFilters.NodePools,
              NodeFilters.Provisioners,
              // NodeFilters.AvailabilityZones,
              // NodeFilters.Labels,
              NodeFilters.WorkloadLabels,
              NodeFilters.WorkloadAnnotations,
              // NodeFilters.WorkloadNames,
            ]}
            queryParamsPrefix={CONSOLIDATION_TABLE_QUERY_PREFIX}
          />
        </div>
      )}
      <DataGrid
        columns={getColumns(
          data,
          forfeitsArr,
          disqualifiedNodes,
          hasNodePoolColumn,
          hasNodeGroupColumn,
          selectedRows,
          setSelectedRows
        )}
        checkboxSelection
        rows={rows}
        selectionModel={selectedRows.map((row) => String(row?.name))}
        onSelectionModelChange={(newSelection) => {
          setSelectedRows(
            newSelection.map(
              (name) =>
                rows.find((row) => {
                  const isSelectable = isRowSelectable(row);
                  return row?.name === name && isSelectable;
                }) as ROW
            )
          );
        }}
        isRowSelectable={(row) => {
          return isRowSelectable(row.row as ROW);
        }}
        pagination={true}
        headerHeight={40}
        rowHeight={50}
        autoHeight={true}
        sx={{
          ...getDataGridSx(true),
          "& .MuiDataGrid-footer": {
            fontSize: "0.5rem",
          },
          "& .MuiTablePagination-displayedRows": {
            fontSize: "0.75rem",
          },
        }}
        initialState={{
          pagination: {
            pageSize: PAGE_SIZE,
          },
        }}
        loading={(isLoading && !rows?.length) || state === ConsolidationState.Pending}
        disableSelectionOnClick
        hideFooter={rows.length <= PAGE_SIZE}
        components={{
          BaseCheckbox: ({ indeterminate, ...rest }) => {
            return (
              <Checkbox
                checked={selectedRows.length === rows.length}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: SCALEOPS_COLORS.primary.purpleBlue,
                  },
                }}
                icon={<SelectedCheckBoxIcon width={CHECKBOX_SIZE} height={CHECKBOX_SIZE} />}
                checkedIcon={<SelectedCheckBoxIcon width={CHECKBOX_SIZE} height={CHECKBOX_SIZE} checked />}
                indeterminateIcon={<SelectedCheckBoxIcon width={CHECKBOX_SIZE} height={CHECKBOX_SIZE} indeterminate />}
                indeterminate={Boolean(indeterminate)}
                onSelect={() => {
                  return;
                }}
                {...rest}
              />
            );
          },
        }}
      />
    </div>
  );
};

export default ConsolidateTable;
