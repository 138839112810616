import * as React from "react";
import { ReactElement, useCallback, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowId,
  GridRowParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { darken, Grid, lighten, Switch, Typography } from "@mui/material";
import { Policy, RenderMetrics, SLA } from "./PoliciesTable";
import { Box, Theme } from "@mui/system";
import WorkloadTuning from "./WorkloadTuning";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import East from "@mui/icons-material/East";
import { AutoFixNormal } from "@mui/icons-material";
import { GraphRow } from "../HPAView";

export interface Trigger {
  current: string;
  recommendation: string;
  coastChange: string;
  goalMetricChange: string;
}

export interface Workload {
  id: string;
  namespace: string;
  name: string;
  triggers: Trigger[];
  policy: Policy;
  enabled: boolean;
  // eslint-disable-next-line
  data: GraphRow[];
}
type WorkloadsTableProps = {
  workloads: Workload[];
  updateWorkload: (workload: Partial<Workload>) => Promise<Workload>;
};

export default function WorkloadsTable(props: WorkloadsTableProps) {
  const [selectedWorkload, setSelectedWorkload] = useState<Workload | undefined>(undefined);
  const [workloads, setWorkloads] = useState<Workload[]>(props.workloads);

  const processRowUpdate: (updatedWorkload: Partial<Workload>) => Promise<Workload> = React.useCallback(
    async (updatedWorkload: Partial<Workload>) => {
      setWorkloads((old) => {
        return old.map((w) => {
          if (w.id == updatedWorkload.id) {
            return { ...w, ...updatedWorkload };
          } else {
            return w;
          }
        });
      });
      // Make the HTTP request to save in the backend
      return await props.updateWorkload(updatedWorkload);
    },
    [props.updateWorkload]
  );

  const openDetails = useCallback(
    (id: GridRowId) => () => {
      console.log("open details", "id", id);
      setSelectedWorkload(
        workloads.find((w) => {
          return w.id == id;
        })
      );
    },
    [workloads]
  );

  const applyRecommendation = useCallback(
    (id: GridRowId) => () => {
      console.log("applyRecommendation", "id", id);
    },
    []
  );

  const columns: GridColumns = [
    {
      field: "enabled",
      renderHeader: () => <AutoFixNormal />,
      editable: true,
      maxWidth: 15,
      type: "boolean",
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "namespace",
      headerName: "Namespace",
      flex: 1,
      type: "string",
      align: "center",
      disableColumnMenu: false,
      sortable: true,
      filterable: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      type: "string",
      align: "left",
      disableColumnMenu: false,
      sortable: true,
      filterable: true,
    },
    {
      field: "sla",
      headerName: "SLAs",
      minWidth: 180,
      maxWidth: 180,
      type: "string",
      align: "center",
      renderCell: (params: GridRenderCellParams<SLA[], Policy, SLA[]>) => {
        return RenderMetrics(params.value);
      },
      valueGetter: (params: GridValueGetterParams<SLA[], Workload>) => {
        return params.row.policy.sla;
      },
    },
    {
      field: "triggers",
      headerName: "Trigger(s)",
      type: "string",
      align: "center",
      disableColumnMenu: true,
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams<Trigger[], Workload, ReactElement>) => {
        if (params.value != undefined) {
          if (params.row.enabled) {
            return params.value.map((trigger) => {
              return (
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item key={JSON.stringify(trigger.current) + "triggers"}>
                    {trigger.current}
                  </Grid>
                  <Grid item key="arrow">
                    <East style={{ fontSize: "medium" }} sx={{ mt: "4px" }} />
                  </Grid>
                  <Grid item key={JSON.stringify(trigger.recommendation) + "triggers"}>
                    <Typography color={"green"}>{trigger.recommendation}</Typography>
                  </Grid>
                </Grid>
              );
            });
          } else {
            return params.value.map((trigger) => {
              return (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item key={JSON.stringify(trigger.current) + "triggers-unoptimized"}>
                    {trigger.current}
                  </Grid>
                </Grid>
              );
            });
          }
        }
        return <></>;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: ({ id, row: { enabled } }: GridRowParams<Workload>): ReactElement<GridActionsCellItemProps>[] => [
        <GridActionsCellItem
          showInMenu
          color="secondary"
          onClick={applyRecommendation(id)}
          disabled={!enabled}
          label="Apply recommendation"
        />,
        <GridActionsCellItem color="secondary" icon={<AutoFixHighIcon />} onClick={openDetails(id)} label="Details" />,
      ],
    },
    {
      field: "automated",
      headerName: "Automated",
      type: "actions",
      width: 110,
      getActions: ({ row: { enabled } }: GridRowParams<Workload>): ReactElement<GridActionsCellItemProps>[] => [
        <Switch disabled={!enabled}></Switch>,
      ],
    },
  ];

  const getBackgroundColor: (color: string, mode: string) => string = (color: string, mode: string) =>
    mode === "dark" ? darken(color, 0.9) : lighten(color, 0.9);

  const getHoverBackgroundColor: (color: string, mode: string) => string = (color: string, mode: string) =>
    mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

  return (
    <Box
      sx={{
        "& .disabled-row": {
          "&:hover": {
            bgcolor: (theme: Theme) =>
              // eslint-disable-next-line
              getHoverBackgroundColor(theme.palette.primary.main as string, theme.palette.mode as string) +
              " !important",
          },
          bgcolor: (theme: Theme) =>
            // eslint-disable-next-line
            getBackgroundColor(theme.palette.primary.main as string, theme.palette.mode as string) + " !important",
        },
      }}
    >
      <DataGrid
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        columns={columns}
        rows={workloads}
        disableSelectionOnClick={true}
        hideFooter
        autoHeight
        getRowClassName={(params: GridRowClassNameParams<Workload>) => {
          if (params.row.enabled) {
            return "";
          }
          return "disabled-row";
        }}
      />
      <WorkloadTuning
        workload={selectedWorkload}
        onClose={() => setSelectedWorkload(undefined)}
        updateWorkload={processRowUpdate}
      />
    </Box>
  );
}
