import { CircularProgress, Dialog, ListItemText, MenuItem, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  AlertsSettingsResponse,
  getLabelKeyValues,
  GetLabelKeyValuesResponse,
  GetSlackConf,
  GetSlackConfResponse,
  UpdateSlackConfMultiInput,
  VerifySlackChannel,
  VerifySlackChannelResponse,
  VerifySlackToken,
  VerifySlackTokenResponse,
} from "../../api/fetcher";
import Button from "../../components/Button";
import Tooltip from "../../components/Tooltip";
import AlertIcon from "../../Icons/AlertIcon";
import SlackColorIcon from "../../Icons/SlackColorIcon";
import WarningIcon from "../../Icons/WarningIcon";
import { GetConfig } from "../../utils/ConfigHelper";
import TokenForm, { TokenValidationError } from "./TokenForm";
import useUpdateAlertsSettings from "./useUpdateAlertsSettings";
import useUpdateSlackConf from "./useUpdateSlackConf";
// import ClusterMultiSelect from "./ClusterMultiSelect";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { MAIN_YELLOW } from "../../colors";
import FormInput from "../../components/formComponents/FormInput";
import SingleSelect, { SingleSelectSize } from "../../components/SingleSelect";
import useGetUserRole from "../../MainMenu/useGetUserRole";
import MultiClusterSlackEnabledSelector from "./MultiClusterSlackEnable";
import { useTriggerSlackAlert } from "./useTriggerSlackAlert";
import { documentationUrl } from "../../utils/urlUtils";

interface Props {
  alertsSettingsData: AlertsSettingsResponse | undefined;
  isMultiCluster: boolean;
}

const AlertsSettings = ({ alertsSettingsData, isMultiCluster }: Props) => {
  const { userRole } = useGetUserRole();
  const config = GetConfig();
  const slackConf = GetSlackConf();
  const labelKeyValues = getLabelKeyValues();
  const updateAlertsSettings = useUpdateAlertsSettings(true);
  const [isOpen, setIsOpen] = useState(false);

  const triggerSlackTestAlert = useTriggerSlackAlert();

  const updateSlackConf = useUpdateSlackConf();

  const { data, isLoading, isError } = useQuery<GetSlackConfResponse, Error>({
    queryKey: [slackConf.queryKey],
    queryFn: () => slackConf.queryFn(),
  });

  const { data: labelKeyValuesData } = useQuery<GetLabelKeyValuesResponse, Error>(
    [labelKeyValues.queryKey],
    () => labelKeyValues.queryFn(),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isError) {
    toast.error("Error while fetching slack configuration");
  }

  const [slackTokenValid, setSlackTokenValid] = useState<boolean | undefined>(undefined);
  const [slackChannelValid, setSlackChannelValid] = useState<boolean | undefined>(undefined);

  const verifySlackToken = VerifySlackToken();
  const {
    error: slackTokenValidationError,
    isLoading: slackTokenValidationLoading,
    data: slackTokenValidationResponse,
  } = useQuery<VerifySlackTokenResponse, Error>({
    queryKey: [verifySlackToken.queryKey, data?.config?.token],
    queryFn: () =>
      verifySlackToken.queryFn({
        token: data?.config?.token || "",
      }),
    enabled: !!data?.config?.token,
    retryDelay: (attempt) => attempt * 1000,
  });

  const getError = (e: VerifySlackTokenResponse | undefined) => {
    if (!e || e.ok) {
      return null;
    }
    return { message: e?.error?.message, type: e?.error?.type } as TokenValidationError;
  };

  const [slackTokenValidationErrorV2, setSlackTokenValidationErrorV2] = useState<TokenValidationError | Error | null>(
    null
  );

  useEffect(() => {
    if (slackTokenValidationLoading) {
      return;
    }
    if (slackTokenValidationError !== null) {
      setSlackTokenValidationErrorV2(slackTokenValidationError);
    } else {
      setSlackTokenValidationErrorV2(getError(slackTokenValidationResponse));
    }
  }, [slackTokenValidationError, slackTokenValidationLoading, slackTokenValidationResponse]);

  const validateSlackChannel = VerifySlackChannel();
  const { error: slackChannelValidationError, isLoading: slackChannelValidationLoading } = useQuery<
    VerifySlackChannelResponse,
    Error
  >({
    queryKey: [validateSlackChannel.queryKey, data?.config?.token, data?.config?.defaultChannel],
    queryFn: () =>
      validateSlackChannel.queryFn({
        channel: data?.config?.defaultChannel?.name || "",
        token: data?.config?.token || "",
      }),
    enabled: !!data?.config?.token && !!data?.config?.defaultChannel,
    retryDelay: (attempt) => attempt * 1000,
  });

  useEffect(() => {
    if (slackChannelValidationError === null && !slackChannelValidationLoading) {
      setSlackChannelValid(true);
    } else if (slackChannelValidationError !== null) {
      setSlackChannelValid(false);
    }
  }, [slackChannelValidationError, slackChannelValidationLoading]);

  useEffect(() => {
    if (slackTokenValidationLoading) {
      return;
    }
    const err =
      slackTokenValidationErrorV2 !== null ? getError(slackTokenValidationResponse) : slackTokenValidationErrorV2;
    setSlackTokenValid(err === null);
  }, [slackTokenValidationErrorV2, slackTokenValidationLoading, slackTokenValidationResponse]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const disableFormInputs = !slackTokenValid || !data?.config?.token;
  const disableTestAlertButton = !slackChannelValid || disableFormInputs || !!data?.config?.disabled;
  const showWarningSetupNotDone = (slackChannelValid === false || slackTokenValid === false) && !!data?.config?.token;

  const [loadingAny, setLoadingAny] = useState<boolean>(true);
  useEffect(() => {
    setLoadingAny(
      (!!data?.config?.token && slackTokenValid === undefined && slackChannelValid === undefined) || isLoading
    );
  }, [slackTokenValid, slackChannelValid, isLoading]);

  const whatIsMissing = [];
  if (!data?.config?.token) {
    whatIsMissing.push("Slack token is missing.");
  } else if (slackTokenValid === false) {
    whatIsMissing.push("Slack token is invalid");
  } else if (slackTokenValid === true) {
    if (!data?.config?.defaultChannel) {
      whatIsMissing.push("Default channel is missing.");
    } else if (!slackChannelValid) {
      whatIsMissing.push("Default channel is invalid");
    }
  }

  const showDefaultSlackChannelWarning =
    (slackChannelValid === false || !data?.config?.defaultChannel?.name) &&
    !!data?.config?.token &&
    slackTokenValid &&
    !slackTokenValidationLoading;
  return (
    <div className="flex justify-end gap-4 h-[50px] mb-[-50px]">
      <Button
        className={clsx({
          "hover:bg-[#4A154B]": userRole === "Admin",
          "hover:bg-white": userRole !== "Admin",
        })}
        onClick={() => {
          setIsOpen(true);
        }}
        label={
          <Tooltip
            title="Some configurations are missing. Please complete the setup."
            disabled={!showWarningSetupNotDone}
          >
            <Typography className="flex gap-2 items-center justify-between" variant="body1">
              <AlertIcon />
              {showWarningSetupNotDone && (
                <div>
                  <WarningIcon fill={MAIN_YELLOW} height={20} width={20} />
                </div>
              )}
              Alert Settings
            </Typography>
          </Tooltip>
        }
        disabled={userRole !== "Admin"}
      />
      <Dialog open={isOpen} onClose={handleClose}>
        <div className="p-4 w-[400px] relative">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <Typography className="flex gap-2 items-center" variant="body1" fontWeight={500}>
                <AlertIcon />
                Alert Settings
                {loadingAny ? (
                  <div className="flex grow justify-end">
                    <CircularProgress size={"20px"} />
                  </div>
                ) : (
                  showWarningSetupNotDone && (
                    <div className={"grow flex justify-end"}>
                      <Tooltip
                        title={
                          <div>
                            <b>Missing configuration: </b>
                            {whatIsMissing.map((item) => (
                              <li>{item}</li>
                            ))}
                          </div>
                        }
                      >
                        <WarningIcon fill={MAIN_YELLOW} height={20} width={20} />
                      </Tooltip>
                    </div>
                  )
                )}
              </Typography>
              <div></div>
              <Typography className="" variant="body2">
                For detailed instruction please see our simple{" "}
                <a
                  href={documentationUrl({
                    path: "integrations/slack-integration",
                    token: config.token,
                  })}
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-guideline-lessDarkBlue hover:text-guideline-darkBlue"
                >
                  <b>integration tutorial</b>
                </a>
                .
              </Typography>
            </div>
          </div>

          {isLoading && (
            <div className="flex items-center justify-center w-full py-8">
              <CircularProgress />
            </div>
          )}
          <Formik
            initialValues={{
              defaultChannel: data?.config?.defaultChannel?.id,
              defaultLabel: alertsSettingsData?.Label,
            }}
            validationSchema={Yup.object({
              defaultChannel: Yup.string().min(1, "Channel must be 1 character or more"),
              defaultLabel: Yup.string()
                .min(1, "Must be 1 character or more")
                .max(63, "Must be 63 characters or less")
                .matches(
                  /^([a-z0-9A-Z]+[a-z0-9A-Z-_.]*[a-z0-9A-Z]+\/)?[a-z0-9A-Z]+[a-z0-9A-Z-_.]*[a-z0-9A-Z]+$/,
                  "Invalid label"
                ),
            })}
            onSubmit={(values) => {
              if (values.defaultChannel !== data?.config?.defaultChannel?.name) {
                updateSlackConf.mutate({
                  defaultChannel: { id: values.defaultChannel, name: values.defaultChannel },
                  multiCluster: isMultiCluster,
                } as UpdateSlackConfMultiInput);
                validateSlackChannel
                  .queryFn({ channel: values.defaultChannel || "", token: data?.config?.token || "" })
                  .then(() => {
                    setSlackChannelValid(true);
                  })
                  .catch(() => {
                    setSlackChannelValid(false);
                    toast.error("Invalid channel");
                  });
              }
            }}
          >
            {(formik) => {
              useEffect(() => {
                formik.validateForm();
              }, []);

              return (
                <>
                  {data && (
                    <div className="">
                      <TokenForm
                        token={data?.config?.token}
                        showTokenIsInvalidError={
                          !slackTokenValidationLoading && !!data?.config?.token && !slackTokenValid
                        }
                        isMultiCluster={isMultiCluster}
                        showValidation={slackTokenValidationLoading}
                        onReset={() => {
                          formik.setFieldValue("defaultChannel", "");
                        }}
                        onSlackTokenChange={(valid: boolean) => {
                          setSlackTokenValid(valid);
                        }}
                        tokenIsValid={slackTokenValid}
                        tokenValidationError={
                          slackTokenValidationErrorV2 === null
                            ? undefined
                            : ((slackTokenValidationErrorV2 instanceof Error
                                ? { message: "Invalid Slack Token", type: "" }
                                : slackTokenValidationErrorV2) as TokenValidationError)
                        }
                      />
                    </div>
                  )}
                  <Form className="pt-6">
                    <div className="flex flex-col gap-5 relative">
                      {
                        <div className="flex relative items-end gap-5 w-[370px]">
                          <div className="flex items-center w-[251px]">
                            <FormInput
                              label={
                                <div className="flex items-center gap-1">
                                  <SlackColorIcon width={14} height={14} />
                                  {showDefaultSlackChannelWarning && (
                                    <Tooltip title={"Slack Channel is invalid"}>
                                      <WarningIcon height={14} width={14} fill={MAIN_YELLOW} />
                                    </Tooltip>
                                  )}
                                  Default Slack Channel
                                </div>
                              }
                              name="defaultChannel"
                              required
                              className="grow"
                              disabled={disableFormInputs}
                            />
                          </div>
                          {
                            <Button
                              type="submit"
                              label={<div className="mx-[-10px]">Save Channel</div>}
                              variant="small"
                              className="h-[34px] mt-[2px] grow"
                              disabled={
                                disableFormInputs || formik.values.defaultChannel === data?.config?.defaultChannel?.name
                              }
                            />
                          }
                        </div>
                      }
                      {
                        <div className="flex justify-start items-start w-[368px]">
                          <MultiClusterSlackEnabledSelector disabled={disableFormInputs} />
                        </div>
                      }
                      {
                        <div>
                          <Typography variant="body2" className="text-text-lightBlack flex items-center gap-1">
                            <div className="items-end">
                              Ownership Label <span className="text-[8px]">(optional)</span>
                            </div>
                            <Tooltip
                              title={
                                "Assign alerts to specific roles, teams, or projects. This will be used as a default key for all alerts."
                              }
                            >
                              <InfoOutlinedIcon style={{ width: 14 }} />
                            </Tooltip>
                          </Typography>
                          <SingleSelect<string | undefined>
                            selected={alertsSettingsData?.Label}
                            setSelected={(value) => {
                              updateAlertsSettings.mutate({
                                label: value,
                                multiCluster: isMultiCluster,
                                modifications: [],
                              });
                            }}
                            renderOptionsFunc={(search) => {
                              return [
                                ...new Set((labelKeyValuesData?.labels || []).map((label) => label?.split("=")[0])),
                              ]
                                .filter((label) => !search || label.includes(search))
                                .map((label) => (
                                  <MenuItem value={label} key={label}>
                                    <ListItemText primary={label} />
                                  </MenuItem>
                                ));
                            }}
                            disabled={disableFormInputs}
                            isSearchable
                            className="w-[368px] h-[36px]"
                            size={SingleSelectSize.Medium}
                          />
                        </div>
                      }
                    </div>
                    {
                      <div className="mt-4 flex justify-between">
                        <div className="w-[115px]">
                          <Tooltip
                            title={
                              disableTestAlertButton
                                ? data?.config?.disabled
                                  ? "Slack alerts are disabled for this cluster"
                                  : "Finish configuring the token and channel to enable this test"
                                : "Trigger a dummy alert, and verify you get a slack notification for it"
                            }
                          >
                            <Button
                              type="button"
                              label="Send Test Alert"
                              variant="smallGreen"
                              onClick={() => {
                                triggerSlackTestAlert.mutate({
                                  multiCluster: isMultiCluster,
                                  direct: true,
                                });
                              }}
                              disabled={disableTestAlertButton}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    }
                    <div></div>
                    <div className={clsx("flex gap-2 mt-12 items-end justify-end")}>
                      <Button type="button" label="Close" onClick={handleClose} variant="small" />
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
};

export default AlertsSettings;
