import { SCALEOPS_COLORS } from "../../../../colors";
import { OPTIMIZED_REQUEST } from "../../../../utils/namesUtils";

export const WORKLOAD_ANALYTICS_SYNCH_ID = "WORKLOAD_ANALYTICS_SYNCH_ID";
export const CHART_HEIGHT = "h-[320px]";

export type TooltipSortType = (
  a: { value: string | number; name?: string },
  b: { value: string | number; name?: string }
) => number;

export const podTooltipSortFnc = (
  a: { value: string | number; name?: string },
  b: { value: string | number; name?: string }
) => {
  if (a.name === OPTIMIZED_REQUEST) {
    return -1;
  }
  if (b.name === OPTIMIZED_REQUEST) {
    return 1;
  }
  return String(a.name).localeCompare(String(b.name));
};

export const recommendedGetElementsFormat = (key: string, regexp: string, color: string) => {
  if (key.startsWith("memory_request_recommended") || key.startsWith("cpu_request_recommended")) {
    return {
      key: key,
      dataKey: key,
      label: "Optimized request",
      color: SCALEOPS_COLORS.main.green,
      tooltipValueColor: color,
      fill: "none",
    };
  }

  return {
    key: key,
    dataKey: key,
    label: key.replace(regexp, ""),
    color: color,
    tooltipValueColor: color,
    fill: "none",
  };
};

export const recommendedLegendSortFunction = (a: { [x: string]: string }, b: { [y: string]: string }) => {
  if (!a || !b) return 0;
  const aKey = Object.keys(a)[0];
  const bKey = Object.keys(b)[0];

  if (aKey.startsWith("memory_request_recommended") || aKey.startsWith("cpu_request_recommended")) {
    return -1;
  }
  if (bKey.startsWith("memory_request_recommended") || bKey.startsWith("cpu_request_recommended")) {
    return 1;
  }

  return aKey.localeCompare(bKey);
};
