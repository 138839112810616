import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import * as formatterUtils from "../../../utils/formatterUtils";
import { Typography } from "@mui/material";
import Tooltip from "../../Tooltip";
import {
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE,
} from "./GridColumnsUtils";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import clsx from "clsx";

export interface ActiveSavingsColumnProps {
  hpaOptimizationEnabled: boolean | undefined;
}

export default function ActiveSavingsColumn({
  params,
  hpaOptimizationEnabled,
}: {
  params: GridRenderCellParams<number, components["schemas"]["UtilsWorkload"], string>;
} & ActiveSavingsColumnProps) {
  const rightSizeActiveSavings =
    params.row.activeSavings == undefined || (params.row.activeSavings < 0 && params.row.activeSavings > -1)
      ? formatterUtils.currencyFormatter(0)
      : formatterUtils.currencyFormatter(params.row.activeSavings);
  const overallActiveSavings =
    params.row.overallActiveSavings == undefined
      ? rightSizeActiveSavings
      : params.row.overallActiveSavings < 0 && params.row.overallActiveSavings > -1
      ? formatterUtils.currencyFormatter(0)
      : formatterUtils.currencyFormatter(params.row.overallActiveSavings);

  const displayValue = hpaOptimizationEnabled ? overallActiveSavings : rightSizeActiveSavings;
  const classNameCondition = hpaOptimizationEnabled
    ? displayValue !== "$0" && params.row.overallActiveSavings && params.row.overallActiveSavings > -1
    : displayValue !== "$0" && params.row.activeSavings && params.row.activeSavings > -1;

  if (isUnallocatedRow(params.row)) return <div></div>;
  if (params.row.isReadyRecommendation == false) {
    return (
      <Typography fontWeight={410} fontSize={"small"} fontFamily={"Poppins"} fontStyle={"oblique"}>
        inactive
      </Typography>
    );
  }
  return (
    <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
      <Tooltip
        title={WORKLOAD_OVERVIEW_TOOLTIP_MESSAGE}
        maxWidth={400}
        className={clsx(WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME, {
          "text-guideline-darkGreen": classNameCondition,
        })}
        enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
      >
        {displayValue}
      </Tooltip>
    </Typography>
  );
}
